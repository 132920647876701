//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CalculatorLogic from '~/components/CalculatorLogic';
import BaseToggle from '~/components/form/BaseToggle';
import BaseIcon from '~/components/BaseIcon';
import SliderInput from '~/components/form/SliderInput';
import BaseSlider from '~/components/BaseSlider';
import SelectFieldModal from '~/components/form/SelectFieldModal';

import { userStore } from '~/store';

const THEMES = {
    dark: 'dark',
    light: 'light'
};

export default {
    components: {
        BaseToggle,
        BaseIcon,
        SliderInput,
        BaseSlider,
        CalculatorLogic,
        SelectFieldModal
    },
    props: {
        theme: {
            type: String,
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        withPreloader: {
            type: Boolean,
            default: true
        },
        creditTypes: { type: Array },
        isCreditTypeDisabled: { type: Boolean, default: false },
        value: { type: Object, required: true },
        withEqualWidth: { type: Boolean, default: false },
        disableAllFields: { type: Boolean, default: false, required: false }
    },
    data() {
        return {
            availableThemes: THEMES,
            initialFormData: null
        };
    },
    computed: {
        formData: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', Object.assign({}, this.value || {}, value));
            }
        },
        textColor() {
            return { 'text-black': this.theme === THEMES.dark };
        },
        userType() {
            return userStore.userType;
        }
    },
    created() {
        const initialData = {
            creditType: this.value?.typeOfCredit,
            amount: this.value?.amount,
            duration: this.value?.duration
        };

        Object.keys(initialData).forEach(key => {
            if (initialData[key] === null) delete initialData[key];
        });

        this.initialFormData = Object.keys(initialData).length ? initialData : null;
    },
    methods: {
        onDataChange(payload) {
            this.formData = {
                typeOfCredit: payload?.creditType, // Выбранный тип кредита
                amount: payload?.amount, // Сумма кредита
                duration: payload?.duration // Срок кредита
            };
            this.$emit('filters-change', payload);
        },
        onCreditProductTypesUpdated(payload) {
            this.$emit('credit-product-types-updated', payload);
        }
    }
};
