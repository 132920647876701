//
//
//
//
//
//
//
//
//

import getImage from '~/helpers/getImage';
import MediaVideo from '~/components/MediaVideo.vue';

export default {
    components: {
        MediaVideo
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    computed: {
        videoUrl() {
            return this.block.video && this.block.video.source;
        },
        previewImage() {
            return (this.block.preview && this.block.preview.file && this.block.preview.file.url)
                ? getImage(this.block.preview.file.url, 'rs:fit:1280:768')
                : null;
        }
    },
    methods: {
        getImage
    }
};
