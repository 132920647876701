var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vue-card-stack__wrapper"},[_c('div',{ref:"stack",staticClass:"vue-card-stack__stack",style:({
            height: ((_vm.cardHeight + _vm.paddingVertical * 2) + "px"),
            width: _vm.containerWidth
        })},_vm._l((_vm.stack),function(card,index){return _c('div',{key:card._id,staticClass:"vue-card-stack__card",style:({
                opacity: card.opacity,
                display: card.display,
                top: ((card.yPos) + "px"),
                width: ((card.width) + "px"),
                height: ((card.height) + "px"),
                zIndex: card.zIndex,
                transition: ("transform " + (_vm.isDragging ? 0 : _vm.speed) + "s ease, opacity " + _vm.speed + "s ease"),
                transform: ("\n                    scale(" + (card.scale) + ", " + (card.scale) + ")\n                    translate(" + (card.xPos) + "px, 0)\n                "),
            })},[_vm._t("card",null,{"card":Object.assign({}, card, {$index: index})})],2)}),0),_vm._v(" "),_vm._t("nav",null,null,{ activeCardIndex: _vm.originalActiveCardIndex, onNext: _vm.onNext, onPrevious: _vm.onPrevious })],2)}
var staticRenderFns = []

export { render, staticRenderFns }