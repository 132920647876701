var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'relative overflow-hidden flex-auto w-full rounded-xl before:block before:aspect-w-15 before:aspect-h-9',
        ( _obj = {}, _obj['media-video_playing'] = _vm.isPlaying, _obj['is-loading'] = _vm.isLoading, _obj )
    ]},[_c('div',{class:['absolute inset-0 flex items-center justify-center cursor-pointer overflow-hidden bg-center bg-cover transition-fast z-50', {
            'opacity-100': !_vm.isPlaying,
            'opacity-0 pointer-events-none': _vm.isPlaying
        }],style:(_vm.backgroundImage),on:{"click":function($event){$event.preventDefault();return _vm.play($event)}}},[_c('div',{staticClass:"relative flex items-center justify-center flex-auto flex-grow-0"},[_c('div',{class:['group relative flex items-center justify-center w-16 h-16 overflow-hidden before:absolute before:inset-0 before:bg-black before:bg-opacity-5 before:backdrop-filter before:backdrop-blur-3xl before:rounded-xl', {
                    'hidden': _vm.isLoading || _vm.isPlaying
                }]},[_c('PlayIcon',{staticClass:"relative ml-1 transition-fast group-hover:scale-105"})],1),_vm._v(" "),_c('CircleLoading',{class:[{
                    'hidden': !_vm.isLoading
                }],attrs:{"width":48}})],1)]),_vm._v(" "),_c('div',{class:['absolute inset-0 transition-fast z-30', {
            'opacity-0 pointer-events-none': !_vm.isPlaying,
            'opacity-100 pointer-events-auto': _vm.isPlaying
        }]},[(_vm.isReady && _vm.videoCode)?_c('Youtube',{ref:"youtube",staticClass:"absolute top-0 left-0 min-w-full min-h-full w-auto h-auto",attrs:{"video-id":_vm.videoCode,"player-vars":_vm.playerVars},on:{"ready":_vm.ready}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }