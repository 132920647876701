//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '~/mixins/documentSizeMixin';
import BaseImage from '~/components/BaseImage';
import Typograph from '~/components/Typograph';
import BaseIcon from '~/components/BaseIcon';
import makeClasses from '~/helpers/makeClasses';

import getImage from '~/helpers/getImage';
import dayjs from 'dayjs';

const THEMES = {
    white: 'white',
    outline_light_gray: 'outline_light_gray',
    global: 'global'
};
const VIEWS = {
    vertical: 'vertical',
    horizontal: 'horizontal'
};
export { THEMES, VIEWS };

const useClasses = makeClasses(() => ({
    createdAt: () => ({
        'text-[#878787] text-[13px] mt-2.5': true
    }),
    root: ({ view, theme }) => ({
        'relative p-4 rounded-base group transition-fast hover:z-[5]': true,
        'max-w-[245px] lg:flex lg:flex-col': view === VIEWS.vertical,
        'w-full flex': view === VIEWS.horizontal,
        'bg-white border border-white mouse-device:hover:rounded-b-none will-change-transform mouse-device:hover:drop-shadow-light-blue': theme === THEMES.white,
        'border border-gray-700 border-opacity-[0.12] will-change-transform hover:bg-white hover:border-gray-700 hover:border-opacity-[0.12] hover:border-b-white a-lg:hover:rounded-b-none lg:hover:border-gray-700 lg:hover:border-opacity-[0.12] mouse-device:hover:filter mouse-device:hover:drop-shadow-light-black lg:bg-white': theme === THEMES.outline_light_gray,
        'bg-accent bg-opacity-[var(--theme-accent-opacity)]': theme === THEMES.global,
        'hover:bg-white border border-transparent hover:border-white will-change-transform mouse-device:hover:bg-blue-50 mouse-device:hover:border-blue-250 mouse-device:hover:border-opacity-[0.12] mouse-device:hover:filter mouse-device:hover:drop-shadow-light-blue hover:rounded-b-none': theme === THEMES.global
    }),
    imageContainer: ({ view }) => ({
        'relative flex items-center justify-center h-16 w-16 rounded-md bg-gray-50 filter drop-shadow-lg transition-fast -translate-y-7 group-hover:-translate-y-10 lg:-translate-y-10': view === VIEWS.vertical,
        'flex-shrink-0 mr-6 lg:mr-4': view === VIEWS.horizontal
    }),
    image: ({ view }) => ({
        'max-w-[64px] max-h-[64px]': view === VIEWS.vertical,
        'filter drop-shadow-lg w-24 h-24 lg:w-16 lg:h-16 sm:w-10 sm:h-10': view === VIEWS.horizontal
    }),
    content: ({ view }) => ({
        'lg:flex-col lg:flex-auto max-w-full': true,
        'lg:flex': view === VIEWS.vertical,
        flex: view === VIEWS.horizontal
    }),
    bankTitle: ({ view, theme }) => ({
        'text-xs break-word font-semibold': true,
        'text-gray-650': theme !== THEMES.global,
        'text-primary opacity-50 group-hover:text-gray-650': theme === THEMES.global,
        'mb-0.5': view === VIEWS.vertical,
        'mr-2 sm:mr-0 sm:mb-1': view === VIEWS.horizontal
    }),
    reviewsButton: ({ view }) => ({
        'transition-fast relative': true,
        'hover:opacity-60': true,
        'mb-3': view === VIEWS.vertical
    }),
    reviewsContainer: () => 'flex items-center -mx-0.5 whitespace-nowrap',
    star: () => ({
        'mx-0.5 fill-current': true
    }),
    reviewText: ({ theme }) => ({
        'mx-0.5 text-xxs': true,
        'text-gray-650': theme !== THEMES.global,
        'text-primary opacity-50 group-hover:text-gray-650': theme === THEMES.global
    }),
    title: ({ view, theme }) => ({
        'break-word': true,
        'text-black': theme !== THEMES.global,
        'text-primary group-hover:text-black': theme === THEMES.global,
        'mb-4 pr-3': view === VIEWS.vertical,
        'text-lg': view === VIEWS.horizontal
    })
}));

const MAX_RATING = 5;
const MAX_INFO_LENGTH = 2;

export default {
    components: { BaseIcon, Typograph, BaseImage },
    mixins: [documentSizeMixin],
    props: {
        offer: { type: Object, required: true },
        view: {
            default: VIEWS.vertical,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        theme: {
            default: THEMES.white,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        }
    },
    data: () => ({
        maxBankRating: MAX_RATING,
        maxInfoLength: MAX_INFO_LENGTH,
        fullReview: false
    }),
    computed: {
        classes() {
            return useClasses({
                view: this.view,
                theme: this.theme,
                activeRating: this.offer.grade,
                isChosen: false,
                disableReview: true
            });
        },
        bank() {
            return this.offer?.partner || {};
        },
        bankRating() {
            return (this.offer?.grade && this.offer.grade.toFixed(1)) || 0;
        },
        bankImage() {
            const url = this.bank?.image?.url || null;

            return getImage(url, 'rs:fit:308:308');
        },
        reviewText() {
            return this.truncate(this.offer.text, 115);
        }
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        readMore() {
            this.fullReview = !this.fullReview;
        },
        onWindowResize() {
            this.maxInfoLength = MAX_INFO_LENGTH;

            if (this.windowSize.width < 1000 && this.view === 'vertical') {
                this.maxInfoLength = 3;
            }

            if (this.windowSize.width < 500 && this.view === 'horizontal') {
                this.maxInfoLength = 1;
            }
        },
        truncate(str, maxlength) {
            return (str.length > maxlength) ?
                str.slice(0, maxlength - 1) + '…' : str;
        }
    }
};
