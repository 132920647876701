//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formFieldMixin from '~/mixins/formFieldMixin';
import selectFieldMixin from '~/mixins/selectFieldMixin';
import BaseField from '~/components/form/BaseField';
import BaseSelect from '~/components/form/BaseSelect';
import BaseIcon from '@/components/BaseIcon';

export default {
    components: {
        BaseIcon,
        BaseField,
        BaseSelect
    },
    mixins: [formFieldMixin, selectFieldMixin]
};
