//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { animate, stagger } from 'motion';
import BaseIcon from '@/components/BaseIcon';

export default {
    components: { BaseIcon },
    props: {
        withMotion: { type: Boolean, default: true, required: false },
        colors: { type: Array, required: false, default: () => ['#091142', '#0D154A', '#17205B', '#173877', '#2550A4'] }
    },
    methods: {
        onListBeforeEnter(el) {
            el.style.opacity = 0;
            el.style.transform = 'translate(0, 0)';
        },
        async onListEnter(el, done) {
            const isLastEl = el.dataset.isLast;
            const delay =  isLastEl ? 0 : stagger(0.2, { from: 'last' });
            const offsetX = 50 * el.dataset.index;
            const offsetY = 7 * el.dataset.index;
            const opacity = 1;

            let transformOptions = { duration: 0 };
            if (this.withMotion) {
                transformOptions = { duration: 3, delay: isLastEl ? 0 : stagger(0.4) };
            }

            await animate(el, {
                opacity,
                transform: `translate(-${ offsetX }px, -${ offsetY }px)`
            }, {
                duration: 2,
                delay,
                transform: transformOptions,
                opacity: { duration: 0 }
            }).finished;
            done();
        },
        async onListLeave(el, done) {
            el.style.display = 'none';
            done();
        }
    }
};
