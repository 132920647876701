var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes.root,on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[(_vm.offer.isPopular)?_c('div',{class:_vm.classes.popularTag},[_vm._v("\n        Популярно\n    ")]):_vm._e(),_vm._v(" "),_c('div',{class:[_vm.classes.imageContainer, {'sm:hidden': _vm.view === 'horizontal'}]},[_c('BaseImage',{class:_vm.classes.image,attrs:{"src":_vm.optimizedImage,"alt":_vm.offer.bank.title}})],1),_vm._v(" "),(_vm.isCheckbox)?[_c('div',{class:_vm.classes.checkboxIcon},[(_vm.isChosen || _vm.isCheckboxVisible)?_c('CheckItem',{attrs:{"is-checked":_vm.isChosen}}):_vm._e()],1),_vm._v(" "),_c('label',{class:[_vm.isChosen ? 'cursor-default' : 'cursor-pointer']},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],class:_vm.classes.checkboxInput,attrs:{"type":"checkbox"},domProps:{"value":_vm.offer.id,"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,_vm.offer.id)>-1:(_vm.inputValue)},on:{"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.offer.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}),_vm._v(" "),_c('span',{staticClass:"absolute inset-0 opacity-0"})])]:_vm._e(),_vm._v(" "),(_vm.normalizedTags.length)?_c('div',{class:_vm.classes.tag},_vm._l((_vm.normalizedTags),function(tag,tagIndex){return _c('BaseTooltipTag',{key:tag.id,class:{
                'mx-1': _vm.view === 'horizontal',
                'hidden': (_vm.view === 'vertical' && tagIndex > 0) || (_vm.view === 'horizontal' && tagIndex > 2 && !_vm.isMobile) || (_vm.view === 'horizontal' && tagIndex > 0 && _vm.isMobile)
            },attrs:{"theme":_vm.tagTheme,"tooltip-message":tag.title}},[_c('BaseIcon',{attrs:{"name":tag.icon,"width":"15","height":"13"}})],1)}),1):_vm._e(),_vm._v(" "),_c('div',{class:[_vm.classes.content]},[_c('div',{class:{'max-w-[255px] flex-auto a-lg:min-w-[255px] pt-3 mr-4 lg:pt-0 lg:mb-4': _vm.view === 'horizontal'}},[_c('div',{class:{'sm:flex sm:items-center sm:mb-4': _vm.view === 'horizontal'}},[_c('div',{class:[_vm.classes.imageContainer, _vm.view === 'horizontal' ? 'a-sm:hidden': 'hidden']},[_c('BaseImage',{class:_vm.classes.image,attrs:{"src":_vm.optimizedImage,"alt":_vm.offer.bank.title}})],1),_vm._v(" "),_c('div',{class:{'flex items-baseline mb-1.5 sm:items-start sm:flex-col sm:mb-0': _vm.view === 'horizontal'}},[_c('Typograph',{class:_vm.classes.bankTitle,attrs:{"html":_vm.offer.bank.title}}),_vm._v(" "),_c('button',{class:_vm.classes.reviewsButton,attrs:{"type":"button"}},[_c('span',{class:_vm.classes.reviewsContainer},[_vm._l((_vm.maxBankRating),function(rate){return _c('span',{key:rate,class:[_vm.classes.star, rate <= _vm.offer.bank.rating ? 'text-yellow' : 'text-gray-400']},[_c('BaseIcon',{attrs:{"name":"star","width":"10","height":"10"}})],1)}),_vm._v(" "),_c('span',{class:_vm.classes.reviewText},[(_vm.offer.bank.rating)?_c('span',[_vm._v("\n                                    "+_vm._s(_vm.offer.bank.rating)+"\n                                ")]):_vm._e(),_vm._v(" "),(_vm.offer.bank.reviews)?_c('span',[_vm._v("\n                                    ("+_vm._s(_vm.offer.bank.reviews)+")\n                                ")]):_vm._e()])],2)])],1)]),_vm._v(" "),_c('Typograph',{class:_vm.classes.title,attrs:{"html":_vm.offer.title}})],1),_vm._v(" "),_c('div',{class:{'grid grid-cols-3 gap-x-6 items-center sm:gap-x-2 sm:items-start xs:grid-cols-2': _vm.view === 'horizontal'}},_vm._l((_vm.offerInfo),function(infoItem,infoItemIndex){return _c('div',{key:infoItem.title,class:[
                    _vm.classes.infoItem,
                    {
                        'hidden': infoItemIndex > _vm.maxInfoLength,
                        'lg:flex lg:flex-col': infoItemIndex <= _vm.maxInfoLength && _vm.view === 'horizontal'
                    }
                ]},[_c('span',{class:[_vm.classes.infoItemValue, {'text-2xl': infoItem.code === 'rate' && _vm.view === 'horizontal'}]},[_vm._v("\n                    "+_vm._s(infoItem.value)+"\n                ")]),_vm._v(" "),_c('span',{class:_vm.classes.infoItemTitle},[_vm._v(_vm._s(infoItem.title))])])}),0),_vm._v(" "),_c('div',{staticClass:"a-lg:hidden mt-auto"},[_vm._t("actions",null,null,{extraOfferInfo: _vm.extraOfferInfo})],2)]),_vm._v(" "),_c('div',{class:[_vm.classes.extraInfoContainer, 'lg:hidden']},[(_vm.extraOfferInfo.length && _vm.view !== 'horizontal')?_vm._l((_vm.extraOfferInfo),function(infoItem){return _c('div',{key:infoItem.code,class:[_vm.classes.infoItem, 'pt-3']},[_c('span',{class:_vm.classes.infoItemValue},[_vm._v(_vm._s(infoItem.value))]),_vm._v(" "),_c('span',{class:_vm.classes.infoItemTitle},[_vm._v(_vm._s(infoItem.title))])])}):_vm._e(),_vm._v(" "),_vm._t("actions",null,null,{extraOfferInfo: _vm.extraOfferInfo})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }