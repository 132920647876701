//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '~/components/BaseButton';
import CircleLoading from '~/components/CircleLoading';
import ConstructorField from '~/components/ApplicationForm/ConstructorField';
import SuggestionField from '~/components/SuggestionField';

import processError from '~/helpers/processError';
import validateMixin from '~/mixins/validateMixin';

import CommonService from '~/services/Common';
import { userStore } from '~/store';

const MASKS_DICTIONARY = {
    ru_phone: 'phone'
};

export default {
    components: {
        SuggestionField, BaseButton,
        CircleLoading, ConstructorField
    },
    mixins: [validateMixin('formFields', 'formErrors', {})],
    props: {
        predefinedFieldsValue: { type: Object, default: () => ({}) },
        predefinedFormData: { type: Object },
        formName: { type: String, required: true },
        submitDisabled: { type: Boolean },
        withSuccess: { type: Boolean, default: true },
        isSubmitCentered: { type: Boolean, default: false }
    },
    data() {
        return {
            formPending: false,
            formData: this.predefinedFormData || null,
            formFields: {},
            formErrors: {},
            fetchError: false
        };
    },
    async fetch() {
        try {
            const formData = this.formData || await CommonService.fetchFormData(this.formName, { progress: false });
            if (formData) {
                this.formData = formData;
                const fields = (formData.fields && formData.fields.map(field => ({
                    name: field.name,
                    type: field.type
                }))) || [];
                this.formFields = Object.fromEntries(fields.map(k => [
                    k.name,
                    this.predefinedFieldsValue?.[k.name] !== undefined
                        ? this.predefinedFieldsValue?.[k.name]
                        : k.name === 'name'
                            ? `${ this.predefinedFieldsValue?.['firstname'] || '' } ${ this.predefinedFieldsValue?.['lastname'] || '' }`.trim()
                            : k.type === 'agreement'
                                ? false
                                : ''
                ]));

                const cityField = formData.fields.find(field => field.name === 'city');

                if (cityField && this.currentCityValue) { // предвартельно проставить текущий выбранный город
                    this.formFields.city = { ...this.currentCityValue, value: this.currentCityValue.id };
                }
            }
        } catch (err) {
            this.fetchError = true;
            console.error(err);
        }
    },
    computed: {
        buttonText() {
            return (this.formData && this.formData.form && this.formData.form.buttonText) || 'Отправить';
        },
        hasFormErrors() {
            return Object.keys(this.formErrors).length > 0;
        },
        currentCityValue() {
            return userStore.userCity;
        }
    },
    methods: {
        normalizeMask(maskName) {
            return MASKS_DICTIONARY[maskName] || null;
        },
        async handleSubmit() {
            if (this.formPending) return;

            this.formErrors = {};

            this.validateFormFields();
            if (this.hasFormErrors) return;

            this.formPending = true;

            try {
                const dataSend = Object.fromEntries(Object.keys(this.formFields).map(key => {
                    let value = this.formFields[key];

                    // выбор города
                    if (typeof value === 'object' && value && value.isCitySearch) {
                        value = value.id;
                    }

                    return [key, value];
                }));
                const response = await CommonService.postFormData(this.formName, dataSend, { progress: false });
                const fields = (this.formData.fields && this.formData.fields.map(field => field.name)) || [];
                this.formFields = Object.fromEntries(fields.map(k => [k, '']));
                this.$emit('success');
                if (this.withSuccess) {
                    await this.$layer.alert({
                        title: 'Заявка успешно <br>отправлена',
                        message: (response && response.successText) || 'Заявка отправлена',
                        icon: {
                            name: 'thumbs-up',
                            class: 'text-green-500'
                        }
                    });
                }
            } catch (err) {
                processError(err, this.formErrors);
            } finally {
                this.formPending = false;
            }
        }
    }
};
