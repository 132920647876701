//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formFieldMixin from '~/mixins/formFieldMixin';
import selectFieldMixin from '~/mixins/selectFieldMixin';
import BaseField from '~/components/form/BaseField';
import BaseIcon from '@/components/BaseIcon';

export default {
    components: {
        BaseField,
        BaseIcon
    },
    mixins: [formFieldMixin, selectFieldMixin],
    data: function() {
        return {
            isOpened: false
        };
    },
    methods: {
        async showModal() {
            this.isOpened = true;
            const response = await this.$layer.open('SelectLayer', {
                options: this.options,
                title: this.title,
                selectValue: this.selectValue
            });
            if (typeof response === 'object' && response !== null) {
                this.selectValue = response;
            }
            this.isOpened = false;
        }
    }
};
