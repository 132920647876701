var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes.root},[_c('div',{staticClass:"sticky top-1 z-20"},[_c('div',{staticClass:"overflow-y-auto -scrollbar"},[_c('ToggleGroup',{staticClass:"inline-flex flex-nowrap bg-gray-70 bg-opacity-100",attrs:{"items":_vm.tabs,"toggle-theme":"white","theme":"simple","type":"radio","with-motion":false},model:{value:(_vm.tabActiveId),callback:function ($$v) {_vm.tabActiveId=$$v},expression:"tabActiveId"}})],1)]),_vm._v(" "),_c('div',{staticClass:"relative"},[(_vm.contentTabsActive && _vm.contentTabsActive.length)?_c('div',{class:{
                'absolute -bottom-14 -left-4 -right-4 sm:left-0 sm:right-0 h-8 z-10 pointer-events-none': true,
                'bg-gr-b-from-white-to-transparent': !_vm.reviewExternalAdd,
                'bg-gr-b-from-blue-50-to-transparent': _vm.reviewExternalAdd

            }}):_vm._e()]),_vm._v(" "),_c('div',{class:[_vm.contentClasses, { 'overflow-x-auto -scrollbar': true, 'mt-6 pt-2': _vm.contentTabsActive && _vm.contentTabsActive.length }]},_vm._l((_vm.contentTabsActive),function(contentTab){return _c('div',{key:contentTab.id,staticClass:"mx-1.5"},[(contentTab.isReview && contentTab.vendorId)?_c('div',[_c('VendorReviews',{staticClass:"min-h-[200px]",attrs:{"external-add":_vm.reviewExternalAdd,"vendor-id":contentTab.vendorId}})],1):_c('ContentBlocks',{attrs:{"blocks":_vm.normalizeBlocks(contentTab.content.blocks),"flat":true}})],1)}),0),_vm._v(" "),_c('div',{staticClass:"relative"},[(_vm.contentTabsActive && _vm.contentTabsActive.length)?_c('div',{class:{
                'absolute -top-8 -left-4 -right-4 sm:left-0 sm:right-0 h-8 z-10 pointer-events-none': true,
                'bg-gr-b-from-transparent-to-white': !_vm.reviewExternalAdd,
                'bg-gr-b-from-transparent-to-blue-50': _vm.reviewExternalAdd
            }}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }