//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectField from '~/components/form/SelectField';

import CityService from '@/services/City';

function setCaretPosition(ctrl, pos) {
    if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
        const range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
}
function join(arr) {
    const separator = arguments.length > 1 ? arguments[1] : ', ';

    return arr.filter((n) => { return n; }).join(separator);
}
function makeAddressString(address) {
    if ((address.region || address.area) && !address.city && !address.settlement) {
        return '';
    } else {
        return join([
            address.city_with_type,
            address.settlement_with_type,
            address.street_with_type,
            join([address.house_type, address.house,
                address.block_type, address.block], ' '),
            join([address.flat_type, address.flat], ' ')
        ]);
    }
}
function makeOrganizationString(payload) {
    return `${ payload?.inn }`;
}

const TYPES = {
    address: 'address',
    organization: 'organization',
    city: 'city' // поиск по АПИ, а не daData
};

const TYPES_DATA = {
    [TYPES.address]: {
        endpoint: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        makeString: (payload) => makeAddressString(payload)
    },
    [TYPES.organization]: {
        endpoint: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
        makeString: (payload) => makeOrganizationString(payload)
    }
};

export default {
    components: { SelectField },
    props: {
        value: { type: [String, Object, Number] },
        type: {
            type: String,
            default: TYPES.address,
            validator(value) {
                return Object.values(TYPES).includes(value);
            }
        },
        initialPropSearch: { type: String }, // Помогает идентифицировть начальное значение и выбрать его. Например, можно передать строку с адресом
        title: { type: String, default: 'Адрес' },
        resultsLimit: { type: Number, default: 3 },
        minSearchLength: { type: Number, default: 2 },
        options: { type: Array, default: () => [] },
        error: { type: String },
        requestSettings: { type: Object, default: () => ({}) }
    },
    data: () => ({
        suggestions: [],
        searchQuery: '', // строка поиска
        cancelToken: null,
        isCreated: false // Можно запускать ватчер, все данные инициализированы
    }),
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.searchQuery = newValue?.title || '';
                this.$emit('input', newValue);
            }
        },
        normalizedSuggestions() {
            return this.suggestions.filter(option => option?.title);
        }
    },
    watch: {
        async value(newVal) {
            if (typeof newVal == 'string' && this.isCreated) await this.updateOptions();
        }
    },
    async created() {
        this.suggestions = [...this.suggestions, ...this.options];
        await this.updateOptions();
        this.$nextTick(() => {
            this.$emit('initial-value-set');
            this.isCreated = true;
        });
    },
    methods: {
        /**
         * Получаем адрес
         * @param query
         * @returns {Promise<void>}
         */
        async search(query) {
            if (query.length === 0) { this.suggestions = []; this.inputValue = ''; }
            if (query.length < this.minSearchLength) return; // ничего не меняем если запрос меньше n-символов
            if (this.cancelToken) { this.cancelToken.cancel(); this.cancelToken = null; }

            try {
                this.cancelToken = this.$axios.CancelToken.source();

                // поиск по городам (АПИ)
                if (this.type === TYPES.city) {
                    const suggestionData = await CityService.fetchAllCities({
                        search: query,
                        page: 1,
                        limit: 25
                    });

                    if (suggestionData && suggestionData.items && Array.isArray(suggestionData.items) && suggestionData.items.length) {
                        this.suggestions = suggestionData.items.map(item => ({
                            ...item,
                            value: item.id,
                            isCitySearch: true
                        }));
                    }
                } else {
                    // поиск по адресам/организациям (daData)
                    const suggestionData = await this.$axios.$post(TYPES_DATA[this.type].endpoint, {
                        query,
                        count: this.resultsLimit,
                        ...this.requestSettings
                    }, {
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            Authorization: 'Token ' + process.env.dadataToken
                        },
                        progress: false,
                        cancelToken: this.cancelToken.token
                    });

                    const now = new Date().getTime();
                    let result = suggestionData?.suggestions?.map((res, index) => {
                        const title = TYPES_DATA[this.type].makeString(res.data);

                        return Object.assign(res, {
                            id: now + index,
                            title,
                            note: this.type === TYPES.organization ? res.data?.name?.short_with_opf : '',
                            isDadata: true
                        });
                    });

                    this.suggestions = result || [];
                }
            } catch (err) {
                //
            }
        },
        /** Обработать выбор */
        handleSelect(selectedOption) {
            selectedOption?.title && this.setSearchQuery(selectedOption.title);

            this.$emit('select', selectedOption);
        },
        /** Установить фокус */
        focusSearch() {
            const searchEl = this.$refs.selectField?.$refs?.select?.$refs?.search;
            searchEl && setCaretPosition(searchEl, searchEl.value.length);
            this.setSearchQuery(this.searchQuery);
        },
        /** Добавить новое значение */
        addNewValue(title) {
            const option = { id: new Date().getTime(), title };
            this.suggestions = [option];
            this.inputValue = option;
        },
        setSearchQuery(query) { // Необходимо, чтобы обновить инпут с поиском, иначе выбранное значение не соответстует строке поиска
            const searchEl = this.$refs.selectField?.$refs?.select?.$refs?.search;
            if (searchEl) {
                const inputEvent = new Event('input', {
                    bubbles: true,
                    cancelable: true
                });
                searchEl.value = query;

                searchEl.dispatchEvent(inputEvent);
            }
        },
        async updateOptions() {
            if ((this.inputValue && typeof this.inputValue === 'string') || this.inputValue?.title) {
                const title = this.inputValue?.title || this.inputValue;
                const query = this.initialPropSearch ? `${ this.initialPropSearch } ${ title }` : title;
                await this.search(query);

                if (this.suggestions?.length) {
                    this.inputValue = this.suggestions[0];
                } else {
                    this.addNewValue(this.inputValue);
                }

                await this.$nextTick();
                this.inputValue?.title && this.setSearchQuery(this.inputValue?.title);
            }
        }
    }
};
