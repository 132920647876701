//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseButton from '~/components/BaseButton';

const AVAILABLE_TYPES = ['mixed', 'pages', 'load'];

export default {
    components: {
        BaseButton
    },
    props: {
        type: {
            type: String,
            default: 'load',
            validation: (value) => {
                return AVAILABLE_TYPES.includes(value);
            }
        },
        // Текущая страница
        page: { type: Number, required: true },
        // Общее количество страниц
        pages: { type: Number, required: true },
        // Показывать стрелки?
        arrows: { type: Boolean, default: true },
        // Настройки роута
        route: { type: Object },
        // Сколько ссылок должно быть в главном промежутку
        visible: { type: Number, default: 3 },
        showFirst: { type: Boolean, default: true },
        showLast: { type: Boolean, default: true },
        loading: { type: Boolean, default: false }
    },

    data() {
        return {
            currentPage: this.page
        };
    },

    computed: {
        items() {
            let pages = [];

            if (this.pages > this.visible + 2) {
                let pageStart = this.currentPage - Math.floor(this.visible / 2);

                if (pageStart < 1) {
                    pageStart = 1;

                } else if (pageStart + this.visible > this.pages) {
                    pageStart = this.pages - this.visible + 1;
                }

                pages = [...Array(this.visible).keys()].map((x, index) => pageStart + index);

                if (this.showFirst && this.currentPage > Math.ceil(this.visible / 2)) {
                    if (this.currentPage > Math.ceil(this.visible / 2) + 1) {
                        pages.splice(0, 0, 'delimiter');
                    }

                    pages.splice(0, 0, 1);
                }

                if (this.showLast && this.currentPage < this.pages - Math.floor(this.visible / 2)) {
                    if (this.currentPage < this.pages - Math.floor(this.visible / 2) - 1) {
                        pages.push('delimiter');
                    }

                    pages.push(this.pages);
                }

            } else {
                pages = [...Array(this.pages + 1).keys()].slice(1);
            }

            return pages.map((page) => ({
                page,
                url: typeof page === 'number' ? this.getPageRoute(page) : undefined
            }));
        },
        typeBasedClass() {
            return { [`pagination_${this.type}`]: this.type }; // eslint-disable-line
        },
        prevPage() {
            return this.currentPage - 1 < 1 ? 1 : this.currentPage - 1;
        },
        nextPage() {
            return this.currentPage + 1 > this.pages ? this.pages : this.currentPage + 1;
        }
    },

    watch: {
        ['page'](page) {
            this.currentPage = page;
        }
    },

    methods: {
        getPageRoute(page) {
            if (this.route) return undefined;

            return Object.assign({}, this.route, {
                query: Object.assign({}, this.route.query, {
                    page
                })
            });
        },
        goPage(page, isAppend = false) {
            this.$emit('changePage', { page, isAppend });
            this.currentPage = page;
        }
    }
};
