//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContentText from '~/components/Content/ContentText.vue';
import ContentClues from '~/components/Content/ContentClues.vue';
import ContentHelper from '~/components/Content/ContentHelper.vue';
import ContentCreditproducts from '~/components/Content/ContentCreditProducts.vue';
import ContentImage from '~/components/Content/ContentImage.vue';
import ContentQuote from '~/components/Content/ContentQuote.vue';
import ContentFAQ from '~/components/Content/ContentFAQ';
import ContentVideo from '~/components/Content/ContentVideo.vue';
import ContentGallery from '~/components/Content/ContentGallery.vue';
import ContentJavascript from '@/components/Content/ContentJavascript';
import ContentInform from '@/components/Content/ContentInform';
import ContentWebinarForm from '@/components/Content/ContentWebinarForm';
import ContentFeedbackForm from '@/components/Content/ContentFeedbackForm';
import ContentLinks from '@/components/Content/ContentLinks';
import ContentPartners from '@/components/Content/ContentPartners';
import ExternalWidget from '@/components/ExternalWidget';
import AdBannerController from '@/components/AdBannerController';
import getImage from '~/helpers/getImage';
import ContentReviews from '~/components/Content/ContentReviews.vue';
import ContentAllbanks from '~/components/Content/ContentAllBanks.vue';
import ContentNewsarticles from '~/components/Content/ContentNewsArticles.vue';

const GRID_SIZES = {
    4: 'col-span-4',
    5: 'col-span-5',
    6: 'col-span-6',
    7: 'col-span-7',
    8: 'col-span-8',
    9: 'col-span-9',
    11: 'col-span-11',
    12: 'col-span-12'
};

export default {
    name: 'ContentBlocks',
    components: {
        ContentClues,
        ContentHelper,
        ContentCreditproducts,
        ContentText,
        ContentImage,
        ContentQuote,
        ContentQuestion: ContentFAQ,
        ContentVideo,
        ContentGallery,
        ContentJavascript,
        ContentInform,
        ContentWebinarForm,
        ContentFeedbackForm,
        ContentPartners,
        ExternalWidget,
        AdBannerController,
        ContentLinks,
        ContentReviews,
        ContentAllbanks,
        ContentNewsarticles
    },
    props: {
        /** Блоки */
        isMainPage: {
            type: Boolean,
            default: false
        },
        blocks: {
            type: Array,
            required: true
        },
        flat: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: true
        },
        contentBanner: {
            type: Object,
            default: null
        }
    },
    computed: {
        blocksComponents() {
            return this.blocks.map(block => ({
                block,
                id: block.type + block.id.toString(),
                component: ('content-' + block.type.toLowerCase()),
                size: Object.keys(GRID_SIZES).includes(block.size.toString()) ? GRID_SIZES[block.size] : GRID_SIZES[12]
            }));
        }
    },
    methods: {
        getJsItemId(item) {
            const res = item && item.block && item.block.data ? item.block.data.match(/id:\s'(.*)',/) : null;

            return res && res.length >= 2 ? parseInt(res[1], 10) : null;
        },
        optimizedImageUrl(imageUrl) {
            return getImage(imageUrl, 'rs:fit:596:304');
        }
    }
};
