//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CircleLoading from '@/components/CircleLoading';

import InputField from '~/components/form/InputField.vue';
import debounce from 'lodash/debounce';
import ToggleGroup from '~/components/form/ToggleGroup.vue';
import PartnerService from '~/services/Partner';
import AllBanksSwiper from '~/components/swiper/AllBanksSwiper.vue';

const LIMIT_DEFAULT = 10;

export default {
    components: { AllBanksSwiper, ToggleGroup, InputField, CircleLoading },
    props: {
        selectedCityId: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            search: null,
            category: 1,
            offers: {
                isPending: false,
                error: false,
                items: []
            },
            categories: [
                { id: 1, title: 'Банк' },
                { id: 2, title: 'Микрофинансовая организация' },
                { id: 3, title: 'Лизинговая компания' },
                { id: 4, title: 'Факторинговая компания' },
                { id: 5, title: 'Краунлендинговая площадка' },
                { id: 6, title: 'Кредитный кооператив' },
                { id: 7, title: 'Краундфандинг' },
                { id: 8, title: 'Частный инвестор' }
            ]
        };
    },
    async fetch() {
        return Promise.all([
            this.fetchOffers()
        ]);
    },
    computed: {
        offerItems() {
            return this.offers.items;
        }
    },
    watch: {
        search: debounce(function(value) {
            this.fetchOffers(value, this.category, this.selectedCityId);
        }, 300),
        category: debounce(function(value) {
            this.fetchOffers(this.search, value, this.selectedCityId);
        }, 300),
        selectedCityId: debounce(function(value) {
            this.fetchOffers(this.search, this.category, value);
        }, 300)
    },
    methods: {
        /** Получить предложения */
        async fetchOffers(search, category, cityId) {
            try {
                this.offers.isPending = true;
                const paginatedOffers = await PartnerService.search({ search: search || this.search || undefined, category: category || this.category || undefined, cityId: cityId || undefined },
                    {
                        page: 1,
                        limit: LIMIT_DEFAULT
                    },
                    {
                        progress: false
                    }
                );


                if (paginatedOffers.length) {
                    this.offers.items = paginatedOffers[0].items;
                    this.offers.error = false;
                }
            } catch (err) {
                this.offers.error = true;
                console.error(err);
            } finally {
                this.offers.isPending = false;
            }
        }
    }
};
