import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';
import { BannerDTO, BannerDTOList, BannersGetRequestQuery } from '~/models/Banners';

export default class BannerService {
    /**
     * Получить баннеры
     * @param queryParams - квери-параметры запроса
     * @param config Настройки запроса
     */
    static fetchBanners(queryParams?: BannersGetRequestQuery, config?: RequestConfig): Promise<BannerDTOList> {
        return api.get('/api/banners', {
            ...config,
            params: { ...config?.params, ...queryParams }
        });
    }

    /**
     * Получить баннеры
     * @param id - ID баннера
     * @param config Настройки запроса
     */
    static fetchBanner<R = BannerDTO>(id: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/banners/${ id }`, config));
    }

    /**
     * Отправить отметку о просмотре
     * @param id - ID баннера
     * @param config Настройки запроса
     */
    static setViewBanner<R = BannerDTO>(id: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/banners/${ id }/view`, config));
    }

    /**
     * Отправить отметку о клике
     * @param id - ID баннера
     * @param config Настройки запроса
     */
    static setClickBanner<R = BannerDTO>(id: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/banners/${ id }/click`, config));
    }
}
