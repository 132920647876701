//
//
//
//
//
//
//
//
//
//

import Typograph from '~/components/Typograph';

export default {
    components: { Typograph },
    props: {
        advantages: { type: Array, default: () => [
            { title: 'Экономия времени клиента' },
            { title: 'Цифровой кредитный брокер' },
            { title: 'Более 200 финансовых партнеров' },
            { title: 'Сеть офисов по всей России' }
        ] }
    },
    data() {
        return {
            activeAdvantageIndex: 0
        };
    },
    computed: {
        activeAdvantage() {
            return this.advantages[this.activeAdvantageIndex];
        }
    },
    mounted() {
        this.showAdvantages();
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        showAdvantages() {
            this.timer = setInterval(() => this.showNextAdvantage(), 2000);
        },
        showNextAdvantage() {
            this.activeAdvantageIndex += 1;
            if (this.activeAdvantageIndex >= this.advantages.length) { this.activeAdvantageIndex = 0; }
        }
    }
};
