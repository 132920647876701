//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { userStore } from '~/store';
import prettyPeriod from '@/helpers/prettyPeriod';
import formatNumber from '@/helpers/formatNumber';
import CreditService from '@/services/Credit';
import processError from '@/helpers/processError';
import StatisticsService from '@/services/Statistics';
import createFormData from '@/helpers/createFormData';
import { STATISTIC_ACTIONS } from '@/constants/statistics';
import setUserIdentifierIfNotExists from '@/helpers/setUserIdentifierIfNotExists';
import debounce from 'lodash/debounce';
import { UserTypes } from '@/models/enums';

const RANGE_FIELDS = [
    'amount',
    'duration'
];

export default {
    name: 'CalculatorLogic',
    props: {
        userType: { type: [Number, String], required: true },
        predefinedFilterData: { type: Object, default: () => ({}), required: false }, // предустановленные значения фильтров
        predefinedCreditTypes: { type: Array }, // Предустановленные типы кредитов
        preloaderClass: { type: String, default: '-preloader' },
        externalCreditType: { type: [Number, String], default: null, required: false }
    },
    data() {
        return {
            formData: {},
            isWrongCreditType: false, // creditType отсутствует или не является валидным
            creditTypes: this.predefinedCreditTypes || [],
            creditTypesPending: false,
            filters: null,
            isFiltersPending: false,
            isFiltersReady: false,
            isFirstSetCreditType: true, // первичное определение creditType
            isFirstSetFilters: true // первичное определение значений фильтра
        };
    },
    async fetch() {
        return Promise.all([
            await this.fetchCreditTypes(true, !!this.creditTypes?.length)
        ]);
    },
    computed: {
        isMobile() {
            return this.$device.isMobile;
        },
        creditTypesDesktop() {
            return (Array.isArray(this.creditTypes) ? this.creditTypes : []).filter(item => !item.onlyForAdaptive);
        },
        userFriendlyPeriod() {
            return prettyPeriod(this.formData.duration);
        },
        currentCreditType() {
            if (!this.creditTypes || !this.formData || (this.formData && !this.formData.creditType)) return null;

            return this.creditTypes.find(type => type.id === this.formData.creditType);
        },
        payments() {
            const rate = this.currentCreditType && this.currentCreditType.rate;
            let result = null;

            if (!rate) return result;

            try {
                const monthlyRate = (parseFloat(rate).toFixed(2) / 12 / 100);

                result = Math.round((this.formData.amount / this.formData.duration) + (this.formData.amount * monthlyRate));
            } catch (err) {
                console.error(err);
            }

            return result && (isFinite(result) ? formatNumber(result) : 0);
        },
        creditTypeTitle() { // Название выбранного типа кредитного продукта
            return this.currentCreditType?.title || '';
        },
        creditTypeDescription() { // Описание выбранного типа кредитного продукта
            return this.currentCreditType?.description || '';
        },
        creditTypeBackgroundImage() { // Изображение для промо-блока
            return this.currentCreditType?.image?.file?.url || null;
        },
        filterFields() {
            return (this.filters && this.filters.fields) || [];
        },
        normalizedFilterFields() {
            return this.filterFields
                .filter(field => field.paramName !== 'creditType') // убираем поле creditType, так как выводим его отдельно
                .filter(field => field.type === 3 ? field.options.length > 1 : true); // оставляем только те radio-buttons, у которых кол-во вариантов больше 1
        },
        normalizedFilterFieldsQuantity() {
            return this.normalizedFilterFields.length;
        },
        isAllReady() {
            return this.isFiltersReady;
        },
        formDataCached() {
            return {
                ...this.formData
            };
        },
        userCity() {
            return userStore.userCity;
        }
    },
    watch: {
        userType() {
            this.fetchCreditTypes();
            this.onFormDataChange();
        },
        formDataCached: {
            deep: true,
            handler(value, oldValue) {
                if ((value.creditType !== oldValue.creditType) && !(typeof oldValue.creditType === 'undefined' && this.predefinedFilterData?.creditType && !this.isWrongCreditType)) {
                    this.fetchFilters();
                } else {
                    this.onFormDataChange();
                }
                if (value) {
                    this.hitStatistics(value);
                }
            }
        },
        normalizedFilterFieldsQuantity(value) {
            this.$emit('filters-number-change', value);
        },
        predefinedFilterData: {
            deep: true,
            handler() {
                this.fillFilter();
            }
        }
    },
    methods: {
        onFormDataChange: debounce(function() {
            if (this.isFiltersReady) {
                this.$emit('change', {
                    ...this.formDataCached,
                    userType: this.userType
                });
            }
        }, 300),
        getFieldRange(fieldName, type) {
            let typePropName = null;
            if (type === 'min') {
                typePropName = 'valueFrom';
            } else if (type === 'max') {
                typePropName = 'valueTo';
            }
            const field = this.filterFields.find(field => field.paramName === fieldName);
            if (!typePropName || ! field) { return 0; }

            return parseFloat(field[typePropName]) || 0;

        },
        /** Получить список типов кредитных продуктов */
        async fetchCreditTypes(isRequestFields = false, typesIsDefined = false) {

            const userType = this.userType;
            if (!userType) return Promise.resolve();

            try {
                let types = typesIsDefined ? this.creditTypes : null;

                // типы для частного лица могут быть определены по дефолту, поэтому для private нужно обязательно загружать
                if (!typesIsDefined || userType !== UserTypes.private) {
                    this.creditTypesPending = true;
                    types = await CreditService.fetchCreditProductTypes({ userType }, { progress: false });

                    this.$emit('credit-product-types-updated', { creditTypes: types });
                    this.creditTypesPending = false;
                    this.creditTypes = types;
                }

                // первичное наполнение из пропсов
                if (this.isFirstSetCreditType && this.predefinedFilterData?.creditType) {
                    this.isFirstSetCreditType = false;
                    this.formData.creditType = this.predefinedFilterData.creditType;
                }

                const currentCreditType = this.formData.creditType;

                this.isWrongCreditType = false;

                if (!currentCreditType || (currentCreditType && !types.find(type => type.id === currentCreditType))) {
                    if (!(types && Array.isArray(types) && types.length)) {
                        return;
                    }
                    const firstType = (this.externalCreditType) ? types.find(type => type.id === this.externalCreditType) : types[0];
                    
                    this.$set(this.formData, 'creditType', firstType && firstType.id);
                    this.isWrongCreditType = true;
                } else if (isRequestFields) {
                    await this.fetchFilters('fetchCreditTypes');
                }
            } catch (error) {
                processError(error);
            }
        },
        async fetchFilters() {
            try {
                this.isFiltersPending = true;
                const cityId = this.userCity && this.userCity.id;
                const filters = await CreditService.fetchCreditOffersFilters(this.formData.creditType, {
                    params: {
                        cityId
                    },
                    progress: false
                });

                if (!filters?.fields) {
                    this.isFiltersPending = false;
                    this.isFiltersReady = true;

                    return;
                }

                this.filters = filters;
                this.fillFilter();
              
                this.isFiltersPending = false;
                this.isFiltersReady = true;

                this.onFormDataChange();
            } catch (error) {
                console.log('error', error);
            }
        },
        fillFilter() {
            const filters = this.filters;
            if (!filters) {
                return;
            }
          
            let formData = filters.fields.reduce((result, field) => {
                const paramName = field.paramName;
                if (paramName && paramName !== 'creditType' && RANGE_FIELDS.includes(paramName)) {
                    result[paramName] = (field.optimalValue && field.optimalValue !== 0) ? field.optimalValue
                        : field.valueTo ? Math.ceil(field.valueTo / 3) : field.valueFrom;
                }

                if (field.paramName !== 'creditType' && this.predefinedFilterData && this.predefinedFilterData[paramName]) {
                    result[paramName] = this.predefinedFilterData[paramName];
                }

                return result;
            }, {});
            const currentCreditType = this.creditTypes?.find(item => String(item.id) === String(this.formData.creditType));
            if (this.isMobile) {
                formData.creditType = this.formData.creditType;
            } else {
                if (currentCreditType?.onlyForAdaptive) {
                    formData.creditType = this.creditTypes?.find(item => !item.onlyForAdaptive)?.id;
                } else {
                    formData.creditType = currentCreditType?.id;
                }
            }
            this.formData = formData;
        },
        clearFilters() { // сброс фильтров
            const creditTypeId = this.formData.creditType;

            if (creditTypeId) {
                this.formData = { creditType: creditTypeId };
            } else {
                this.formData = {};
            }

            this.$emit('clear-filters');
        },
        hitStatistics: debounce(function(value) {
            StatisticsService.hit(createFormData({
                sessionId: setUserIdentifierIfNotExists(this.$cookies),
                action: STATISTIC_ACTIONS.FILTER_CHOICE,
                page: this.$route.fullPath,
                creditProductTypeId: value.creditType,
                sum: value.amount,
                period: value.duration
            }), {
                progress: false
            });
        }, 300)
    }
};
