//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Youtube } from 'vue-youtube';
import PlayIcon from '~/assets/sprite/svg/play.svg?inline';
import CircleLoading from '~/components/CircleLoading.vue';
import { getIdFromUrl } from 'vue-youtube';

export default {
    components: {
        Youtube,
        PlayIcon,
        CircleLoading
    },
    props: {
        /** Url-адрес с ютуба */
        videoUrl: {
            type: String,
            required: true
        },
        /** Картинка-превью */
        preview: {
            type: String
        }
    },
    data: () => ({
        isReady: false,
        isPlaying: false,
        isLoading: false,
        playerVars: { // Подробнее https://developers.google.com/youtube/player_parameters?hl=ru
            enablejsapi: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0
        }
    }),
    computed: {
        player() {
            return this.$refs.youtube.player;
        },
        videoCode() {

            return getIdFromUrl(this.videoUrl);
        },
        backgroundImage() {
            return this.preview
                ? `background-image:url('${ this.preview }'`
                : `background-image:url(https://img.youtube.com/vi/${ this.videoCode }/maxresdefault.jpg)`;
        }
    },
    methods: {
        play() {
            this.isReady = true;
            this.isLoading = true;
        },
        async ready() {
            await this.player.playVideo();
            this.isPlaying = true;
            this.isLoading = false;
        }
    }
};
