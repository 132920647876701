/**
 *
 * @param {String} dataProp имя поля с данными для валидации
 * @param {String} errorProp поле для ошибок
 * @param {Object} validations варианты валидации
 * @param {Object <String>} [validations.required] обязательные поля
 * @param {Object, Boolean} [schema] схема данных. Необходима,
 */
export default function validateMixin(dataProp, errorProp, validations, schema = {}) {
    if (!validations) {
        throw new TypeError('validateMixin: Нужно указать проверки');
    }

    const dataName = dataProp.charAt(0).toUpperCase() + dataProp.slice(1);
    const cacheDataName = dataProp + 'Validate';
    let oldData = JSON.parse(JSON.stringify(dataProp));

    return {
        watch: {
            /**
             * Следим за изменением данных чтобы убрать ошибку с поля
             */
            [cacheDataName]: {
                handler(formData) {
                    const errors = Object.assign({}, this[errorProp]);
                    // console.log(formData, oldData);
                    Object.keys(formData).forEach(name => {
                        compare({ currentObj: formData, propName: name, errors, oldObj: oldData, schema: schema });
                    });

                    // Object.keys(formData).filter((name) => formData[name] !== oldData[name])
                    //     .forEach((name) => delete errors[name]);

                    this[errorProp] = errors;
                    oldData = JSON.parse(JSON.stringify(formData));
                },
                deep: true
            }
        },

        methods: {
            ['validate' + dataName]() {
                const errors = Object.assign({}, this[errorProp]);

                if (validations.required) {
                    Object.keys(validations.required).forEach((name) => {
                        validate({ validations: validations.required, propName: name, errors, targetObj: this[dataProp] });
                        // if (!this[dataProp][name]) {
                        //     errors[name] = validations.required[name];
                        // }
                    });
                }

                this[errorProp] = errors;
            }
        },

        computed: {
            [cacheDataName]() {
                return Object.assign({}, this[dataProp]);
            }
        }
    };
}

/**
 * Валидирует данные по переданным правилам
 * @param validations
 * @param propName
 * @param errors
 * @param path - путь до св-ва исходного объекта (прим. person.address.street)
 * @param targetObj - объект с исходными данными
 */
function validate({ validations = {}, propName = null, errors = {}, path = propName, targetObj = {} }) {
    if (Object.keys(validations).length && propName && Object.keys(targetObj).length) {
        if (validations[propName] && typeof validations[propName] === 'object' && !Array.isArray(validations[propName])) { // если св-во является объектом, провалидируем ключи
            const keys = Object.keys(validations[propName]);
            keys.forEach(key => {
                validate({ validations: validations[propName], propName: key, errors, path: `${ propName }.${ key }`, targetObj });
            });
        } else if (validations[propName] && Array.isArray(validations[propName])) { // если св-во является массивом. Берем первый эл-т из правил (предусмотрен объект) и валидируем каждый из элементов внтри исходных данных
            const arrayItemsValidation = validations[propName][0];
            const targetObjItemsLength = targetObj[propName] && targetObj[propName].length;
            if (targetObjItemsLength && arrayItemsValidation && typeof arrayItemsValidation === 'object' && !Array.isArray(arrayItemsValidation)) {
                const keys = Object.keys(arrayItemsValidation);
                for (let i = 0; i < targetObjItemsLength; i++) {
                    keys.forEach(key => {
                        validate({ validations: arrayItemsValidation, propName: key, errors, path: `${ propName }[${ i }][${ key }]`, targetObj });
                    });
                }
            }
        } else if (!deepValue(targetObj, path)) {
            errors[path] = validations[propName];
        }
    }
}

function compare({ currentObj = {}, propName = null, errors = {}, path = propName, oldObj = {}, schema = {} }) {
    if (Object.keys(currentObj).length && propName && Object.keys(oldObj).length) {

        // console.log(propName, path, currentObj[propName], deepValue(oldObj, path));
        const useDeepValidationWithoutSchema = typeof schema === 'boolean' && schema;
        const useDeepValidationWithSchema = typeof schema === 'object' && Object.keys(schema).length;

        if (useDeepValidationWithoutSchema || useDeepValidationWithSchema) { // если передана схема, проверяем на ее соотвествие
            if (currentObj[propName] && typeof currentObj[propName] === 'object' && !Array.isArray(currentObj[propName])) { // св-во является объектом пройдем по его ключам

                // console.log(path, hasOwnPropertyDeep(schema, path), deepValue(schema, path), schema);
                if (useDeepValidationWithoutSchema || (hasOwnPropertyDeep(schema, path) && typeof deepValue(schema, path) !== 'object')) { // если в схеме есть св-во и оно не объект => удалим ошибку, если есть различие с пред. значением
                    if (currentObj[propName] && currentObj[propName] !== deepValue(oldObj, path)) {
                        delete errors[path];
                    }
                } else {
                    const keys = Object.keys(currentObj[propName]);
                    keys.forEach(key => {
                        compare({ currentObj: currentObj[propName], propName: key, errors, path: `${ propName }.${ key }`, oldObj, schema });
                    });
                }
            } else if (currentObj[propName] && Array.isArray(currentObj[propName])) { // св-во является массивом пройдем по его эл-там
                for (let i = 0; i < currentObj[propName].length; i++) {
                    const keys = Object.keys(currentObj[propName][i]);
                    keys.forEach(key => {
                        compare({ currentObj: currentObj[propName][i], propName: key, errors, path: `${ propName }[${ i }][${ key }]`, oldObj, schema });
                    });
                }
            } else if (currentObj[propName] && currentObj[propName] !== deepValue(oldObj, path)) {
                delete errors[path];
            }
        } else if (currentObj[propName] && currentObj[propName] !== deepValue(oldObj, path)) {
            delete errors[path];
        }
    }
}

// function deepValue(obj, path) {
//     return path.split('.').reduce((a, v) => a[v], obj);
// }

function deepValue(obj, path, def) {

    /**
     * If the path is a string, convert it to an array
     * @param  {String|Array} path The path
     * @return {Array}             The path array
     */
    let stringToPath = function(path) {

        // If the path isn't a string, return it
        if (typeof path !== 'string') return path;

        // Create new array
        let output = [];

        // Split to an array with dot notation
        // eslint-disable-next-line no-unused-vars
        path.split('.').forEach(function(item, index) {

            // Split to an array with bracket notation
            item.split(/[[\]]{1,2}/).forEach(function(key) {

                // Push to the new array
                if (key.length > 0) {
                    output.push(key);
                }

            });

        });

        return output;

    };

    // Get the path as an array
    path = stringToPath(path);

    // Cache the current object
    let current = obj;

    // For each item in the path, dig into the object
    for (let i = 0; i < path.length; i++) {

        // If the item isn't found, return the default (or null)
        if (!current[path[i]]) return def;

        // Otherwise, update the current  value
        current = current[path[i]];

    }

    return current;

}

function hasOwnPropertyDeep(obj, propertyPath) {
    if (!propertyPath) return false;

    let newObj = Object.assign({}, obj);
    const properties = propertyPath.split('.');

    for (let i = 0; i < properties.length; i++) {
        let prop = properties[i];
        if (!Object.prototype.hasOwnProperty.call(newObj, prop)) {
            return false;
        } else {
            newObj = newObj[prop];
        }
    }

    return true;
}
