//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimatedPromoArrow from '~/components/AnimatedFigures/AnimatedPromoArrow';
import getImage from '~/helpers/getImage';
import { userStore } from '~/store';
import { UserTypes } from '~/models/enums';
import documentSizeMixin from '~/mixins/documentSizeMixin';

export default {
    components: { AnimatedPromoArrow },
    mixins: [documentSizeMixin],
    props: {
        imageUrl: { type: String, default: null },
        blurArrow: { type: Boolean, default: false },
        withLazyload: { type: Boolean, default: false },
        colors: { type: Array, required: false }
    },
    computed: {
        userType() {
            return userStore.userType;
        },
        backgroundImage() {
            return this.isTouch
                ? getImage(this.imageUrl, 'rs:fit:800:686/format:jpg')
                : getImage(this.imageUrl, 'rs:fit:1424:768/format:jpg');
        },
        backgroundImagePlaceholder() {
            return getImage(this.imageUrl, 'rs:fit:960:600/blur:5/q:25');
        },
        showAnimation() {
            return this.$route.name !== 'offers'; // show animation for both user types but not for offers page
            // return this.userType === UserTypes.company;
        },
        currentUserTypeIsCompany() {
            return this.userType === UserTypes.company;
        },
        isTouch() {
            return this.windowSize.width <= 750;
        }
    }
};
