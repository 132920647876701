//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vue-slider-component/theme/default.css';
import '~/assets/styles/components/vue-slider.css';
import { THEMES } from '~/components/form/BaseField';

export default {
    props: {
        value: { type: [Number, String] },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
        interval: { type: Number, default: 1 },
        tooltip: { type: String, default: 'none' },
        disabled: { type: Boolean, default: false },
        contained: { type: Boolean, default: false },
        lazy: { type: Boolean, default: false },
        theme: {
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        }
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        modifiedSliderStyle() {
            if (this.contained) {
                return {
                    padding: (this.inputValue === this.min || this.inputValue === 0 || this.inputValue === '' || this.inputValue > this.max)
                        ? '0 0 0 7px'
                        : '0'
                };
            }

            return {};
        }
    }
};
