//
//
//
//
//
//
//
//
//
//
//
//

import getImage from '~/helpers/getImage';
import BaseImage from '~/components/BaseImage.vue';

export default {
    components: {
        BaseImage
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    computed: {
        image() {
            return this.block.image && this.block.image.file;
        }
    },
    methods: {
        getImage
    }
};
