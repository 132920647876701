//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FeedbackForm from '@/components/FeedbackForm';
import FaqCardStack from '@/components/CardStack/FaqCardStack';
import cloneDeep from 'lodash/cloneDeep';
export default {
    components: { FaqCardStack, FeedbackForm },
    storageItems: ['homepageFeedback', 'faq'],
    props: {
        title: { type: String },
        description: { type: String },
        faqCards: { type: Array },
        fullWidth: { type: Boolean, default: false }
    },
    computed: {
        templateData() {
            return {
                title: this.title || this.homepageFeedback?.title,
                description: this.description || this.homepageFeedback?.description,
                faqCards: this.faqCards || cloneDeep(this.faq)
            };
        }
    }
};
