//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import formFieldMixin from '@/mixins/formFieldMixin';
import makeClasses from '@/helpers/makeClasses';
import { ZoomYTransition } from 'vue2-transitions';

const THEMES = {
    dark: 'dark',
    light: 'light',
    dark_secondary: 'dark_secondary',
    global: 'global',
    white: 'white'
};
const VIEWS = {
    base: 'base',
    slider: 'slider'
};
const TEXT_SIZE = {
    base: 'base',
    xl: 'xl'
};
export { THEMES, VIEWS, TEXT_SIZE };

const useClasses = makeClasses(() => ({
    root: ({ disabled }) => ({
        'relative flex-auto': true,
        'pointer-events-none': disabled
    }),
    field: ({ view, isFocused, theme, error, type }) => ({
        'relative flex transition-fast': true,
        'border-b': view === VIEWS.base,
        'border-gray-700 border-opacity-[0.32] mouse-device:hover:border-opacity-60': view === VIEWS.base && theme === THEMES.dark,
        'border-gray-700 border-opacity-[0.12] mouse-device:hover:border-opacity-30': view === VIEWS.base && theme === THEMES.dark_secondary,
        'border-white border-opacity-30 mouse-device:hover:border-opacity-60': view === VIEWS.base && theme === THEMES.light,
        'border-primary border-opacity-30 mouse-device:hover:border-opacity-60': view === VIEWS.base && theme === THEMES.global,
        'border-blue border-opacity-100 mouse-device:hover:border-opacity-100': view === VIEWS.base && isFocused,
        'border-red border-opacity-100 mouse-device:hover:border-opacity-100': view === VIEWS.base && error,
        // чтобы содержимое textarea не залезало на title
        // необходимо задавать цвет фона в пропе fieldClassMod для bg-inherit
        'before:block before:absolute before:inset-0 before:h-6 before:pointer-events-none before:bg-inherit': type === 'textarea',
        'min-h-[64px]': type === 'select',
        'bg-white border-2 border-b-[2px] border-[#1F83F8] rounded-[20px] text-black px-[20px]': view === VIEWS.base && theme === THEMES.white
    }),
    title: ({ theme, isFocused, isFilled }) => [
        isFocused || isFilled ? 'translate-y-1 text-sm sm:translate-y-0.5' : 'translate-y-6 text-md',
        {
            'transition-all duration-300 ease-in-out absolute transform pointer-events-none origin-top-left truncate max-w-[90%]': true,
            'text-white text-opacity-50': theme === THEMES.light,
            'text-black text-opacity-50': theme === THEMES.dark || theme === THEMES.dark_secondary || theme === THEMES.white,
            'text-primary text-opacity-50': theme === THEMES.global
        }
    ],
    side: ({ theme }) => ({
        'fill-current flex-shrink-0 flex items-center': true,
        'text-gray-400': theme === THEMES.light,
        'text-gray-650': theme === THEMES.dark || theme === THEMES.dark_secondary
    }),
    prepend: () => ({}),
    append: () => ({}),
    error: () => 'absolute left-0 top-full text-tiny text-red mt-1 sm:mt-0'
}));

export default {
    components: {
        ZoomYTransition
    },
    mixins: [formFieldMixin],
    inheritAttrs: false,
    props: {
        theme: {
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        textSize: {
            default: TEXT_SIZE.base,
            validator(value) {
                return Object.keys(TEXT_SIZE).includes(value);
            }
        },
        fieldClassMod: { type: String, default: '', required: false }
    },
    computed: {
        classes() {
            return useClasses({
                theme: this.theme,
                view: this.view,
                isFocused: this.focus,
                isFilled: typeof this.value === 'number' ? true : !!this.value,
                disabled: this.disabled,
                error: !!this.error,
                type: this.type
            });
        }
    }
};
