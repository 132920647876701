//
//
//
//
//

import { BANNERS_CLICK_COOKIE_NAME, BANNERS_VIEW_COOKIE_NAME } from '@/constants/banners';
import BannerService from '@/services/Banner';

const BANNER_STATISTICS_ACTION = {
    VIEW: 'VIEW',
    CLICK: 'CLICK'
};
export default {
    name: 'AdBannerController',
    props: {
        banner: { type: Object, required: true }
    },
    computed: {
        bannerId() {
            return this.banner.id;
        },
        isModalExists() {
            return !!(this.banner.modalTitle && this.banner.modalText);
        }
    },
    mounted() {
        this.setBannerStatistics(BANNER_STATISTICS_ACTION.VIEW, BANNERS_VIEW_COOKIE_NAME);
    },
    methods: {
        onBannerClick(e) {
            if (this.isModalExists) {
                e.stopPropagation();
                e.preventDefault();

                this.openFeedbackLayer();

                return;
            }

            this.setBannerStatistics(BANNER_STATISTICS_ACTION.CLICK, BANNERS_CLICK_COOKIE_NAME);
        },
        async setBannerStatistics(action, cookieName) {
            let actionName = '';

            if (action === BANNER_STATISTICS_ACTION.VIEW) {
                actionName = 'setViewBanner';
            } else if (action === BANNER_STATISTICS_ACTION.CLICK) {
                actionName = 'setClickBanner';
            }

            const id = this.bannerId;
            if (!id || !actionName) {
                return;
            }
            const uniq = !this.isIncludesOnArrayCookie(cookieName, id);
            if (uniq) {
                this.pushToArrayCookie(cookieName, id);
            }

            await BannerService[actionName](id, {
                params: {
                    uniq
                },
                progress: false
            });
        },
        isIncludesOnArrayCookie(cookieName, value) {
            const items = this.$cookies.get(cookieName) || [];

            return items.includes?.(value);
        },
        pushToArrayCookie(cookieName, value) {
            if (!cookieName) {
                return;
            }
            const items = this.$cookies.get(cookieName) || [];
            items.push(value);
            this.$cookies.set(cookieName, items);
        },
        openFeedbackLayer() {
            const title = this.banner.modalTitle;
            const subtitle = this.banner.modalText;

            this.$layer.open('FeedbackLayer', {
                title,
                subtitle
            });
        }
    }
};
