//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputField from '~/components/form/InputField';
import SelectField from '~/components/form/SelectField';
import BaseToggle from '~/components/form/BaseToggle';
import ToggleGroup from '~/components/form/ToggleGroup';
import CalendarField from '~/components/form/CalendarField';
import SuggestionField from '~/components/SuggestionField';
import Typograph from '~/components/Typograph';
import { ZoomYTransition } from 'vue2-transitions';

const VALID_FIELD_TYPES = ['text', 'select', 'one-checkbox', 'radio-group', 'calendar', 'organization'];
export { VALID_FIELD_TYPES };

const VALIDATION_TO_MASK = {
    ru_phone: 'phone',
    digits: 'numeric'
};

export default {
    components: {
        InputField, SelectField, BaseToggle,
        ToggleGroup, CalendarField, SuggestionField,
        Typograph, ZoomYTransition
    },
    inheritAttrs: false,
    props: {
        fieldData: { type: Object, required: true },
        value: { type: [String, Number, Object, Boolean] },
        error: { type: String },
        theme: { type: String },
        customType: { type: String },
        customTitle: { type: String },
        mask: { type: String }, // маска - актуальна для текстовых полей
        disabled: { type: Boolean, default: false, required: false },
        isToggleGroupWithMotion: { type: Boolean, default: true, required: false }
    },
    computed: {
        fieldValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        fieldType() {
            return this.customType || this.fieldData?.type;
        },
        fieldTitle() {
            return this.customTitle || this.fieldData?.title;
        },
        fieldMask() {
            let result = this.mask;

            if (!result) {
                switch (this.fieldData?.name) {
                    case 'carPtsSeriesAndNumber': {
                        result = 'pts';
                        break;
                    }
                    case 'carVin': {
                        result = 'vin';
                        break;
                    }
                    case 'carYearOfRelease': {
                        result = 'year';
                        break;
                    }
                    case 'oooOkpo': {
                        result = 'businessOKPO';
                        break;
                    }
                    case 'okpo': {
                        result = 'individualOKPO';
                        break;
                    }
                    case 'oooOgrn': {
                        result = 'businessOGRN';
                        break;
                    }
                    case 'ogrnip': {
                        result = 'individualOGRN';
                        break;
                    }
                    case 'realEstateCadastralNumber': {
                        result = 'cadastralNumber';
                        break;
                    }
                    default: {
                        //
                    }
                }

                if (!result && Object.keys(VALIDATION_TO_MASK).includes(this.fieldData?.validationType)) {
                    result = VALIDATION_TO_MASK[this.fieldData.validationType];
                }
            }

            return result;
        }
    }
};
