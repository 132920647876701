export enum WIDGET_TYPES {
    TEXT = 'text',
    QUOTE = 'quote',
    QUESTION_ANSWER = 'questionAnswer',
    IMAGE = 'image',
    GALLERY = 'gallery',
    VIDEO = 'video',
    JAVASCRIPT = 'javaScript',
    INFORM = 'inform',
    WEBINAR_FORM = 'webinarForm',
    FORM = 'form',
    FEEDBACK_FORM = 'feedbackForm',
    PARTNERS = 'partners',
    CLUES = 'clues',
    CREDIT_PRODUCTS = 'creditProducts',
    LINKS = 'links',
}

export enum WIDGET_TYPES_MAP {
    TEXT = 'text',
    QUOTE = 'quote',
    QUESTION_ANSWER = 'question',
    IMAGE = 'image',
    GALLERY = 'gallery',
    VIDEO = 'video',
    JAVASCRIPT = 'javaScript',
    INFORM = 'inform',
    WEBINAR_FORM = 'webinar-form',
    FORM = 'form',
    FEEDBACK_FORM = 'feedback-form',
    PARTNERS = 'partners',
    CLUES = 'clues',
    CREDIT_PRODUCTS = 'credit-products',
    LINKS = 'links',
}
