//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { layoutStore, pageStore, userStore } from '~/store';
import PromoBlock from '~/components/PromoBlock';
import PromoAdvantages from '~/components/PromoAdvantages';
import PartnersSwiper from '~/components/swiper/PartnersSwiper';
import PromoOffersSwiper from '~/components/swiper/PromoOffersSwiper';
import IntersectionObserver from '~/components/IntersectionObserver';
import SortOffers from '~/components/SortOffers';
import BaseButton from '~/components/BaseButton';
import CreditBoard from '~/components/CreditBoard';
// import NewsCatalog from '~/components/NewsCatalog';
import BestOffersBlock from '@/components/Offer/BestOffersBlock';
import getImage from '@/helpers/getImage';
import ContentBlocks from '@/components/Content/ContentBlocks';

import BannerService from '~/services/Banner';
import CreditService from '~/services/Credit';
import { UserTypes } from '~/models/enums';
import debounce from 'lodash/debounce';
import { BANNER_PLACES } from '~/constants/banners';
import { CREDIT_PRODUCT_RESPONSE_TYPES } from '~/constants/credits';
import { OFFERS_SORT_ARRAY } from '~/helpers/sortOffers';
import InlineFeedbackForm from '@/components/InlineFeedbackForm';
import { normalizeBase } from '@/helpers/normalizeContentBlocks';
import PageService from '~/services/Page';

export default {
    storageItems: ['partners', 'homepageAdvantages', 'homepageSeo'],
    name: 'IndexPage',
    components: {
        ContentBlocks,
        // InlineFeedbackForm,
        BestOffersBlock,
        PromoBlock, PromoAdvantages,
        PartnersSwiper, PromoOffersSwiper,
        CreditBoard, IntersectionObserver,
        SortOffers,
        BaseButton
        // NewsCatalog
    },
    async asyncData({ error, route }) {
        const { path } = route;
        const data = {
            page: null
        };

        try {
            const [creditTypes] = await Promise.all([
                await CreditService.fetchCreditProductTypes({ userType: userStore.userType })
            ]);
            const firstType = creditTypes && Array.isArray(creditTypes) && creditTypes.length && creditTypes[0];
            const typeId = firstType?.id || null;
            const activePromoImageId = typeId;
            const holderActivePromoImageId = typeId;
            const creditBoardData = { typeOfCredit: typeId, amount: null, duration: null };
            const banners = { items: [], pending: false, error: false };

            if (typeId) {
                const bannersResp = await BannerService.fetchBanners({
                    userType: userStore.userType,
                    cityId: userStore.userCity && userStore.userCity.id,
                    creditProductTypeIds: [typeId],
                    place: BANNER_PLACES.PROMOBLOCK
                });
                banners.items = (bannersResp && bannersResp.items) || [];
            }

            const page = await PageService.fetchData(path);

            data.page = page;

            if (page) {
                pageStore.update(page);
            }

            if (page.content && page.content.id) {
                data.page.content = await PageService.fetchContent(page.content.id);
            } else {
                data.page.content = page.content;
            }

            return { creditTypes, creditBoardData, activePromoImageId, holderActivePromoImageId, banners, ...data };
        } catch (err) {
            pageStore.reset();
            error({ statusCode: 404 });
        } finally {
            if (process.server) {
                pageStore.fix();
            }
        }
    },
    data: () => ({
        page: null,
        // Основные данные
        banners: {
            items: [],
            pending: false,
            error: false
        },
        offers: { // Кредитные предложения
            items: [],
            pending: false,
            error: false
        },
        creditTypes: [], // Типы кредитных продуктов
        creditOffersLength: null, // кол-во кредитных предложений

        // Вспомогательные данные
        showBeneficialOffersGradient: false, // показать градиент для блока Выгодные предложения
        creditBoardData: {
            typeOfCredit: null, // Выбранный тип кредита в промо-блоке
            amount: null, // Сумма кредита
            duration: null // Срок кредита
        },
        activePromoImageId: null, // активный тип кредитного продукта в промоблоке. Помогает найти изображение
        holderActivePromoImageId: null, // активный тип кредитного продукта в промоблоке при ховере. Помогает показать изображение во время наведения мыши
        chosenSortingType: OFFERS_SORT_ARRAY[0].id, // Выбранный по тип сортировки предложения
        sortingTypes: OFFERS_SORT_ARRAY,
        chosenBeneficialOffers: [], // выбранные выгодные предложения,
        isBeneficialCardCheckboxVisible: false, // показать значок чекбокса на карточках OfferCard (выгодные предложения)
        isTouch: false, // Тач устройство
        isMounted: false,
        isFirstBackgroundChange: false, // Изменился бэкграунд. Необходимо, чтобы избежать ленивой загрузки изображений и вследствие мельканий перключения
        offersFilterData: {}
    }),
    computed: {
        normalizedBlocks() {
            const blocks = this.page?.content?.blocks;
            if (!blocks) {
                return [];
            }

            return blocks
                .map(item => {
                    return this.normalizeBase(item);
                })
                .filter(x => x);
        },
        userCity() {
            return userStore.userCity;
        },
        userType() {
            return userStore.userType;
        },
        fetchParams() {
            const userCityId = this.userCity && this.userCity.id;
            const currentCreditTypeId = this.creditBoardData && this.creditBoardData['typeOfCredit'];
            const query = {};
            Object.assign(query, userCityId ? { cityId: userCityId } : {});
            Object.assign(query, this.userType ? { userType: this.userType } : {});
            Object.assign(query, currentCreditTypeId ? { creditProductTypeId: currentCreditTypeId } : {});

            return query;
        },
        fetchOffersExtraParams() { // доп. кваэри-параметры для получения списка предложений
            const currentCreditTypeId = this.creditBoardData && this.creditBoardData['typeOfCredit'];
            const currentSortingType = this.currentOfferSortType && this.currentOfferSortType.key;
            const query = {};

            Object.assign(query, this.userType ? { userType: this.userType } : {});
            Object.assign(query, currentCreditTypeId ? { creditType: currentCreditTypeId } : {});
            Object.assign(query, currentSortingType ? { sort: currentSortingType } : {});

            return query;
        },
        currentOfferSortType() {
            return this.sortingTypes.find(item => item.id === this.chosenSortingType);
        },
        promoImages() {
            return this.creditTypes?.map(creditType => creditType?.image?.file?.url).filter(x => !!x);
        },
        promoBackgroundImage() { // Изображение для промо-блока
            const currentCreditType = this.creditTypes.find(creditOption => creditOption.id === this.activePromoImageId);

            return currentCreditType && currentCreditType.image && currentCreditType.image.file && currentCreditType.image.file.url;
        },
        promoAdvantages() {
            return this.homepageAdvantages ||  [
                { title: 'Экономия времени клиента' },
                { title: 'Цифровой кредитный брокер' },
                { title: 'Более 200 финансовых партнеров' },
                { title: 'Сеть офисов по всей России' }
            ];
        },
        currentUserTypeIsCompany() {
            return this.userType === UserTypes.company;
        },
        goToOffersPageQuery() {
            return {
                userType: this.userType,
                creditType: this.creditBoardData.typeOfCredit,
                amount: this.creditBoardData.amount,
                duration: this.creditBoardData.duration
            };
        },
        seoContent() {
            return {
                title: pageStore.seoTitle,
                tag: pageStore.seoTag,
                description: pageStore.seoContent
            };
        },
        currentCreditType() {
            return this.creditTypes?.find(item => String(item.id) === String(this.offersFilterData.creditType));
        },
        isCurrentCreditTypeDisabled() {
            return this.currentCreditType?.onlyForAdaptive === true;
        }
    },
    watch: {
        async fetchParams(newVal, oldVal) {
            if (newVal?.cityId !== oldVal?.cityId || newVal?.creditProductTypeId !== oldVal?.creditProductTypeId) await this.fetchBanners();
        },
        promoChosenCreditOption(val) {
            this.holderActivePromoImageId = val;
        },
        holderActivePromoImageId(id) {
            if (id) { this.debouncedActivePromoImage(); }
            this.isFirstBackgroundChange = true;
        },
        'creditBoardData.typeOfCredit': function(activeType) {
            if (this?.creditTypes && Array.isArray(this.creditTypes) && activeType) {
                this.activePromoImageId = this.holderActivePromoImageId = activeType;
            }
        }
    },
    mounted() {
        this.isMounted = true;
        this.isTouch = 'ontouchstart' in window;
        // const postscribe = require('postscribe');
        //
        // postscribe('#rsya', `
        //         <!-- Yandex.RTB R-A-5754028-1 -->
        //             window.yaContextCb.push(() => {
        //                 window.Ya.Context.AdvManager.render({
        //                     blockId: 'R-A-5754028-1',
        //                     type: 'floorAd',
        //                     platform: 'touch'
        //                 });
        //             });
        //     `);
    },
    created() {
        layoutStore.updateHeaderTheme('transparent');
        this.debouncedActivePromoImage = debounce(this.changeActivePromoImage, 300);
    },
    methods: {
        getTagImage(imageUrl) {
            return getImage(imageUrl, 'rs:fit:30:30');
        },
        isThirdTag(index) {
            return ((index + 1) % 3 === 0) && ((index + 1) !== 0);
        },
        isForthTag(index) {
            return ((index + 1) % 4 === 0) || ((index + 1) === 1);
        },
        isFifthTag(index) {
            return ((index + 1) % 5 === 0) || ((index + 1) === 2);
        },
        getImage,
        normalizeBase,
        /** Получить баннеры */
        async fetchBanners() {
            try {
                this.banners.pending = true;
                const dataSend = {
                    userType: this.fetchParams.userType,
                    cityId: this.fetchParams.cityId,
                    creditProductTypeIds: [this.fetchParams.creditProductTypeId],
                    place: BANNER_PLACES.PROMOBLOCK
                };
                const banners = await BannerService.fetchBanners(dataSend);
                this.banners.items = banners.items;
                this.banners.error = false;
            } catch (err) {
                this.banners.error = true;
                console.error(err);
            } finally {
                this.banners.pending = false;
            }
        },
        onFilterChange(payload) {
            this.offersFilterData = payload;
            this.fetchCreditBoardOffersCount(payload);
        },
        async fetchCreditBoardOffersCount(payload) {
            if (!payload) return;

            try {
                const dataSend = {
                    ...payload,
                    city: this.fetchParams?.cityId,
                    typeResponse: CREDIT_PRODUCT_RESPONSE_TYPES.COUNT
                };
                this.creditOffersLength = await CreditService.fetchCreditOffers(dataSend, { progress: false });
            } catch (err) { // silent fail
                console.error(err);
            }
        },
        onSearchCreditOffers(searchInput) { // переход на страницу предложений с фильтрацией по поисковому запросу (по нажатию на Enter)
            if (!searchInput) { return; }

            this.$router.push({
                name: 'offers',
                query: {
                    ...this.goToOffersPageQuery,
                    partnerTitle: searchInput.toLowerCase()
                }
            });
        },

        // анимация градиента фона для блока Выгодные предложения
        onBeneficialOffersAppear() {
            this.showBeneficialOffersGradient = true;
        },
        onBeneficialOffersDisappear() {
            this.showBeneficialOffersGradient = false;
        },
        changeActivePromoImage() {
            this.activePromoImageId = this.holderActivePromoImageId;
        },
        // поменять активный фон в промо при наведении на вариант кредитования
        onCreditOptionMouseEnter(id) {
            if (this.currentUserTypeIsCompany) { return; }
            this.holderActivePromoImageId = id;
        },
        onCreditOptionsMouseLeave() {
            this.debouncedActivePromoImage.cancel();
            this.holderActivePromoImageId = this.creditBoardData['typeOfCredit'];
        },
        // обновились типы кред. продуктов из калькулятора
        onCreditProductTypesUpdated(payload) {
            this.creditTypes = payload?.creditTypes || null;
        },

        // показать значок чекбокса на карточках предложений
        showCheckboxOnOfferCard() {
            this.isBeneficialCardCheckboxVisible = true;
        },

        // очистить выбранные предложения, спрятать значок чекбокса
        clearChosenOffers() {
            this.chosenBeneficialOffers = [];
            this.isBeneficialCardCheckboxVisible = false;
        }
    }
};
