//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getImage from '@/helpers/getImage';
import BaseImage from '@/components/BaseImage';
import BaseIcon from '@/components/BaseIcon';
import makeClasses from '@/helpers/makeClasses';

import { ZoomCenterTransition } from 'vue2-transitions';

const THEMES = {
    dark: 'dark',
    light: 'light'
};

// language=HTML prefix=<div\u0020class=" suffix="\\/>
const useClasses = makeClasses(() => ({
    root: ({ theme }) => ['relative flex flex-col p-3 border rounded-xl', {
        'border-gray-700 border-opacity-10 text-black': theme === THEMES.dark,
        'border-white border-opacity-30 text-white': theme === THEMES.light
    }],
    header: () => 'flex mb-3',
    logoContainer: () => 'relative -mt-6 flex items-center justify-center w-16 h-16 rounded-md bg-gray-50 filter drop-shadow-lg overflow-hidden',
    logo: () => 'max-w-full max-h-full',
    remove: ({ theme }) => ['flex items-center justify-center w-9 h-9 ml-auto rounded-lg transition-main', {
        'bg-black bg-opacity-[0.06] hover:bg-opacity-10': theme === THEMES.dark,
        'bg-white bg-opacity-[0.06] hover:bg-opacity-[0.14]': theme === THEMES.light
    }],
    removeIcon: () => 'text-gray-400 fill-current',
    title: ({ theme }) => ['text-xs mb-3', {
        'text-gray-650': theme === THEMES.dark,
        'text-white': theme === THEMES.light
    }],
    description: () => 'font-bold mb-4',
    footer: () => 'flex items-center mt-auto',
    rate: () => 'mr-4',
    rateNote: ({ theme }) => ['ml-auto text-tiny', {
        'text-gray-650': theme === THEMES.dark,
        'text-white opacity-60': theme === THEMES.light
    }]
}));

export default {
    components: {
        BaseImage,
        BaseIcon,
        ZoomCenterTransition
    },
    props: {
        offer: {
            type: Object,
            required: true
        },
        theme: {
            type: String,
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        allowRemove: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        classes() {
            return useClasses({
                theme: this.theme
            });
        },
        bank() {
            return this.offer && this.offer.vendor;
        },
        bankImage() {
            return getImage((this.bank?.image?.url) || '', 'rs:fit:64:64');
        },
        normalizedRate() {
            const prefix = this.offer?.rateFrom ? 'от ' : '';

            return `${ prefix }${ this.offer?.rateFrom
                ? parseFloat(this.offer?.rateFrom?.replace(/[^\d.]*/g, ''))
                : parseFloat(this.offer?.rateTo?.replace(/[^\d.]*/g, ''))
            }%`;
        }
    },
    methods: {
        getImage,
        remove() {
            this.$emit('remove-offer', this.offer.id);
        }
    }
};
