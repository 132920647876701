//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getImage from '~/helpers/getImage';
import Typograph from '~/components/Typograph.vue';

export default {
    name: 'ContentClues',
    components: { Typograph },
    props: {
        isMainPage: {
            type: Boolean,
            default: false
        },
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    },
    methods: {
        formatData(text) {
            return text?.replace(/<h4/gi, '<div class="title-h4" ')
                .replace(/<h5/gi, '<div class="title-h5" ')
                .replace(/<h6/gi, '<div class="title-h6" ')
                .replace(/<\/h[4-6]>/gi, '</div>');
        },
        optimizedImageUrl(image) {
            const imageUrl = image && image.file.url;

            return imageUrl ? getImage(imageUrl) : null;
        }
    }
};
