import { render, staticRenderFns } from "./ContentBlocks.vue?vue&type=template&id=7cd64383&scoped=true&"
import script from "./ContentBlocks.vue?vue&type=script&lang=js&"
export * from "./ContentBlocks.vue?vue&type=script&lang=js&"
import style0 from "./ContentBlocks.vue?vue&type=style&index=0&id=7cd64383&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd64383",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdBannerController: require('/app/components/AdBannerController.vue').default,ExternalWidget: require('/app/components/ExternalWidget.vue').default})
