var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'relative group break-words bg-white': true,
        'border border-gray-700 border-opacity-[0.12] rounded-base p-4 cursor-pointer': _vm.view === 'base',
        'bg-accent bg-opacity-[var(--theme-accent-opacity)]': _vm.isGlobalTheme
    }},[_c('div',{class:{
            'absolute': true,
            'text-xs top-4 right-4': _vm.view === 'base',
            'top-5 right-0': _vm.view === 'simple',
            'text-gray-650': !_vm.isGlobalTheme,
            'text-primary opacity-50': _vm.isGlobalTheme
        }},[_vm._v("\n        "+_vm._s(_vm.relativeDate)+"\n    ")]),_vm._v(" "),_c('div',{class:{
            'relative h-[2.25rem]': true,
            'mb-11 lg:mb-8': _vm.view === 'simple',
        }},[_c('BaseImage',{class:{
                'absolute rounded-md': true,
                'w-12 transform -translate-y-7 mouse-device:transition-medium mouse-device:group-hover:-translate-y-10': _vm.view === 'base',
                'w-14 lg:w-12': _vm.view === 'simple'
            },attrs:{"src":_vm.sourceImage}})],1),_vm._v(" "),(_vm.post && !_vm.youtubeVideo)?_c('Typograph',{class:['mb-3', {'text-lg md:text-base': _vm.view === 'simple'}],attrs:{"as":"div","html":_vm.post}}):_vm._e(),_vm._v(" "),(_vm.isLongPost && _vm.post && !_vm.showFullContent && !_vm.youtubeVideo)?_c('div',[(_vm.isDetailLink)?_c('BaseButton',{staticClass:"my-3",attrs:{"view":"link","theme":"transparent_blue","href":_vm.detailLink,"target":"_blank"}},[_vm._v("\n            Показать полностью...\n        ")]):_c('BaseButton',{staticClass:"my-3",attrs:{"view":"link","theme":"transparent_blue"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openNewsCardLayer($event)}}},[_vm._v("\n            Показать полностью...\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.contentImage && !_vm.youtubeVideo)?_c('div',{staticClass:"my-3"},[_c('BaseImage',{staticClass:"w-full",attrs:{"src":_vm.contentImage}})],1):(_vm.newsData.video)?_c('div',{staticClass:"my-3",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('MediaVideo',{attrs:{"video-url":_vm.newsData.video}})],1):(_vm.youtubeVideo)?_c('div',[_c('MediaVideo',{attrs:{"video-url":_vm.youtubeVideo}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-6"},[_c('div',{staticClass:"flex items-center justify-between"},[(_vm.sourceTitle)?_c('div',{staticClass:"font-bold mb-1"},[_vm._v("\n                "+_vm._s(_vm.sourceTitle)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',[(_vm.newsData.source.creditRequestLink)?_c('BaseButton',{attrs:{"size":"GAP_X_SM","href":_vm.newsData.source.creditRequestLink}},[_vm._v("\n                    Открыть кредитную заявку\n                ")]):_vm._e()],1)]),_vm._v(" "),(_vm.subscriberCount && _vm.newsData.author)?_c('div',{staticClass:"typography flex items-center justify-between text-xs mt-4"},[(_vm.subscriberCount)?_c('div',{class:{
                    'text-gray-650': !_vm.isGlobalTheme,
                    'text-primary opacity-50': _vm.isGlobalTheme
                }},[_vm._v("\n                "+_vm._s(_vm.subscriberCount)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.newsData.author)?_c('div',[_vm._v("\n                "+_vm._s(_vm.newsData.author)+"\n            ")]):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }