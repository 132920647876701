//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasCursorMixin from '~/mixins/hasCursorMixin';
import BaseIcon from '~/components/BaseIcon';
import CardStack from '~/components/CardStack/CardStack';

export default {
    components: { CardStack, BaseIcon },
    mixins: [hasCursorMixin],
    props: {
        cards: { type: Array, required: true }, // массив карточек
        withNav: { type: Boolean, required: false, default: false }, // нужны ли стрелочки навигации
        cardWidth: { type: Number, required: false, default: 300 }, // ширина карточки
        cardHeight: { type: Number, required: false, default: 400 }, // высота карточки
        stackWidth: { type: [Number, String], required: false }, // ширина контейнера
        maxVisibleCards: { type: Number, required: false }, // сколько карточек всегда показывать на экране
        paddingVertical: { type: Number, required: false }, // расстояние сверху и снизу "колоды" карточек
        paddingHorizontal: { type: Number, required: false } // расстояние по бокам "колоды" карточек
    },
    computed: {
        items() {
            if (this.cards.length < 4) {
                return [...this.cards, ...(new Array(4 - this.cards.length)
                    .fill(null)
                    .map((item, index) => {
                        return {
                            ...this.cards[index % this.cards.length]
                        };
                    }))];
            }

            return this.cards;
        }
    },
    mounted() {
        this.defineIfDeviceHasCursor();
    },
    methods: {
        /**
         * @param value {number} - показывает, на сколько сдвинулась карточка (может быть < 0, от 0 до 1, > 1)
         */
        onMove(value) {
            this.$emit('onmove', value);
        }
    }
};
