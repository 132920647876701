export default {
    data() {
        return {
            isDeviceHasCursor: false
        };
    },
    methods: {
        defineIfDeviceHasCursor() {
            if (typeof window === 'undefined') {
                return;
            }

            this.isDeviceHasCursor = window.matchMedia('(pointer: fine)').matches;
        }
    }
};
