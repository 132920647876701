let resizeAF = null;
let resizeTimer = null;
const callbacks = [];
let windowWidth;
let windowHeight;
let bodyHeight;
let windowWidthWas;
let windowHeightWas;
let bodyHeightWas;
let isBodyResized = false;
const watch = function(callback) {
    stop();
    callbacks.push(callback);
    if (!resizeAF) {
        resizeAF = requestAnimationFrame(resizeCallback);
    }
};
const unwatch = function(callback) {
    callbacks.splice(callbacks.indexOf(callback), 1);
};
const stop = function() {
    if (resizeAF) {
        clearTimeout(resizeTimer);
        cancelAnimationFrame(resizeAF);
        resizeAF = null;
    }
};
const resizeCallback = function() {
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
    bodyHeight = document.body.scrollHeight;
    if (windowWidth != windowWidthWas || windowHeight != windowHeightWas || bodyHeight != bodyHeightWas) {
        windowWidthWas = windowWidth;
        windowHeightWas = windowHeight;
        isBodyResized = bodyHeight != bodyHeightWas;
        bodyHeightWas = bodyHeight;
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            callbacks.forEach((callback) => callback.call(null, {
                width: windowWidth,
                height: windowHeight,
                bodyHeight,
                isBodyResized
            }));
        }, 250);
    }
    resizeAF = requestAnimationFrame(resizeCallback);
};
export { stop, watch, unwatch };
