//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import makeClasses from '~/helpers/makeClasses';
import UserAvatar from '~/components/UserAvatar';

// language=HTML prefix=<div\u0020class=" suffix="\\/>
const useClasses = makeClasses(
    () => ({
        root: () => ['relative overflow-hidden'],
        wrapper: () => ['relative'],
        content: () => ['text-2xl md:text-lg'],
        author: () => ['flex items-center pl-[100px] mt-6 -mx-1.5 md:pl-0'],
        authorAvatarWrapper: () => ['px-1.5'],
        authorAvatar: () => ['flex-shrink-0'],
        authorInfo: () => ['text-xs text-gray-650 flex-auto px-1.5']
    })
);

export default {
    components: {
        UserAvatar
    },
    props: {
        author: {
            type: Object,
            required: false
        }
    },
    computed: {
        classes() {
            return useClasses({});
        }
    }
};
