//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '@/mixins/documentSizeMixin';
import BaseImage from '@/components/BaseImage';
import Typograph from '@/components/Typograph';
import BaseIcon from '@/components/BaseIcon';
import BaseTooltipTag from '@/components/BaseTooltipTag';
import CheckItem from '@/components/CheckItem';
import makeClasses from '@/helpers/makeClasses';
import getImage from '@/helpers/getImage';

const THEMES = {
    white: 'white',
    outline_light_gray: 'outline_light_gray'
};
const VIEWS = {
    vertical: 'vertical',
    horizontal: 'horizontal'
};
const TAG_THEMES = {
    white: 'white',
    blue: 'blue'
};
export { THEMES, VIEWS, TAG_THEMES };

const useClasses = makeClasses(() => ({
    root: ({ view, theme }) => ({
        'relative p-4 rounded-base group transition-main hover:z-[1]': true,
        'max-w-[179px] lg:flex lg:flex-col': view === VIEWS.vertical,
        'w-full flex': view === VIEWS.horizontal,
        'bg-white border border-white will-change-transform mouse-device:hover:bg-blue-50 mouse-device:hover:border-blue-250 mouse-device:hover:border-opacity-[0.12] mouse-device:hover:filter mouse-device:hover:drop-shadow-light-blue': theme === THEMES.white,
        'bg-white border border-gray-700 border-opacity-[0.12] will-change-transform hover:border-gray-700 hover:border-opacity-[0.12] hover:border-b-white a-lg:hover:rounded-b-none lg:hover:border-gray-700 lg:hover:border-opacity-[0.12] mouse-device:hover:filter mouse-device:hover:drop-shadow-light-black': theme === THEMES.outline_light_gray
    }),
    popularTag: ({ view }) => ({
        hidden: view === VIEWS.vertical,
        'absolute -top-1.5 left-[8.5rem] bg-gray-650 text-white text-tiny rounded-sm py-px px-1 lg:left-24 sm:left-18': view === VIEWS.horizontal
    }),
    imageContainer: ({ view }) => ({
        'relative h-12': view === VIEWS.vertical,
        'flex-shrink-0 mr-6 lg:mr-4': view === VIEWS.horizontal
    }),
    image: ({ view }) => ({
        'rounded-md filter drop-shadow-lg': true,
        'absolute transition-main w-16 h-16 transform -translate-y-7 group-hover:-translate-y-10 lg:-translate-y-10': view === VIEWS.vertical,
        'w-24 h-24 lg:w-16 lg:h-16 sm:w-10 sm:h-10': view === VIEWS.horizontal
    }),
    tag: ({ view, isChosen, isCheckboxVisible }) => ({
        absolute: true,
        'opacity-0 pointer-events-none transition-fast': isChosen || isCheckboxVisible,
        'top-3 right-3': view === VIEWS.vertical,
        'top-2 right-2 flex -mx-1 sm:top-4 sm:right-4': view === VIEWS.horizontal
    }),
    checkboxIcon: ({ view }) => ({
        absolute: true,
        'top-4 right-3.5': view === VIEWS.vertical,
        'top-3 right-3 sm:top-4 sm:right-4': view === VIEWS.horizontal
    }),
    checkboxInput: () => 'absolute inset-0 opacity-0 pointer-events-none',
    content: ({ view }) => ({
        'lg:flex-col lg:flex-auto': true,
        'lg:flex': view === VIEWS.vertical,
        flex: view === VIEWS.horizontal
    }),
    bankTitle: ({ view }) => ({
        'text-gray-650 text-xs break-word': true,
        'mb-0.5': view === VIEWS.vertical,
        'mr-2 sm:mr-0 sm:mb-1': view === VIEWS.horizontal
    }),
    reviewsButton: ({ view }) => ({
        'transition-fast hover:opacity-60 relative': true,
        'mb-3': view === VIEWS.vertical
    }),
    reviewsContainer: () => 'flex items-center -mx-0.5 whitespace-nowrap',
    star: () => ({
        'mx-0.5 fill-current': true
    }),
    reviewText: () => 'mx-0.5 text-xxs text-gray-650',
    title: ({ view }) => ({
        'text-black break-word': true,
        'mb-4 font-bold pr-3': view === VIEWS.vertical,
        'text-lg': view === VIEWS.horizontal
    }),
    infoItem: ({ view }) => ({
        'break-word': true,
        'grid grid-cols-2 items-center gap-x-0.5 mb-2 last:mb-0': view === VIEWS.vertical,
        'col-span-1': view === VIEWS.horizontal
    }),
    infoItemValue: ({ view }) => ({
        'break-word': true,
        'text-black font-normal': true,
        'col-span-1 text-left': view === VIEWS.vertical,
        'lg:order-2': view === VIEWS.horizontal
    }),
    infoItemTitle: ({ view }) => ({
        'col-span-1 text-gray-650 text-tiny text-right': view === VIEWS.vertical,
        'a-lg:hidden lg:order-1 text-xs text-gray-650 mb-0.5': view === VIEWS.horizontal
    }),
    extraInfoContainer: ({ theme, view }) => ({
        'pt-0 rounded-b-base transition-main absolute group-hover:pointer-events-auto group-hover:opacity-100 a-lg:opacity-0 a-lg:pointer-events-none lg:relative': true,
        'bg-white mouse-device:bg-blue-50 mouse-device:border mouse-device:border-t-0 mouse-device:border-blue-250 mouse-device:border-opacity-[0.12]': theme === THEMES.white,
        'border border-t-0 border-gray-700 border-opacity-[0.12] bg-white': theme === THEMES.outline_light_gray,
        'p-4 -right-px -left-px lg:p-0': view === VIEWS.vertical,
        'pl-4 pr-5 pb-4 -right-px -left-px top-full flex justify-between lg:p-0': view === VIEWS.horizontal
    })
}));

const TAG_ICON_DICTIONARY = {
    popular: 'diamond',
    local: 'geo-mark'
};

const MAX_INFO_LENGTH = 2;

export default {
    components: { BaseTooltipTag, BaseIcon, Typograph, BaseImage, CheckItem },
    mixins: [documentSizeMixin],
    props: {
        /**
         * {offer.title} {String}
         * {offer.id} {String|number}
         * {offer.bank} {Object}
         * {offer.rate} {String}
         * {offer?.time} {String}
         * {offer?.methods} {String}
         * {offer?.approvalRate} {String}
         * {offer?.href} {String}
         * {offer?.tags} {Array}
         * {offer?.isPopular} {Boolean}
         * {offer?.isBanner} {Boolean} - рекламный баннер
         */
        offer: { type: Object, default: () => ({}) },
        view: {
            default: VIEWS.vertical,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        theme: {
            default: THEMES.white,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        tagTheme: {
            default: TAG_THEMES.blue,
            validator(value) {
                return Object.keys(TAG_THEMES).includes(value);
            }
        },
        // является ли карточка чекбоксом
        isCheckbox: { type: Boolean, default: true, required: false  },
        // значение чекбокса
        value: { type: [Boolean, String, Number, Array] },
        // показать значок чекбокса (спрячутся теги, если есть)
        isCheckboxVisible: { type: Boolean, default: false, required: false  }
    },
    data() {
        return {
            maxBankRating: 5,
            maxInfoLength: MAX_INFO_LENGTH
        };
    },
    computed: {
        classes() {
            return useClasses({
                view: this.view,
                theme: this.theme,
                isChosen: this.isChosen,
                isCheckboxVisible: this.isCheckboxVisible
            });
        },
        normalizedTags() {
            if (!this.offer.tags.length) { return []; }

            return this.offer.tags.map(tag => ({
                ...tag,
                icon: TAG_ICON_DICTIONARY[tag.code]
            }));
        },
        optimizedImage() {
            return getImage(this.offer.bank.image, 'rs:fit:308:308');
        },
        offerInfo() {
            return [
                {
                    title: 'Ставка',
                    code: 'rate',
                    value: this.offer.rate,
                    alwaysVisible: true
                },
                {
                    title: 'Время одобрения',
                    code: 'time',
                    value: this.offer.time,
                    alwaysVisible: true
                },
                {
                    title: 'Способы оформления',
                    code: 'methods',
                    value: this.offer.methods,
                    alwaysVisible: true
                },
                {
                    title: 'Вероятность одобрения',
                    code: 'approvalRate',
                    value: this.offer.approvalRate,
                    alwaysVisible: false
                }
            ];
        },
        extraOfferInfo() {
            return this.offerInfo.filter((infoItem, index) => index > this.maxInfoLength);
        },
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        // выбрали ли данную карточку
        isChosen() {
            if (Array.isArray(this.inputValue)) {
                return !!this.inputValue.find(it => it === this.offer.id);
            }

            return false;
        },
        isMobile() {
            return this.windowSize.width <= 600;
        }
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            this.maxInfoLength = MAX_INFO_LENGTH;

            if (this.windowSize.width < 1000 && this.view === 'vertical') {
                this.maxInfoLength = 3;
            }

            if (this.windowSize.width < 500 && this.view === 'horizontal') {
                this.maxInfoLength = 1;
            }
        }
    }
};
