//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DisclosureHeadless from '~/components/Disclosure/DisclosureHeadless';
import BaseIcon from '~/components/BaseIcon';
import { CollapseTransition } from 'vue2-transitions';

export default {
    components: {
        DisclosureHeadless,
        BaseIcon,
        CollapseTransition
    },
    model: {
        prop: '_value',
        event: 'toggle'
    },
    props: {
        // eslint-disable-next-line vue/prop-name-casing
        _value: {
            type: [String, Number, Array],
            required: true
        },
        value: {
            type: [String, Number],
            required: true
        },
        image: {
            type: [String]
        },
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        modelValue: {
            get() {
                return this._value;
            },
            set(val) {
                this.$emit('toggle', val);
            }
        }
    }
};
