//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Typograph from '~/components/Typograph';
import BaseIcon from '~/components/BaseIcon';
import makeClasses from '~/helpers/makeClasses';
import getImage from '~/helpers/getImage';

const VIEWS = {
    bank: 'bank',
    person: 'person'
};

const useClasses = makeClasses(() => ({
    header: () => 'flex items-center',
    imageContainer: () => 'relative w-12 h-12 flex-shrink-0 mr-3 flex',
    image: ({ view }) => ({
        'flex-1 bg-cover bg-center': true,
        'rounded-md filter drop-shadow-lg': view === VIEWS.bank,
        'rounded-full': view === VIEWS.person
    }),
    infoContainer: () => 'flex-auto',
    title: () => 'text-gray-650 text-xs break-word mb-1',
    ratingWrapper: () => 'relative',
    ratingContainer: () => 'flex items-center -mx-0.5 whitespace-nowrap',
    ratingText: () => 'mx-0.5 text-xxs text-gray-650',
    star: 'mx-0.5 fill-current',
    text: 'text-sm mt-3 text-black',
    date: 'text-gray-650 text-xs mt-3'
}));

export default {
    components: {
        BaseIcon, Typograph
    },
    props: {
        reviewData: { type: Object, default: () => ({}) },
        view: {
            type: String,
            default: VIEWS.person,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        }
    },
    data: () => ({
        maxBankRating: 5
    }),
    computed: {
        optimizedImage() {
            return getImage(this.reviewData?.image?.url, 'rs:fit:308:308');
        },
        formattedDate() {
            return this.reviewData.date;
        },
        classes() {
            return useClasses({
                view: this.view
            });
        },
        VIEWS() {
            return VIEWS;
        }
    }
};
