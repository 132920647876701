//
//
//
//
//
//
//
//
//

export default {
    model: {
        prop: '_value',
        event: 'toggle'
    },
    props: {
        // eslint-disable-next-line vue/prop-name-casing
        _value: {
            type: [String, Number, Array],
            required: true
        },
        value: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        modelValue: {
            get() {
                return this._value;
            },
            set(val) {
                this.$emit('toggle', val);
            }
        },
        isOpen() {
            if (!this.value) { return false; }

            if (Array.isArray(this.modelValue)) {
                return this.modelValue.includes(this.value);
            }

            return this.modelValue === this.value;
        },
        slotProps() {
            return {
                isOpen: this.isOpen,
                doClose: this.doClose,
                doOpen: this.doOpen,
                doToggle: this.doToggle
            };
        }
    },
    methods: {
        doClose() {
            if (Array.isArray(this.modelValue)) {
                this.$emit('toggle', this.modelValue.filter(value => value !== this.value));
            } else {
                this.$emit('toggle', null);
            }
        },
        doOpen() {
            if (Array.isArray(this.modelValue)) {
                this.$emit('toggle', [...this.modelValue, this.value]);
            } else {
                this.$emit('toggle', this.value);
            }
        },
        doToggle() {
            this.isOpen ? this.doClose() : this.doOpen();
        }
    }
};
