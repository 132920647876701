//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToggleGroup from '@/components/form/ToggleGroup';
import Typograph from '~/components/Typograph';

export default {
    name: 'ContentHelper',
    components: { ToggleGroup, Typograph },
    props: {
        isMainPage: {
            type: Boolean,
            default: false
        },
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    },
    data: function() {
        return {
            modelInputValue: this.block.items.length ? this.block.items[0].sectionTitle : null
        };
    },
    computed: {
        formattedData() {
            return this.selectedItem && this.selectedItem.content ? this.selectedItem.content.replace(/<h4/gi, '<div class="title-h4" ')
                .replace(/<h5/gi, '<div class="title-h5" ')
                .replace(/<h6/gi, '<div class="title-h6" ')
                .replace(/<\/h[4-6]>/gi, '</div>') : '';
        },
        selectedItem() {
            return this.block.items.find(b => {
                return b.sectionTitle === this.modelInputValue;
            });
        },
        sortingTypes() {
            return this.block.items.map(b => {
                return {
                    id: b.sectionTitle,
                    title: b.sectionTitle,
                    key: b.sectionTitle
                };
            });
        }
    }
};
