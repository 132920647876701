//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToggleGroup from '@/components/form/ToggleGroup';
import makeClasses from '@/helpers/makeClasses';
import { WIDGET_TYPES, WIDGET_TYPES_MAP } from '@/constants/widget';
import getImage from '@/helpers/getImage';
import VendorReviews from '@/components/VendorReviews';

const VARIANTS = {
    HORIZONTAL: 'HORIZONTAL',
    VERTICAL: 'VERTICAL'
};

const useClasses = makeClasses(() => ({
    root: ({ variant }) => ({
        'p-4 sm:pl-0 sm:pr-0 sm:pt-3': variant = VARIANTS.VERTICAL,
        'pb-0 a-lg:p-4 lg:pt-4': variant === VARIANTS.HORIZONTAL
    })
}));

export default {
    name: 'OfferCardDetail',
    components: { VendorReviews, ContentBlocks: () => import('@/components/Content/ContentBlocks'), ToggleGroup },
    props: {
        variant: { type: String, default: VARIANTS.HORIZONTAL },
        contentClasses: { type: [String, Object, Array], default: '' },
        contentTabs: { type: Array, required: true },
        reviewExternalAdd: { type: Boolean, default: false }
    },
    data() {
        return {
            tab: null
        };
    },
    computed: {
        contentTabsNotEmpty() {
            return (this.contentTabs && Array.isArray(this.contentTabs) ? this.contentTabs : [])
                .filter(item => (item && item.content && item.content.blocks && item.content.blocks.length) || item.isReview);
        },
        contentTabsActive() {
            return this.contentTabsNotEmpty.filter(item => item.id === this.tabActiveId);
        },
        tabs() {
            return this.contentTabsNotEmpty.map((item) => {

                return {
                    id: item.id,
                    title: item.title
                };
            });
        },
        tabActiveId: {
            get() {
                return this.tab || this.tabs?.[0]?.id;
            },
            set(tabId) {
                this.tab = tabId;
            }
        },
        classes() {
            return useClasses({
                variant: this.variant
            });
        }
    },
    methods: {
        normalizeBlocks(blocks) {
            if (!blocks || !Array.isArray(blocks)) {
                return [];
            }

            return blocks
                .map((item) => {
                    return this.normalizeBase(item);
                })
                .filter(x => x);
        },
        normalizeBase(block) {
            if (!block) { return; }
            const baseNormalized = {
                ...block,
                size: block.size === 1 ? 6 : 12
            };

            if (baseNormalized.type === WIDGET_TYPES.TEXT) {
                return this.normalizeText(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.IMAGE) {
                return this.normalizeImage(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.QUOTE) {
                return this.normalizeQuote(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.QUESTION_ANSWER) {
                return this.normalizeFaq(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.GALLERY) {
                return this.normalizeGallery(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.VIDEO) {
                return this.normalizeVideo(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.JAVASCRIPT) {
                return this.normalizeJavascript(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.INFORM) {
                return this.normalizeInform(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.WEBINAR_FORM) {
                return this.normalizeWebinarForm(baseNormalized);
            }

            if (baseNormalized.type === WIDGET_TYPES.FORM) {
                return this.normalizeForm(baseNormalized);
            }
        },
        normalizeText(block) {
            return block;
        },
        normalizeImage(block) {
            return block;
        },
        normalizeJavascript(block) {
            return block;
        },
        normalizeQuote(block) {
            return {
                ...block,
                subText: block.authorDescription,
                authorImage: getImage(block.authorAvatar, 'rs:fill:64:64/dpr:2')
            };
        },
        normalizeFaq(block) {
            return {
                ...block,
                type: WIDGET_TYPES_MAP.QUESTION_ANSWER,
                questions: (Array.isArray(block.faq) ? block.faq : []).map((faqItem) => {
                    return {
                        title: faqItem.question,
                        answer: faqItem.answer
                    };
                })
            };
        },
        normalizeGallery(block) {
            return {
                ...block
            };
        },
        normalizeVideo(block) {
            const video = block.video;
            if (block.video) {
                video.imageUrl = block.image_url;
                video.embedUrl = block.embed_url;
            }

            return {
                ...block,
                video
            };
        },
        normalizeInform(block) {
            return {
                ...block,
                sourceIds: (Array.isArray(block.sources) ? block.sources : []).map(item => item.id),
                isNormalContainer: true // Контейнер с обычной шириной
            };
        },
        normalizeWebinarForm(block) {
            return {
                ...block,
                type: WIDGET_TYPES_MAP.WEBINAR_FORM
            };
        },
        normalizeForm(block) {
            return {
                ...block
            };
        }
    }
};
