//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '@/mixins/documentSizeMixin';
import BaseCardStack from '@/components/CardStack/BaseCardStack';
import Typograph from '@/components/Typograph';
import BaseIcon from '@/components/BaseIcon';
import getImage from '@/helpers/getImage';

export default {
    components: { BaseCardStack, Typograph, BaseIcon },
    mixins: [documentSizeMixin],
    props: {
        offers: { type: Array, required: true },
        useParentWidth: { type: Boolean, required: false, default: false }
    },
    data() {
        return {
            cardSize: 500,
            isMoving: false
        };
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            let width = this.windowSize.width;

            if (this.useParentWidth) {
                width = this.$parent.$el.clientWidth;
            }

            if (width <= 500) {
                this.cardSize = 250;
            }  else if (width <= 1000 && width > 500) {
                this.cardSize = 300;
            } else if (width <= 1200 && width > 1000) {
                this.cardSize = 400;
            } else {
                this.cardSize = 500;
            }
        },
        /**
         * @param val {number}
         */
        onMove(val) {
            this.isMoving = (val > 0 && val < 1) || (val < 0) || (val > 1);
        },
        getImage
    }
};
