//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseImage from '~/components/BaseImage';
import BaseButton from '~/components/BaseButton';
import MediaVideo from '~/components/MediaVideo';
import getImage from '~/helpers/getImage';
import formatDate from '~/helpers/formatDate';
import pluralize from '~/helpers/pluralize';
import formatNumber from '~/helpers/formatNumber';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { LAYER_NEWS_DETAIL } from '@/models/Layer';
import { INFORM_SOURCE_TYPES } from '@/constants/inform';
import Typograph from '@/components/Typograph';
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const VIEWS = {
    base: 'base',
    simple: 'simple'
};
export { VIEWS };

const THEMES = {
    global: 'global'
};

const SOURCE_IMAGES = {
    telegram: '/images/channels/telegram.png',
    twitter: '/images/channels/twitter.png',
    youtube: '/images/channels/youtube.png'
};

const CONTENT_LENGTH_LIMIT = 638;

export default {
    components: { Typograph, MediaVideo, BaseButton, BaseImage },
    props: {
        newsData: { type: Object, required: true },
        // показать текст полностью
        showFullContent: { type: Boolean, default: false, required: false },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        isDetailLink: { type: Boolean, default: false },
        theme: { type: String, required: false }
    },
    computed: {
        relativeDate() {
            const publicationDate = dayjs(this.newsData.createdAt);
            const isTodayPublication = publicationDate.isToday();
            const isYesterdayPublication = publicationDate.isYesterday();
            const publicationYear = publicationDate.year();
            const currentYear = dayjs().year();

            let resultDay = formatDate(this.newsData.createdAt, publicationYear === currentYear ? 'D MMM' : 'D MMM YYYY');
            if (isTodayPublication) resultDay = 'сегодня';
            if (isYesterdayPublication) resultDay = 'вчера';

            return resultDay;
        },
        sourceType() {
            return this.newsData?.source?.type;
        },
        sourceImage() {
            const icon = (this.sourceType && SOURCE_IMAGES[this.sourceType]) || '';

            return getImage(icon, 'rs:fit:48:48/dpr:2');
        },
        sourceTitle() {
            return this.newsData?.source?.title;
        },
        contentImage() {
            const imageUrl = this.newsData.content.image && this.newsData.content.image.file.url;

            return imageUrl ? getImage(imageUrl, 'rs:fit:415:415/dpr:2') : null;
        },
        post() {
            if (!this.newsData.content.text) { return ''; }

            if (this.showFullContent || !this.isLongPost) { return this.newsData.content.text; }

            return this.newsData.content.text
                .substring(0, CONTENT_LENGTH_LIMIT)
                .split(' ')
                .slice(0, -1)
                .join(' ');
        },
        isLongPost() {
            return this.newsData.content.text.length >= CONTENT_LENGTH_LIMIT;
        },
        detailLink() {
            if (!this.newsData.id) {
                return '';
            }

            return `/?${ LAYER_NEWS_DETAIL }=${ this.newsData.id }`;
        },
        subscriberCount() {
            return this.newsData?.source?.subscriberCount && `${ formatNumber(this.newsData.source.subscriberCount) } ${ pluralize('подписка|подписки|подписок', this.newsData.source.subscriberCount) }`;
        },
        isGlobalTheme() {
            return this.theme === THEMES.global;
        },
        youtubeVideo() {
            if (INFORM_SOURCE_TYPES.YOUTUBE === this.sourceType && this.newsData.url) {
                return this.newsData.url;
            }

            return null;
        }
    },
    methods: {
        openNewsCardLayer() {
            if (this.showFullContent || !this.newsData.id) { return; }

            this.$layer.open('NewsCardDetailLayer', {
                id: this.newsData.id
            });
        }
    }
};
