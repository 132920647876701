//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseNotification from '@/components/BaseNotification';
import BaseButton from '@/components/BaseButton';
import BaseIcon from '@/components/BaseIcon';

export default {
    components: { BaseButton, BaseIcon },
    props: {
        actionTheme: { type: String, default: 'black', required: false },
        showNotification: { type: Boolean, default: true, required: false },
        chosenOffers: { type: Array, default: () => [], required: true },
        initialText: { type: String, default: 'Выбрать несколько', required: false },
        cancelText: { type: String, default: 'Отмена', required: false }
    },
    data() {
        return {
            // кликнули по кнопке
            isClicked: false,
            // название кнопки
            buttonTitle: this.initialText
        };
    },
    watch: {
        isClicked(isVisible) {
            if (isVisible) {
                this.$emit('show-checkbox');
                this.buttonTitle = this.cancelText;

                if (this.showNotification) {
                    // показать нотификацию
                    this.$toast.info(
                        {
                            component: BaseNotification,
                            props: { message: '<span>Отметьте</span>' + '<svg class="mx-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                                    '<g filter="url(#filter0_i_687_12974)">\n' +
                                    '<rect width="24" height="24" rx="4" fill="white"/>\n' +
                                    '</g>\n' +
                                    '<rect x="0.25" y="0.25" width="23.5" height="23.5" rx="3.75" stroke="#B5B5B5" stroke-width="0.5"/>\n' +
                                    '<defs>\n' +
                                    '<filter id="filter0_i_687_12974" x="0" y="0" width="24" height="25" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
                                    '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
                                    '<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>\n' +
                                    '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
                                    '<feOffset dy="1"/>\n' +
                                    '<feGaussianBlur stdDeviation="1.5"/>\n' +
                                    '<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>\n' +
                                    '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>\n' +
                                    '<feBlend mode="normal" in2="shape" result="effect1_innerShadow_687_12974"/>\n' +
                                    '</filter>\n' +
                                    '</defs>\n' +
                                    '</svg>\n' + '<span class="mr-1">продукты,</span><span class="mr-1">которые</span><span class="mr-1">хотите</span><span>выбрать</span>' }
                        },
                        {
                            toastClassName: ['Vue-Toastification_padding_base']
                        });
                }
            } else {
                this.buttonTitle = this.initialText;
                this.$emit('clear-choices');
            }
        },
        chosenOffers(offers) {
            // следим за кол-вом выбранных предложений и меняем статус кнопки
            this.isClicked = !!offers.length;
        }
    },
    methods: {
        toggleIsClicked() {
            this.isClicked = !this.isClicked;
        }
    }
};
