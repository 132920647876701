import { ReviewList } from '~/models/Review';
import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';

export default class PartnerService {
    static getList<R = ReviewList>(config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get('/api/partner', config));
    }

    static search<R = ReviewList>(queryParams: {}, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get('/api/partner/all', {
            ...config,
            params: { ...config?.params, ...queryParams }
        }));
    }
}
