import fromPairs from 'lodash/fromPairs';

/**
 * Позволяет работать с каталогом.
 * Работает с данными фильтра, query-параметрами
 */
export default {
    data: () => ({
        filterData: {},
        isUseFalseValues: false, // проверять значения false и брать их за значение. Необходимо для сортировки
        isUseZeroValues: true // проверять значения 0 и брать их за значение. Необходимо для сортировки
    }),
    computed: {
        queryFilters() {
            const activeFilters = this.getActiveFiltersQuery(this.filterData);

            return Object.keys(activeFilters).reduce((result, key) => {
                const value = activeFilters[key];

                if (!value && !this.isUseFalseValues) { return result; }
                if (Array.isArray(value)) {
                    if (value.length > 0) { result[key] = value; }

                    return result;
                } else if (typeof value === 'string') {
                    if (value.length > 0) { result[key] = value; }

                    return result;
                } else {
                    result[key] = value;
                }

                return result;
            }, {});
        }
    },
    methods: {
        /**
         * Возвращает активные фильтры в виде объекта
         * @returns {{}}
         */
        getActiveFiltersQuery(filter = this.filterData) {
            return fromPairs(Object.entries(filter || {}).filter(([_key, value]) => {
                return this.isUseFalseValues
                    ? value === false || Boolean(value)
                    : this.isUseZeroValues
                        ? value === 0 || Boolean(value)
                        : Boolean(value);
            }));
        }
    }
};

