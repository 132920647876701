import { render, staticRenderFns } from "./index.vue?vue&type=template&id=35550161&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromoAdvantages: require('/app/components/PromoAdvantages.vue').default,BaseButton: require('/app/components/BaseButton.vue').default,SortOffers: require('/app/components/SortOffers.vue').default,CreditBoard: require('/app/components/CreditBoard.vue').default,PromoBlock: require('/app/components/PromoBlock.vue').default,IntersectionObserver: require('/app/components/IntersectionObserver.vue').default,ContentBlocks: require('/app/components/Content/ContentBlocks.vue').default,SeoContent: require('/app/components/SeoContent.vue').default})
