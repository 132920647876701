//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '@/mixins/documentSizeMixin';
import BaseButton from '@/components/BaseButton';
import CreditOffer from '@/components/Offer/CreditOffer';
import BaseIcon from '@/components/BaseIcon';
import OfferCardDetail from '@/components/Offer/OfferCardDetail';
import formatNumber from '@/helpers/formatNumber';
import pluralize from '@/helpers/pluralize';
import formatTime from '@/helpers/formatTime';

export default {
    components: { OfferCardDetail, BaseIcon, CreditOffer, BaseButton },
    mixins: [documentSizeMixin],
    props: {
        useParentWidth: { type: Boolean, required: false, default: false },
        creditTypeMultipleApplicationDisabled: { type: Boolean, required: false, default: false },
        // подробнее о пропсах ---> в OfferCard
        offer: { type: Object, default: () => ({}) },
        isCheckbox: { type: Boolean, default: true, required: false  },
        value: { type: [Boolean, String, Number, Array] },
        isCheckboxVisible: { type: Boolean, default: false, required: false  },
        creditTypeId: { type: Number }, // выбранный тип кредита
        theme: { type: String }, // выбранный тип кредита
        urlTo: { type: [Object, String] },
        reviewExternalAdd: { type: Boolean, default: false },
        disableResizer: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // показать подробную информацию о предложении на адаптиве
            isMoreInfoVisible: false,
            isDetailInfoVisible: false,
            // название кнопки показа подробной информации о карточке
            showMoreInfoButtonTitle: 'Подробнее'
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isTablet() {
            let width = this.windowSize.width;

            if (this.useParentWidth) {
                width = this.$parent.$el.clientWidth;
            }

            return width <= 1000;
        },
        applicationUrl() {
            if (this.offer.referralLinkActive && this.offer.referralLink && this.offer.referralLink.length) {
                return this.offer.referralLink;
            }

            let url = { name: 'application', query: { offers: this.offer?.id, creditType: this.creditTypeId } };

            if (this.urlTo) {
                url = typeof this.urlTo === 'object'
                    ? { ...this.urlTo, query: { ...(this.urlTo?.query || {}), offers: this.offer?.id } }
                    : this.urlTo;
            }

            return url;
        },
        contentTabs() {
            const tabs = [];
            const contentTabs = (this.offer?.contentTabs && Array.isArray(this.offer.contentTabs) ? this.offer.contentTabs : []);
            const vendorId = this.offer?.vendor?.id;
            const countReview = this.offer?.vendor?.countReview;
            const reviewsTab = {
                id: Math.random(),
                title: 'Отзывы',
                isReview: true,
                vendorId
            };

            contentTabs.forEach((tab, tabIndex) => {
                if (tabIndex === 1 && vendorId && countReview) {
                    tabs.push(reviewsTab);
                }

                tabs.push(tab);
            });

            if (contentTabs.length < 2 && vendorId) {
                tabs.push(reviewsTab);
            }

            return tabs;
        },
        hasContentTabs() {
            return this.contentTabs && this.contentTabs.length > 0;
        }
    },
    watch: {
        isMoreInfoVisible(isVisible) {
            if (isVisible) {
                this.showMoreInfoButtonTitle = 'Свернуть';
            } else {
                this.showMoreInfoButtonTitle = 'Подробнее';
            }
        }
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        // тоггл видимости блока с подробной информацией о предложении на адаптиве
        onToggleShowMoreOfferInfo() {
            this.isMoreInfoVisible = !this.isMoreInfoVisible;
            this.onToggleOfferDetailInfo();
        },
        onToggleOfferDetailInfo() {
            this.isDetailInfoVisible = !this.isDetailInfoVisible;
        },
        onWindowResize() {
            if (this.disableResizer) {
                return;
            }
            this.isMoreInfoVisible = this.windowSize.width > 1000;
        },
        showDetailLayer() {
            if (!this.hasContentTabs) {
                return;
            }

            this.$layer.open('OfferDetailLayer', {
                contentTabs: this.contentTabs
            });
        }
    }
};
