import { render, staticRenderFns } from "./CreditBoard.vue?vue&type=template&id=2ca949ef&"
import script from "./CreditBoard.vue?vue&type=script&lang=js&"
export * from "./CreditBoard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/app/components/BaseIcon.vue').default,BaseSlider: require('/app/components/BaseSlider.vue').default,CalculatorLogic: require('/app/components/CalculatorLogic.vue').default})
