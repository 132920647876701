import { ImageProp } from '~/models/Files';
import { CreditOfferDTO } from '~/models/Credits';
import { NewsSource } from '~/models/News';
import { LAYOUT_THEMES } from '~/constants/layout';

export enum EXTERNAL_WIDGET_TYPES {
    pick = 'pick',
    offer = 'offer',
    advert = 'advert',
    promo = 'promo',
    inform = 'inform'
}

interface ExternalWidgetBase {
    id: number;
    bannerPlace?: string;
}

export interface ExternalWidgetPick extends ExternalWidgetBase {
    monthlyPercentageRate: number;
    borrowerTypes: number[];
    partnerId: number;
    creditProductTypeId: number;
}

export interface ExternalWidgetOffer extends ExternalWidgetBase {
    sortTypes: string[];
}

export interface ExternalWidgetAdvert extends ExternalWidgetBase {
    creditProducts: CreditOfferDTO[];
}

export interface ExternalWidgetPromo extends ExternalWidgetBase {
    promos: {
        id: number;
        title: string;
        text: string;
        image: ImageProp;
        url: string;
    }[];
}

export interface ExternalWidgetInform extends ExternalWidgetBase {
    id: number
    buttonTitle: string;
    buttonUrl: string;
    sources: NewsSource[];
}

interface ExternalWidgetShort {
    id: number;
    type: EXTERNAL_WIDGET_TYPES;
}
export type ExternalWidgetBlocks = ExternalWidgetPick | ExternalWidgetOffer | ExternalWidgetAdvert | ExternalWidgetPromo | ExternalWidgetInform;
export interface ExternalWidgetList {
    id: number;
    blocks: ExternalWidgetShort[];
    active: boolean;
    theme: LAYOUT_THEMES;
}


