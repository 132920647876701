import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';
import { NewsCategoriesDTO, NewsDTO, NewsGetRequestQuery, NewsList } from '~/models/News';

export default class NewsService {
    /**
     * Получить новости
     * @param queryParams - квери-параметры запроса
     * @param config Настройки запроса
     */
    static fetchNews(queryParams?: NewsGetRequestQuery, config?: RequestConfig): Promise<NewsList> {
        return api.get('/api/informs', {
            ...config,
            params: { ...config?.params, ...queryParams }
        });
    }

    /**
     * Получить новость по ID
     * @param newsId ID новости
     * @param config Настройки запроса
     */
    static fetchNewsDetail<R = NewsDTO>(newsId: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/informs/${ newsId }`, config));
    }

    /**
     * Получить категории новостей
     * @param config Настройки запроса
     */
    static fetchNewsCategories(config?: RequestConfig): Promise<Array<NewsCategoriesDTO>> {
        return api.get('/api/informCategory', {
            ...config
        });
    }
}
