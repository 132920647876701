//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import swiperMixin from '~/mixins/swiperMixin';
import BaseIcon from '~/components/BaseIcon';
import getImage from '~/helpers/getImage';
import AdBannerController from '@/components/AdBannerController';

const SIZES = {
    1: 'large',
    2: 'medium'
};

export default {
    components: { AdBannerController, BaseIcon },
    mixins: [swiperMixin],
    props: {
        banners: { type: Array, required: true }
    },
    computed: {
        bannersWithImage() {
            return this.banners?.filter(item => item.image);
        },
        swiperOptions() {
            return Object.assign(
                {},
                this.swiperOptionsBase,
                {
                    slidesPerView: 'auto',
                    spaceBetween: 8,
                    breakpoints: {
                        750: {
                            slidesPerView: 'auto',
                            spaceBetween: 8
                        },
                        0: {
                            slidesPerView: 'auto',
                            spaceBetween: 4
                        }
                    },
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    }
                }
            );
        }
    },
    methods: {
        optimizedImageUrl(imageUrl) {
            return getImage(imageUrl, 'rs:fit:776:410');
        },
        getIsLargeBanner(banner) {
            return banner && SIZES[banner.size] === 'large';
        }
    }
};
