//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Typograph from '@/components/Typograph';
import getImage from '@/helpers/getImage';
import BaseImage from '@/components/BaseImage';

const VIEWS = {
    horizontal: 'horizontal',
    vertical: 'vertical'
};
export { VIEWS };

export default {
    components: { BaseImage, Typograph },
    props: {
        /**
         * {banner.url} {String}
         * {banner.image} {String}
         * {banner?.title} {String}
         * {banner?.logo} {String}
         */
        banner: { type: Object },
        view: {
            default: VIEWS.horizontal,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        }
    },
    computed: {
        bannerStyles() {
            return { backgroundImage: `url(${ getImage(this.banner.image, 'rs:fit:776:410') })` };
        },
        isOnlyImage() {
            return !this.banner.title;
        },
        isModalExists() {
            return !!(this.banner.modalText && this.banner.modalTitle);
        }
    },
    methods: {
        getImage
    }
};
