//
//
//
//
//
//

import PartnersSwiper from '~/components/swiper/PartnersSwiper';

export default {
    components: {
        PartnersSwiper
    },
    storageItems: ['partners']
};
