import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';
import {
    CreditOffers,
    CreditOffersFilters,
    CreditOffersGetRequestQuery,
    CreditProductTypeDTO,
    CreditProductTypesGetRequestQuery
} from '~/models/Credits';
import UserService from '~/services/User';
import { getNumberFromQuery, getStringFromQuery } from '~/helpers/normalizeQueryParams';
import ListService from '~/services/List';
import { ITag } from '~/models/List';


interface FilterDataNormalized {
    creditTypes: CreditProductTypeDTO[];
    filterValues: Record<string, unknown>;
    filters: CreditOffersFilters|null;
    tags: ITag[];
}

export default class CreditService {
    /**
     * Получить кредитные предложения
     * @param queryParams?? - квери-параметры запроса
     * @param config? Настройки запроса
     */
    static fetchCreditOffers(queryParams?: CreditOffersGetRequestQuery, config?: RequestConfig): Promise<CreditOffers> {
        return api.get('/api/creditProduct', {
            ...config,
            params: { ...config?.params, ...queryParams }
        });
    }

    static apiFetchCreditOffers<R = CreditOffers>(queryParams?: CreditOffersGetRequestQuery, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get('/api/creditProduct', {
            ...config,
            params: { ...config?.params, ...queryParams }
        }));
    }

    /**
     * Получить список типов кредитных продуктов
     * @param queryParams - квери-параметры запроса
     * @param config Настройки запроса
     */
    static fetchCreditProductTypes(queryParams?: CreditProductTypesGetRequestQuery, config?: RequestConfig): Promise<Array<CreditProductTypeDTO>> {
        return api.get('/api/creditProductTypes', {
            ...config,
            params: { ...config?.params, ...queryParams }
        });
    }

    /**
     * Получить список типов кредитных продуктов
     * @param config Настройки запроса
     */
    static apiFetchCreditProductTypes(config?: RequestConfig): FetchResult<CreditProductTypeDTO[]> {
        return apiFetch(() => api.get('/api/creditProductTypes', config));
    }

    /**
     * Получить список фильтров для кредитных предложений
     * @param creditProductTypeCode - path-параметр запроса (code выбранного типа кредитного продукта)
     * @param [config] Настройки запроса
     */
    static fetchCreditOffersFilters(creditProductTypeCode: number, config?: RequestConfig): Promise<CreditOffersFilters> {
        return api.get(`/api/filters/${ creditProductTypeCode }`, config);
    }

    /**
     * Получить список фильтров для кредитных предложений
     * @param creditProductTypeCode - path-параметр запроса (code выбранного типа кредитного продукта)
     * @param [config] Настройки запроса
     */
    static apiFetchCreditOffersFilters<R = CreditOffersFilters>(creditProductTypeCode: number, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/filters/${ creditProductTypeCode }`, config));
    }

    static checkCreditType(creditTypeId: number|null, creditTypes: CreditProductTypeDTO[]|null): null|number {
        if (!creditTypes || !Array.isArray(creditTypes)) {
            return null;
        }

        const foundItem = creditTypes.find(item => String(item.id) === String(creditTypeId));
        if (foundItem && Number(foundItem.id)) {
            return Number(creditTypeId);
        } else {
            return creditTypes[0].id || null;
        }
    }

    /**
     * Заполняет фильтр из query
     * @param filterData - фильтр с полями
     * @param query - параметры адресной строки
     */
    static normalizeFilter(filterData: CreditOffersFilters, query: Record<string, string>): Record<string, unknown> {
        const data: Record<string, unknown> = {};

        if (!(filterData && Array.isArray(filterData.fields))) {
            return data;
        }

        filterData.fields.forEach((item) => {
            if (item.paramName === 'creditType') {
                return;
            }

            const paramName = item.paramName;
            const queryValue = query[paramName];

            data[paramName] = null;

            if (Number(item.valueTo)) {
                if (queryValue && Number(queryValue) <= Number(item.valueTo) && Number(queryValue) >= Number(item.valueFrom)) {
                    data[paramName] = Number(queryValue);
                } else {
                    data[paramName] = item.valueTo ? Math.ceil(Number(item.valueTo) / 3) : Number(item.valueTo);
                }
            }

            if (Array.isArray(item.options) && item.options.length) {
                const foundItem = item.options.find(item => String(item.id) === String(queryValue));
                if (foundItem && Number(foundItem.id)) {
                    data[paramName] = Number(foundItem.id);
                }
            }
        });

        return data;
    }

    /**
     * Загружает, нормализует и возвращает все данные фильтра
     * @param query - параметры адресной строки
     * @param withTags - сделать запрос на получение тегов
     * @param withInnerFilters - применять внутренние фильтры
     */
    static async getNormalizedFilter(query: Record<string, string>, withTags: boolean = false, withInnerFilters: boolean = true): Promise<FilterDataNormalized> {
        const userType = UserService.getUserType(getStringFromQuery(query.userType));
        const search = getStringFromQuery(query.search);
        const creditType = getNumberFromQuery(query.creditType) || null;
        const city = getNumberFromQuery(query.city) || null;

        const data: FilterDataNormalized = {
            creditTypes: [],
            filterValues: {
                userType,
                creditType,
                city,
                search
            },
            filters: null,
            tags: []
        };


        const [creditTypes] = await CreditService.apiFetchCreditProductTypes({
            params: {
                userType,
                city
            },
            progress: false
        });

        data.filterValues.creditType = CreditService.checkCreditType(creditType, creditTypes);

        data.creditTypes = creditTypes || [];

        if (data.filterValues.creditType) {
            const [filters] = await CreditService.apiFetchCreditOffersFilters(data.filterValues.creditType as number, {
                params: {
                    userType,
                    cityId: city
                },
                progress: false
            });
            if (filters) {
                data.filters = filters;
                data.filterValues = {
                    ...(data.filterValues || {}),
                    ...(withInnerFilters ? (CreditService.normalizeFilter(filters, query) || {}) : {})
                };
            }
        }

        if (withTags) {
            const [tags] = await ListService.apiFetchCreditProductsTagsList({
                params: data.filterValues,
                progress: false
            });
            if (tags) {
                data.tags = tags;
            }
        }

        return data;
    }
}
