//
//
//
//
//
//
//
//

import Typograph from '~/components/Typograph';

export default {
    components: {
        Typograph
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    computed: {
        formattedData() {
            return this.block.data?.replace(/<h4/gi, '<div class="title-h4" ')
                .replace(/<h5/gi, '<div class="title-h5" ')
                .replace(/<h6/gi, '<div class="title-h6" ')
                .replace(/<\/h[4-6]>/gi, '</div>');
        }
    }
};
