//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce, uniqBy } from 'lodash';
import CreditOfferHorizontal from '@/components/Offer/CreditOfferHorizontal';
import BasePagination from '@/components/BasePagination';
import BaseButton from '@/components/BaseButton';
import CardRemovable from '@/components/Offer/markup/CardRemovable';
import hasCursorMixin from '@/mixins/hasCursorMixin';
import documentSizeMixin from '@/mixins/documentSizeMixin';
import processError from '@/helpers/processError';
import pluralize from '@/helpers/pluralize';
import { userStore } from '~/store';

import CreditService from '@/services/Credit';
import { EMPTY_PAGINATED_LIST } from '@/models/enums';
import BannerService from '@/services/Banner';
import { BANNER_PLACES } from '@/constants/banners';
import AdBannerController from '@/components/AdBannerController';
import AdBanner from '@/components/AdBanner';
import ContentText from '~/components/Content/ContentText.vue';

const OFFERS_COLUMNS_TITLES = [
    {
        id: 0,
        title: ''
    },
    {
        id: 1,
        title: 'Предложение'
    },
    {
        id: 2,
        title: 'Ставка'
    },
    {
        id: 3,
        title: 'Время одобрения'
    },
    {
        id: 4,
        title: 'Способы оформления'
    }
];

export default {
    components: { ContentText, AdBanner, AdBannerController, CreditOfferHorizontal, BasePagination, BaseButton, CardRemovable },
    mixins: [hasCursorMixin, documentSizeMixin],
    props: {
        creditTypeMultipleApplicationDisabled: { type: Boolean, required: false, default: false },
        useParentWidth: { type: Boolean, required: false, default: false },
        offersLimit: { type: Number, default: 3, required: false }, // лимит предложений
        filterValues: { type: Object, default: () => ({}) }, // значения фильтров для получения предложений
        appendOffers: { type: Boolean, default: false, required: false }, // нужно ли добавлять новые предложения к имеющимся (пагинация - Показать еще)
        activeIds: { type: Array }, // выбранные значения (параметры с урла)
        creditTypeId: { type: Number }, // выбранный тип кредита
        noFixed: { type: Boolean, default: false },
        theme: { type: String, required: false, default: 'outline_light_gray' },
        applicationRouteQuery: { type: Object, default: () => ({}) },
        reviewExternalAdd: { type: Boolean, default: false },
        disableResizer: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: true
        },
        showHelpers: {
            type: Boolean,
            default: true
        },
        useCopyright: {
            type: Boolean,
            default: false
        },
        refMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isOffersPending: false,
            isOffersPendingMore: false,
            isGetOffersError: false,
            offersCancelTokenSource: null,
            offers: {
                ...EMPTY_PAGINATED_LIST
            },
            chosenOffersIds: [], // id выбранных предложений
            chosenOffers: [],  // выбранные предложения
            isOfferCardCheckboxVisible: false,  // показать значок чекбокса на предложениях
            offersColumns: OFFERS_COLUMNS_TITLES, // названия колонок
            isFirstFetchDone: false,
            updateIds: false, // обновить выбранные id из урла
            startBanners: {
                ...EMPTY_PAGINATED_LIST
            },
            middleBanners: {
                ...EMPTY_PAGINATED_LIST
            }
        };
    },
    async fetch() {
        if (this.isFirstFetchDone) {
            return Promise.resolve();
        }

        return Promise.all([
            this.fetchOffers(),
            this.fetchBanners()
        ]).then(() => {
            this.isFirstFetchDone = true;
        });
    },
    computed: {
        isGlobalTheme() {
            return this.theme === 'global';
        },
        isTablet() {
            let width = this.windowSize.width;
            let heightPred = this.windowSize.height <= 800;

            if (this.useParentWidth) {
                width = this.$parent.$el.clientWidth;
                heightPred = true;
            }

            return width <= 1000 || heightPred;
        },
        // компонент, в котором отображаются выбранные предложения
        // так как на экране без курсора замечен баг, что из-за fixed position (у sticky эл-та) transition применяется некорректно
        chosenOffersComponentName() {
            if (this.isDeviceHasCursor) {
                return 'Transition';
            }

            return 'div';
        },
        // аттрибуты компонента, в котором отображаются выбранные предложения
        chosenOffersComponentAttrs() {
            const attrs = {};

            if (this.chosenOffersComponentName === 'Transition') {
                attrs['name'] = 'fade-and-grow';
            }

            return attrs;
        },
        // кол-во предложений
        offersItemsQuantity() {
            return this.offers.pagination.total;
        },
        // кол-во выбранных предложений
        chosenOffersQuantity() {
            return (Array.isArray(this.chosenOffersIds)) ? this.chosenOffersIds.length : 0;
        },
        // название кнопки Заполнить анкету (для выбранных предложений)
        fillInApplicationButtonTitle() {
            const chosenOffersTitle =  pluralize('продукта|продуктов|продуктов', this.chosenOffersQuantity);

            return `Заполнить анкету для ${ this.chosenOffersQuantity } ${ chosenOffersTitle }`;
        },
        offersQuantityTitle() {
            if (this.isGetOffersError) {
                return 'Ошибка при загрузке данных ☹';
            }

            if ((this.isOffersPending || this.isOffersPendingMore || this.$fetchState.pending) && !this.offersItemsQuantity) {
                return 'Загружаю предложения ☺️';
            } else if (!this.offersItemsQuantity && !this.isOffersPending && !this.isOffersPendingMore && !this.$fetchState.pending) {
                return  'Упс… Предложения не найдены ☹';
            }

            const chosenOffersTitle =  pluralize('предложение|предложения|предложений', this.offersItemsQuantity);

            return `Найдено: ${ this.offersItemsQuantity } ${ chosenOffersTitle }`;
        },
        canShowOffers() {
            return this.offers && this.offers.items && this.offersItemsQuantity && !this.isGetOffersError;
        },
        isGetOffersPending() {
            return this.isOffersPending || this.isOffersPendingMore;
        },
        bannersFilter() {
            return {
                page: this.filterValues.page || 1,
                cityId: this.filterValues.city,
                userType: this.filterValues.userType,
                borrowerTypeId: this.filterValues.borrowerType,
                creditProductTypeIds: this.filterValues.creditType ? [this.filterValues.creditType] : undefined,
                sumTo: this.filterValues.amount,
                limit: 1
            };
        },
        itemsList() {
            const limit = this.offersLimit;
            const currentPage = this.filterValues.page || 1;
            const starts = [0];
            const middles = [];
            for (let page = 1; page <= currentPage; page++) {
                const len = limit * page;
                starts.push(len + 1);
                middles.push(len - limit + Math.ceil(limit / 2));
            }
            const items = [];
            let currentStartBannerIndex = 0;
            let currentMiddleBannerIndex = 0;

            (Array.isArray(this.offers.items) ? this.offers.items : []).forEach((item, itemIndex) => {
                if ((starts.includes(itemIndex))) {
                    const startBanner = this.startBanners.items[currentStartBannerIndex];
                    if (startBanner) {
                        items.push({
                            ...startBanner,
                            logo: startBanner.logo,
                            isBanner: true
                        });
                        currentStartBannerIndex++;
                    }
                }
                if ((middles.includes(itemIndex))) {
                    const middleBanner = this.middleBanners.items[currentMiddleBannerIndex];
                    if (middleBanner) {
                        items.push({
                            ...middleBanner,
                            logo: middleBanner.logo,
                            isBanner: true
                        });
                        currentMiddleBannerIndex++;
                    }
                }

                items.push(item);
            });

            return items;
        },
        applicationRoute() {
            const path = {
                name: 'application',
                query: {
                    offers: this.chosenOffersIds,
                    creditType: this.creditTypeId,
                    amount: this.filterValues?.amount,
                    duration: this.filterValues?.duration,
                    userType: this.filterValues.userType,
                    search: this.filterValues?.search,
                    ...this.applicationRouteQuery
                }
            };

            if (this.filterValues?.borrowerType) {
                path.query.borrowerType = this.filterValues?.borrowerType;
            }

            return path;
        },
        userType: {
            get() { return userStore.userType; }
        }
    },
    watch: {
        filterValues: {
            deep: true,
            handler: debounce(function(value, oldValue) {
                if (Array.isArray(value.tags) && value.tags.length === 0 && !oldValue.tags) {
                    return;
                }
                const isReloadChosenOffers = oldValue?.page && value.page === 1;
                if (isReloadChosenOffers) { this.clearChosenOffers(); }

                this.fetchOffers().then(() => {
                    this.$nextTick(() => { // Иначе при загрузке страницы стики блок прилипает внизу
                        window.scrollTo(window.scrollX, window.scrollY - 1);
                        window.scrollTo(window.scrollX, window.scrollY + 1);
                    });
                });

                if (value.page !== oldValue.page) {
                    this.fetchBanners(true);
                } else {
                    this.fetchBanners();
                }
            }, 300)
        },
        chosenOffersIds(value) {
            if (!value) { return; }

            const currentOffersWithValue = this.offers.items.filter(offer => value.includes(offer.id));  // ищем среди текущих отфильтрованных предложений
            this.chosenOffers = uniqBy([...this.chosenOffers, ...currentOffersWithValue], 'id'); // добавляем, оставляем уникальные
            this.chosenOffers = this.chosenOffers.filter(offer => value.includes(offer.id)); // если удалили предложение из выбора, фильтруем и здесь

            // @todo пока что убрано сохранение выбранных предложений
            //this.$emit('choose-offers', value);
        },
        isGetOffersPending(value) {
            this.$emit('offers-pending', value);
        }
    },
    mounted() {
        this.defineIfDeviceHasCursor();
    },
    methods: {
        async fetchOffers() { // Получить список предложений
            if (this.appendOffers) {
                this.isOffersPendingMore = true;
            } else {
                this.isOffersPending = true;
            }

            this.isGetOffersError = false;

            if (this.offersCancelTokenSource) {
                this.offersCancelTokenSource.cancel();
            }
            this.offersCancelTokenSource = this.$axios.CancelToken.source();

            const [offers, error] = await CreditService.apiFetchCreditOffers({
                ...(this.filterValues || {}),
                limit: this.offersLimit
            },
            {
                cancelToken: this.offersCancelTokenSource.token,
                progress: false
            }).finally(() => {
                this.offersCancelTokenSource = null;
            });

            if (error) {
                this.isGetOffersError = error && !this.$axios.isCancel(error);
                processError(error);
            }

            if (offers) {
                this.offers.items = this.appendOffers ? [...this.offers.items, ...offers.items] : offers.items;
                this.offers.pagination = offers.pagination;

                this.$emit('fetch-offers', this.offers);

                // обновление выбранных предложений (из урла)
                // @todo пока что убрано сохранение выбранных предложений
                /*if (this.activeIds && Array.isArray(this.activeIds) && this.activeIds.length && !this.updateIds) {
                    this.updateIds = true;
                    const validIds = this.activeIds.map(id => Number(id) || 0).filter(id => this.offers.items.find(offer => offer.id === id));
                    if (validIds.length) {
                        // console.log('update ids', validIds);
                        this.chosenOffersIds = validIds;
                    }
                }*/
            }

            this.isOffersPending = false;
            this.isOffersPendingMore = false;
        },
        async onPageChange(payload) {
            if (!payload) return;

            this.$emit('page-change', payload);
        },
        // показать значок чекбокса на карточках предложений
        showCheckboxOnOfferCard() {
            if (!this.creditTypeMultipleApplicationDisabled) {
                this.isOfferCardCheckboxVisible = true;
            }
        },
        // очистить выбранные предложения, спрятать значок чекбокса
        clearChosenOffers() {
            this.chosenOffersIds = [];
            this.isOfferCardCheckboxVisible = false;
        },
        onRemoveChosenOffer(offerId) {
            if (!offerId) { return; }

            this.chosenOffersIds = this.chosenOffersIds.filter(id => id !== offerId);
        },
        async fetchBanners(append = false) {
            try {
                const startBanners = await  BannerService.fetchBanners(
                    {
                        ...this.bannersFilter,
                        page: this.startBanners?.pagination?.pages >= this.bannersFilter.page ? this.bannersFilter.page : 1,
                        place: BANNER_PLACES.LIST_START
                    },
                    {
                        progress: false
                    }
                );
                const middleBanners = await  BannerService.fetchBanners(
                    {
                        ...this.bannersFilter,
                        page: this.middleBanners?.pagination?.pages >= this.bannersFilter.page ? this.bannersFilter.page : 1,
                        place: BANNER_PLACES.LIST_MIDDLE
                    },
                    {
                        progress: false
                    }
                );
                if (startBanners) {
                    this.startBanners.items = append ? [...(this.startBanners.items || []), ...startBanners.items] : startBanners.items;
                    this.startBanners.pagination = startBanners.pagination;
                }
                if (middleBanners) {
                    this.middleBanners.items = append ? [...(this.middleBanners.items || []), ...middleBanners.items] : middleBanners.items;
                    this.middleBanners.pagination = middleBanners.pagination;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
};
