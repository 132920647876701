//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseIcon from '@/components/BaseIcon';
import ToggleGroup from '@/components/form/ToggleGroup';
import BaseButton from '@/components/BaseButton';
import SearchSuggestionField from '@/components/SearchSuggestionField';

export default {
    components: { SearchSuggestionField, BaseIcon, ToggleGroup, BaseButton },
    props: {
        // способы сортировки
        sortingTypes: { type: Array, required: true },
        // выбранный способ сортировки
        value: { type: [String, Number, Array], default: null },
        // нужен ли поиск
        withSearch: { type: Boolean, default: true, required: false },
        // нужен ли фильтр
        withFilter: { type: Boolean, default: true, required: false },
        // тема для ToggleGroup
        toggleGroupTheme: { type: String, default: 'white', required: false },
        // тема для input
        inputTheme: { type: String, default: 'dark_secondary', required: false },
        // тема для BaseToggle
        toggleTheme: { type: String, default: 'blue', required: false },
        // Плавающий фильтр
        float: { type: Boolean, default: true }
    },
    data() {
        return {
            // поиск предложений
            searchOffers: {},
            // показать список способов сортировки на адаптиве
            isSortingOptionsShown: false
        };
    },
    computed: {
        modelInputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        sortButtonTheme() {
            return this.isSortingOptionsShown ? 'blue_outlined_always' : 'black';
        },
        sortButtonView() {
            return this.isSortingOptionsShown ? 'simple' : 'outlined';
        },
        isTypesExists() {
            return !!this.sortingTypes?.length;
        }
    },
    watch: {
        async searchOffers(val) {
            this.redirectToSearch(val?.value || val);
        }
    },
    methods: {
        async redirectToSearch(search) {
            const query = this.$route.query;

            await this.$router.push({
                path: '/search/',
                query: {
                    ...query,
                    search
                }
            });
        },
        toggleSortingOptions() {
            this.isSortingOptionsShown = !this.isSortingOptionsShown;
        }
    }
};
