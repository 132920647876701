//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '~/mixins/documentSizeMixin';
import BaseImage from '~/components/BaseImage';
import Typograph from '~/components/Typograph';
import BaseIcon from '~/components/BaseIcon';
import BaseTooltipTag from '~/components/BaseTooltipTag';
import CheckItem from '~/components/CheckItem';
import makeClasses from '~/helpers/makeClasses';

import getImage from '~/helpers/getImage';

const THEMES = {
    white: 'white',
    outline_light_gray: 'outline_light_gray',
    global: 'global'
};
const VIEWS = {
    vertical: 'vertical',
    horizontal: 'horizontal'
};
const TAG_THEMES = {
    white: 'white',
    blue: 'blue'
};
export { THEMES, VIEWS, TAG_THEMES };

const useClasses = makeClasses(() => ({
    root: ({ view, theme }) => ({
        'relative p-4 rounded-base group transition-fast hover:z-[5]': true,
        'max-w-[179px] lg:flex lg:flex-col': view === VIEWS.vertical,
        'w-full flex': view === VIEWS.horizontal,
        'bg-white border border-white mouse-device:hover:rounded-b-none will-change-transform mouse-device:hover:drop-shadow-light-blue': theme === THEMES.white,
        'border border-gray-700 border-opacity-[0.12] will-change-transform hover:bg-white hover:border-gray-700 hover:border-opacity-[0.12] hover:border-b-white a-lg:hover:rounded-b-none lg:hover:border-gray-700 lg:hover:border-opacity-[0.12] mouse-device:hover:filter mouse-device:hover:drop-shadow-light-black lg:bg-white': theme === THEMES.outline_light_gray,
        'bg-accent bg-opacity-[var(--theme-accent-opacity)]': theme === THEMES.global,
        'hover:bg-white border border-transparent hover:border-white will-change-transform mouse-device:hover:bg-blue-50 mouse-device:hover:border-blue-250 mouse-device:hover:border-opacity-[0.12] mouse-device:hover:filter mouse-device:hover:drop-shadow-light-blue hover:rounded-b-none': theme === THEMES.global
    }),
    popularTag: ({ view }) => ({
        hidden: view === VIEWS.vertical,
        'absolute -top-1.5 left-[8.5rem] bg-gray-650 text-white text-tiny rounded-sm py-px px-1 lg:left-24 sm:left-18': view === VIEWS.horizontal
    }),
    imageContainer: ({ view }) => ({
        'relative flex items-center justify-center h-16 w-16 rounded-md bg-gray-50 filter drop-shadow-lg transition-fast -translate-y-7 group-hover:-translate-y-10 lg:-translate-y-10': view === VIEWS.vertical,
        'flex-shrink-0 mr-6 lg:mr-4': view === VIEWS.horizontal
    }),
    image: ({ view }) => ({
        'max-w-[64px] max-h-[64px]': view === VIEWS.vertical,
        'filter drop-shadow-lg w-24 h-24 lg:w-16 lg:h-16 sm:w-10 sm:h-10': view === VIEWS.horizontal
    }),
    tag: ({ view, isChosen, isCheckboxVisible }) => ({
        absolute: true,
        'opacity-0 pointer-events-none transition-fast': isChosen || isCheckboxVisible,
        'top-3 right-3': view === VIEWS.vertical,
        'top-2 right-2 flex -mx-1 sm:top-4 sm:right-4': view === VIEWS.horizontal
    }),
    checkboxIcon: ({ view }) => ({
        absolute: true,
        'top-4 right-3.5': view === VIEWS.vertical,
        'top-3 right-3 sm:top-4 sm:right-4': view === VIEWS.horizontal
    }),
    checkboxInput: () => 'absolute inset-0 opacity-0 pointer-events-none',
    content: ({ view }) => ({
        'lg:flex-col lg:flex-auto max-w-full': true,
        'lg:flex': view === VIEWS.vertical,
        flex: view === VIEWS.horizontal
    }),
    bankTitle: ({ view, theme }) => ({
        'text-xs break-word font-semibold': true,
        'text-gray-650': theme !== THEMES.global,
        'text-primary opacity-50 group-hover:text-gray-650': theme === THEMES.global,
        'mb-0.5': view === VIEWS.vertical,
        'mr-2 sm:mr-0 sm:mb-1': view === VIEWS.horizontal
    }),
    reviewsButton: ({ view, disableReview }) => ({
        'transition-fast relative': true,
        'hover:opacity-60': !disableReview,
        'pointer-events-none': disableReview,
        'mb-3': view === VIEWS.vertical
    }),
    reviewsContainer: () => 'flex items-center -mx-0.5 whitespace-nowrap',
    star: () => ({
        'mx-0.5 fill-current': true
    }),
    reviewText: ({ theme }) => ({
        'mx-0.5 text-xxs': true,
        'text-gray-650': theme !== THEMES.global,
        'text-primary opacity-50 group-hover:text-gray-650': theme === THEMES.global
    }),
    title: ({ view, theme }) => ({
        'break-word': true,
        'text-black': theme !== THEMES.global,
        'text-primary group-hover:text-black': theme === THEMES.global,
        'mb-4 font-bold pr-3': view === VIEWS.vertical,
        'text-lg': view === VIEWS.horizontal
    }),
    infoItem: ({ view }) => ({
        'break-word lowercase': true,
        'grid grid-cols-2 items-center gap-x-0.5 mb-2 last:mb-0': view === VIEWS.vertical,
        'col-span-1': view === VIEWS.horizontal
    }),
    infoItemValue: ({ view, theme }) => ({
        'break-word': true,
        'font-normal': true,
        'text-black': theme !== THEMES.global,
        'text-primary group-hover:text-black': theme === THEMES.global,
        'col-span-1 text-left': view === VIEWS.vertical,
        'lg:order-2': view === VIEWS.horizontal
    }),
    infoItemTitle: ({ view, theme }) => ({
        'col-span-1 text-tiny text-right': view === VIEWS.vertical,
        'text-gray-650': theme !== THEMES.global,
        'text-primary opacity-50 group-hover:text-gray-650': theme === THEMES.global,
        'a-lg:hidden lg:order-1 text-xs text-gray-650 mb-0.5': view === VIEWS.horizontal
    }),
    extraInfoContainer: ({ theme, view }) => ({
        'pt-0 rounded-b-base transition-fast absolute group-hover:pointer-events-auto group-hover:opacity-100 a-lg:opacity-0 a-lg:pointer-events-none lg:relative': true,
        'bg-white': theme === THEMES.white || theme === THEMES.global,
        'border border-t-0 border-gray-700 border-opacity-[0.12] bg-white': theme === THEMES.outline_light_gray,
        'p-4 -right-px -left-px lg:p-0': view === VIEWS.vertical,
        'pb-4 -right-px -left-px top-full flex justify-between lg:p-0': view === VIEWS.horizontal
        // 'bg-accent bg-opacity-[var(--theme-accent-opacity)]': theme === THEMES.global
    })
}));

const MAX_RATING = 5;
const MAX_INFO_LENGTH = 2;

export default {
    components: { BaseIcon, Typograph, BaseImage, CheckItem, BaseTooltipTag },
    mixins: [documentSizeMixin],
    props: {
        creditTypeMultipleApplicationDisabled: { type: Boolean, required: false, default: false },
        offer: { type: Object, required: true },
        view: {
            default: VIEWS.vertical,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        theme: {
            default: THEMES.white,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        tagTheme: {
            default: TAG_THEMES.white,
            validator(value) {
                return Object.keys(TAG_THEMES).includes(value);
            }
        },
        value: { type: [Boolean, String, Number, Array] }, // значение чекбокса
        isCheckbox: { type: Boolean, default: true, required: false }, // является ли карточка чекбоксом
        isCheckboxVisible: { type: Boolean, default: false, required: false },  // показать значок чекбокса (спрячутся теги, если есть)
        disableReview: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        maxBankRating: MAX_RATING,
        maxInfoLength: MAX_INFO_LENGTH
    }),
    computed: {
        classes() {
            return useClasses({
                view: this.view,
                theme: this.theme,
                activeRating: this.offer.rating,
                isChosen: this.isChosen,
                isCheckboxVisible: this.isCheckboxVisible,
                disableReview: this.disableReview
            });
        },
        bank() {
            return this.offer?.vendor || {};
        },
        bankRating() {
            return (this.bank?.rating && this.bank.rating.toFixed(1)) || 0;
        },
        normalizedTags() {
            return (Array.isArray(this.offer?.tags) ? this.offer.tags : []).filter(tag => tag.darkImage?.file?.url).map(tag => ({
                id: tag.id,
                title: tag.title,
                imageUrlLight: tag.lightImage?.file?.url,
                imageUrlDark: tag.darkImage?.file?.url
            }));
        },
        bankImage() {
            const url = this.bank?.image?.url || null;

            return getImage(url, 'rs:fit:308:308');
        },
        offerInfo() {
            const prefixRate = this.offer?.rateFrom ? 'от ' : '';
            const prefixRateTo = this.offer?.rateTo ? 'до ' : '';
            const prefixTime = this.offer?.reviewTimeFrom ? 'от ' : '';

            const rateFromExists = Boolean(this.offer?.rateFrom);
            const rateToExists = Boolean(this.offer?.rateTo);

            let rateFrom;
            let rateTo;
            if (rateFromExists) {
                rateFrom = parseFloat(this.offer?.rateFrom?.replace(/[^\d.]*/g, ''));
                if (rateToExists) {
                    rateTo = parseFloat(this.offer?.rateTo?.replace(/[^\d.]*/g, ''));
                }
            } else {
                if (rateToExists) {
                    rateFrom = parseFloat(this.offer?.rateTo?.replace(/[^\d.]*/g, ''));
                    rateTo = parseFloat(this.offer?.rateTo?.replace(/[^\d.]*/g, ''));
                }
            }

            return [
                {
                    title: 'Ставка',
                    code: 'rate',
                    value0: `${ prefixRate }${ rateFrom }%`,
                    value1: `${ prefixRateTo }${ rateTo }%`,
                    alwaysVisible: true
                },
                {
                    title: 'Время одобрения',
                    code: 'time',
                    value: `${ prefixTime }${ this.formatTime(this.offer?.reviewTimeFrom || this.offer?.reviewTimeTo) }`,
                    alwaysVisible: true
                },
                {
                    title: 'Способы оформления',
                    code: 'methods',
                    value: this.bank?.registrationMethods?.length ? this.bank.registrationMethods.map(item => item.title).join(', ') : '—',
                    alwaysVisible: true
                },
                {
                    title: 'Вероятность одобрения',
                    code: 'approvalRate',
                    value: this.offer?.probabilityApproval ? `${ this.offer.probabilityApproval }%` : '—',
                    alwaysVisible: false
                }
            ];
        },
        extraOfferInfo() {
            return this.offerInfo.filter((infoItem, index) => index > this.maxInfoLength);
        },
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.creditTypeMultipleApplicationDisabled) {
                    return;
                }

                this.$emit('input', value);
            }
        },
        // выбрали ли данную карточку
        isChosen() {
            if (this.creditTypeMultipleApplicationDisabled) {
                return false;
            }

            if (Array.isArray(this.inputValue)) {
                return !!this.inputValue.find(it => it === this.offer.id);
            }

            return false;
        },
        isMobile() {
            return this.windowSize.width <= 600;
        }
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            this.maxInfoLength = MAX_INFO_LENGTH;

            if (this.windowSize.width < 1000 && this.view === 'vertical') {
                this.maxInfoLength = 3;
            }

            if (this.windowSize.width < 500 && this.view === 'horizontal') {
                this.maxInfoLength = 1;
            }
        },
        formatTime(timeInMinutes) {
            const minutes = timeInMinutes % 60;
            const hours = Math.floor(timeInMinutes / 60);

            const hasMinutes = minutes > 0;
            const hasHours = hours > 0;

            return `${ hasHours ? `${ hours } ч ` : '' }${ hasMinutes ? `${ minutes } мин` : '' }`;
        },
        onRatingClick() {
            const vendorId = this.offer?.vendor?.id;
            if (!vendorId || this.disableReview) {
                return;
            }
            this.$layer.open('ReviewsLayer', {
                vendorId
            });
        }
    }
};
