import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { generateUuid } from '@/plugins/uuid';
import 'swiper/css/swiper.css';

export default {
    components: { Swiper, SwiperSlide },
    props: {
        id: { type: String, default: () => generateUuid('swiper-slider-') },
        autoplay: { type: [Number, Object], default: 0 },
        perSlide: { type: Number, default: 3 },
        perSlideBreakpoints: { type: Object },
        useNavigation: { type: Boolean, default: false },
        usePagination: { type: Boolean, default: false }
    },
    computed: {
        swiperOptionsBase() {
            const autoplayDelay = this.autoplay && typeof this.autoplay.delay !== 'undefined' ? this.autoplay.delay : 6000;

            const options = {
                loop: false,
                autoplay: this.autoplay
                    ? { delay: autoplayDelay, disableOnInteraction: false }
                    : false,
                spaceBetween: 12,
                on: {
                    init: () => {
                        this.$nextTick(() => {
                            this.removeInvisible();
                        });
                    },
                    update: () => {
                        this.$nextTick(() => {
                            this.removeInvisible();
                        });
                    },
                    afterInit: () => {
                        this.$nextTick(() => {
                            this.removeInvisible();
                        });
                    }
                }
            };

            const breakpoints = this.perSlideBreakpoints;

            if (breakpoints) {
                Object.assign(options, {
                    breakpoints: Object.keys(breakpoints).reduce((result, breakWidth) => {
                        const width = +breakWidth;
                        const amount = breakpoints[width];

                        result[width] = {
                            slidesPerView: amount
                        };

                        return result;
                    }, {})
                });
            } else {
                Object.assign(options, {
                    slidesPerView: this.perSlide
                });
            }

            if (this.useNavigation) {
                Object.assign(options, {
                    navigation: {
                        nextEl: `#${ this.id }-next`,
                        prevEl: `#${ this.id }-prev`,
                        disabledClass: 'is-navigation-disabled',
                        hiddenClass: 'is-navigation-hidden'
                    }
                });
            }

            if (this.usePagination) {
                Object.assign(options, {
                    pagination: {
                        el: `#${ this.id }-pagination`,
                        clickable: true
                    }
                });
            }

            return options;
        },
        swiperInstance() {
            return this.$refs.swiper && this.$refs.swiper.swiperInstance;
        }
    },
    methods: {
        removeInvisible() {
            this.swiperInstance && this.swiperInstance.el && this.swiperInstance.el.classList.remove('invisible');
        }
    }
};
