//
//
//
//
//
//
//
//
//
//
//
//
//
//

import makeClasses from '~/helpers/makeClasses';
import BaseImage from '~/components/BaseImage';
import UserIcon from '~/assets/sprite/svg/user.svg?inline';

const SIZES = {
    sm: 'sm',
    md: 'md'
};

// language=HTML prefix=<div\u0020class=" suffix="\\/>
const useClasses = makeClasses(
    () => ({
        root: ({ size }) => [
            'relative group inline-flex flex-col items-center justify-center rounded-full bg-gray-200 overflow-hidden',
            {
                'w-12 h-12': size === SIZES.sm,
                'w-16 h-16': size === SIZES.md
            }
        ],
        image: () => [
            'w-full h-full object-cover'
        ]
    })
);

export default {
    components: {
        BaseImage,
        UserIcon
    },
    props: {
        image: {
            type: String
        },
        size: {
            type: String,
            default: 'sm',
            validator(value) {
                return Object.keys(SIZES).includes(value);
            }
        },
        to: {
            type: [String, Object],
            required: false
        }
    },
    computed: {
        rootComponent() {
            return this.$attrs.href ? 'a' : this.to ? 'NuxtLink' : 'div';
        },
        classes() {
            return useClasses({
                size: this.size
            });
        }
    }
};
