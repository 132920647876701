
import throttle from 'lodash/throttle';

export default {
    props: {
        threshold: { type: [Number, Array], required: false, default: 0 },
        destroyBreakpoint: { type: Number }
    },
    data() {
        return {
            options: { root: null, threshold: this.threshold },
            observer: null,
            resizeHandle: throttle(this.checkBreakpoint, 500)
        };
    },
    mounted() {
        window.addEventListener('resize', this.resizeHandle, { passive: true });
        this.resizeHandle();
    },
    beforeDestroy() {
        this.destroyObserver();
        window.removeEventListener('resize', this.resizeHandle);
    },
    methods: {
        checkBreakpoint() {
            window.innerWidth < this.destroyBreakpoint ? this.destroyObserver() : this.initObserver();
        },
        /** Инициализировать обсервер */
        initObserver() {
            if (!this.observer) {
                this.observer = new IntersectionObserver((entries) => {
                    this.$emit(entries[0].isIntersecting ? 'intersect-enter' : 'intersect-leave', [entries[0]]);
                },
                this.options
                );
            }

            // Нужно подождать следующего тика для того, чтобы дочерний элемент мог отрисоваться.
            this.$nextTick(() => {
                this.$slots.default && this.$slots.default[0].elm && this.observer.observe(this.$slots.default[0].elm);
            });
        },
        /** Уничтожить обсервер */
        destroyObserver() {
            this.observer && this.observer.disconnect();
        }
    },
    render() {
        // Без компонента-обёртки, мы можем отрисовать только один дочерний компонент.
        try {
            return this.$slots.default && this.$slots.default[0];
        } catch (e) {
            throw new Error('IntersectionObserver.vue может отрисовывать один и только один дочерний компонент.');
        }
    }
};
