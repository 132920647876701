export function normalizeArrayQuery(param: string | (string | null)[]): string[] {
    let data: string[] = [];
    if (Array.isArray(param)) {
        data = param.filter(x => x !== null) as string[];
    } else {
        data = [param];
    }

    return data;
}

export function getStringFromQuery(param: string | (string | null)[]): string {
    let data = '';
    if (Array.isArray(param) && param.length) {
        data = String(param[0]);
    } else if (typeof param === 'number') {
        data = String(param);
    } else if (typeof param === 'string') {
        data = param;
    }

    return data;
}

export function getNumberFromQuery(param: string | (string | null)[]): number|undefined {
    let data;
    if (Array.isArray(param) && param.length && param[0]) {
        data = parseFloat(param[0]);
    } else if (typeof param === 'string' || typeof param === 'number') {
        data = parseFloat(param);
    }

    return data;
}
