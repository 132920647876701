//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseField, { THEMES, VIEWS, TEXT_SIZE } from '~/components/form/BaseField';
import BaseIcon from '~/components/BaseIcon';
import { useClasses } from '~/components/form/InputField';
import formatDate from '~/helpers/formatDate';

export default {
    components: {
        BaseField,
        BaseIcon
    },
    extends: BaseField,
    props: {
        isRange: Boolean,
        minDate: Date,
        disabled: Boolean,
        readonly: Boolean,
        theme: {
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        textSize: {
            default: TEXT_SIZE.base,
            validator(value) {
                return Object.keys(TEXT_SIZE).includes(value);
            }
        }
    },
    data() {
        return {
            isFocus: false,
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: new Date()
                }
            ]
        };
    },
    computed: {
        datepickerValue: {
            get() {
                return this.value;
            },
            set(val) {
                let result = '';
                if (val) {
                    result = formatDate(val, 'YYYY-MM-DD');
                    if (this.isRange) result = { end: formatDate(val.end, 'YYYY-MM-DD'), start: formatDate(val.start, 'YYYY-MM-DD') };
                }

                this.inputValue = result;
            }
        },
        classes() {
            return useClasses({
                theme: this.theme,
                view: this.view,
                textSize: this.textSize,
                disabled: this.disabled,
                readonly: this.readonly
            });
        }
    },
    async mounted() {
        this.watchValueOnce();

        const Inputmask = await require('inputmask').default;
        this._im = new Inputmask({
            jitMasking: 0,
            clearMaskOnLostFocus: false,
            showMaskOnHover: false,
            showMaskOnFocus: true,
            alias: 'datetime', inputFormat: 'dd.mm.yyyy',
            placeholder: '__.__.____',
            clearIncomplete: true
        });
        this._im.mask(this.$refs.input);
    },
    beforeDestroy() {
        if (this._im) {
            this._im.remove();
        }
    },
    methods: {
        watchValueOnce() {
            const unwatch = this.$watch('value', (val) => {
                if (val) {
                    let result = formatDate(val, 'YYYY-MM-DD');
                    if (this.isRange) result = { end: formatDate(val.end, 'YYYY-MM-DD'), start: formatDate(val.start, 'YYYY-MM-DD') };
                    this.inputValue = result;
                }
                unwatch();
            });
        },
        checkIsFocused() {
            if (document?.activeElement !== this.$refs.input) this.onBlur();
        },
        onInputFieldBlur(e) {
            if (!e.target.value) this.onBlur();
        }
    }
};
