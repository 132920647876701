import { generateUuid } from '~/plugins/uuid';

export default {
    props: {
        inputListeners: { type: Object, default: () => ({}) },
        title: { type: String },
        error: { type: [String, Boolean] },
        placeholder: { type: String },
        value: {},
        id: { type: String, default: () => generateUuid('form-field-') },
        type: { type: String },
        disabled: { type: Boolean },
        required: { type: Boolean },
        focus: { type: Boolean }
    },
    data() {
        return {
            isFocused: false
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        inputProps() {
            return {
                id: this.id,
                type: this.type,
                placeholder: this.placeholder,
                disabled: this.disabled,
                required: this.required
            };
        }
    },
    methods: {
        onBlur() {
            this.isFocused = false;
        },
        onFocus() {
            this.isFocused = true;
        },
        onChange() {
            this.$emit('change');
        }
    }
};

