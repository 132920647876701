import { ReviewList } from '~/models/Review';
import api, { apiFetch, FetchResult, RequestConfig, RequestDataNoArg } from '~/helpers/api';

export default class ReviewService {
    static getList<R = ReviewList>(config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get('/api/reviews', config));
    }

    static getFullList<R = ReviewList>(search: string | undefined, category: string | undefined, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/reviews/all/?category=${ category }` + (search ? `&search=${ search }` : ''), config));
    }

    static sendReview<R = { success: boolean }>(vendorId: number|string, data: RequestDataNoArg, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.post(`/api/reviews/${ vendorId }`, data, config));
    }
}
