//
//
//
//
//
//

import QuoteItem from '~/components/QuoteItem.vue';

export default {
    components: {
        QuoteItem
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    computed: {
        author() {
            return {
                image: typeof this.block.authorImage === 'object' && this.block.authorImage !== null ? this.block.authorImage.file.url : this.block.authorImage,
                name: this.block.authorName,
                note: this.block.subText
            };
        }
    }
};
