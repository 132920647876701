import { render, staticRenderFns } from "./MediaVideo.vue?vue&type=template&id=eb6ba456&"
import script from "./MediaVideo.vue?vue&type=script&lang=js&"
export * from "./MediaVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CircleLoading: require('/app/components/CircleLoading.vue').default})
