//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseTooltip from '@/components/BaseTooltip';
import Typograph from '@/components/Typograph';
import makeClasses from '@/helpers/makeClasses';

const useClasses = makeClasses(() => ({
    root: ({ theme }) => ({
        'rounded-lg h-8 w-8 flex items-center justify-center transition-main cursor-pointer': true,
        'bg-blue hover:bg-opacity-80': theme === 'blue',
        'border border-gray-700 border-opacity-[0.12] hover:bg-black hover:bg-opacity-5 hover:border-none': theme === 'white'
    }),
    icon: ({ theme }) => ({
        'block fill-current': true,
        'text-white': theme === 'blue',
        'text-blue': theme === 'white'
    }),
    message: () => 'p-2.5'
}));

export default {
    components: { BaseTooltip, Typograph },
    props: {
        tooltipMessage: { type: String, required: false, default: '' },
        theme: {
            default: 'blue',
            validator(value) {
                return ['white', 'blue'].includes(value);
            }
        }
    },
    computed: {
        classes() {
            return useClasses({
                theme: this.theme
            });
        }
    }
};
