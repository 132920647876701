//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import WidgetCardStack from '@/components/CardStack/WidgetCardStack';

import { watch as watchResize, unwatch as unwatchResize } from '~/helpers/watchResize';
import { ExternalWidgetService } from '~/services/ExternalWidget';
import { EXTERNAL_WIDGET_TYPES } from '@/models/ExternalWidget';
import { UserTypes } from '@/models/enums';
import CalculatorLogic from '@/components/CalculatorLogic';
import ToggleGroup from '@/components/form/ToggleGroup';
import SelectField from '@/components/form/SelectField';
import SliderInput from '@/components/form/SliderInput';
import BaseIcon from '@/components/BaseIcon';
import BaseSlider from '@/components/BaseSlider';
import InputField from '@/components/form/InputField';
import CreditOffersCatalog from '@/components/Offer/CreditOffersCatalog';
import { OFFERS_SORT_TYPES } from '@/helpers/sortOffers';
import SortOffers from '@/components/SortOffers';
import ChooseSeveralOffersButton from '@/components/Offer/ChooseSeveralOffersButton';
import CityTooltip from '@/components/CityTooltip';
import { layoutStore, userStore } from '@/store';
import BestOffersBlock from '@/components/Offer/BestOffersBlock';
import { LAYER_NEWS_CHANNEL } from '@/models/Layer';
import NewsCatalog from '@/components/NewsCatalog';
import { LAYOUT_THEMES } from '@/constants/layout';
import { CREDIT_PRODUCT_RESPONSE_TYPES } from '@/constants/credits';
import CreditService from '@/services/Credit';
import isEqual from 'lodash/isEqual';

const DEFAULT_OFFERS_LIMIT = 8;
export default {
    components: {
        NewsCatalog,
        BestOffersBlock,
        CityTooltip,
        ChooseSeveralOffersButton,
        SortOffers,
        CreditOffersCatalog,
        InputField,
        BaseSlider,
        BaseIcon,
        SliderInput,
        SelectField,
        ToggleGroup,
        CalculatorLogic,
        WidgetCardStack
    },
    layout: 'empty',
    props: {
        id: {
            type: Number,
            required: false
        },
        internal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            widgets: null,
            blocks: {},
            blockLoader: {},
            pickReady: false,
            isFetchError: false,
            isWidgetsPending: false,
            userType: null,
            offersSort: null, // Выбранный тип сортировки предложения
            filterData: {},
            page: 1,
            userTypes: [],
            chosenBeneficialOffers: [],
            nuxtRoot: null,
            layersContainer: null,
            appendOffers: false
        };
    },
    fetch() {
        return this.fetchWidgets();
    },
    computed: {
        activeTheme() {
            return layoutStore.activeTheme;
        },
        logoTheme() {
            return (layoutStore.activeTheme === LAYOUT_THEMES.BLACK || layoutStore.activeTheme === LAYOUT_THEMES.BLUE) ? 'white' : 'black';
        },
        userCity() {
            return userStore.userCity;
        },
        widgetId() {
            return this.$route.query.id || this.id;
        },
        isOffersBlockExists() {
            const blocksShort = (this.widgets && this.widgets.blocks) || [];

            return !!blocksShort.find(block => block.type === EXTERNAL_WIDGET_TYPES.offer);
        },
        isAdvertBlockExists() {
            const blocksShort = (this.widgets && this.widgets.blocks) || [];

            return !!blocksShort.find(block => block.type === EXTERNAL_WIDGET_TYPES.advert);
        },
        informBlock() {
            return this.blocks[EXTERNAL_WIDGET_TYPES.inform];
        },
        isInformBlockExists() {
            const blocksShort = (this.widgets && this.widgets.blocks) || [];

            return !!blocksShort.find(block => block.type === EXTERNAL_WIDGET_TYPES.inform);
        },
        promosList() {
            const currentBlock = this.blocks[EXTERNAL_WIDGET_TYPES.promo];
            const promos = currentBlock && currentBlock.promos;
            if (!promos) { return []; }

            return promos
                .map(promo => {
                    return {
                        id: promo.id,
                        image: promo.image,
                        title: promo.title,
                        description: promo.text,
                        hrefExternal: promo.url,
                        isWide: false
                    };
                })
                .filter(item => item.image);
        },
        isPickBlockExists() {
            const blocksShort = (this.widgets && this.widgets.blocks) || [];

            return !!blocksShort.find(block => block.type === EXTERNAL_WIDGET_TYPES.pick);
        },
        isPromoBlockExists() {
            const blocksShort = (this.widgets && this.widgets.blocks) || [];

            return !!blocksShort.find(block => block.type === EXTERNAL_WIDGET_TYPES.promo);
        },
        isPromosExists() {
            return this.promosList.length > 0;
        },
        pickBlockAvailable() {
            return this.pickData && this.userType;
        },
        pickData() {
            return this.blocks[EXTERNAL_WIDGET_TYPES.pick];
        },
        pickId() {
            const blocksShort = (this.widgets && this.widgets.blocks) || [];

            return blocksShort.find(block => block.type === EXTERNAL_WIDGET_TYPES.pick)?.id;
        },
        widgetBlockPickId() {
            return this.pickData?.id;
        },
        offersLimit() {
            return DEFAULT_OFFERS_LIMIT;
        },
        offersSortingTypes() {
            return (Array.isArray(this.blocks[EXTERNAL_WIDGET_TYPES.offer]?.sortTypes) ? this.blocks[EXTERNAL_WIDGET_TYPES.offer]?.sortTypes : [])
                .map(type => {
                    const title = OFFERS_SORT_TYPES[type];
                    if (!type || !title) { return; }

                    return {
                        id: type,
                        title: OFFERS_SORT_TYPES[type],
                        key: type
                    };
                })
                .filter(x => x);

        },
        informSourceIds() {
            const informBlock = this.blocks[EXTERNAL_WIDGET_TYPES.inform];
            if (!informBlock?.sources?.length) {
                return null;
            }

            return informBlock.sources.map(item => item.id);
        },
        newsAddLink() {
            return `/?${ LAYER_NEWS_CHANNEL }=1`;
        },
        advertItems() {
            return this.blocks[EXTERNAL_WIDGET_TYPES.advert]?.creditProducts;
        },
        filterDataFull() {
            return {
                ...this.filterData,
                sort: this.offersSort,
                page: this.page,
                city: this.userCity?.id,
                creditType: this.externalCreditType || this.filterData.creditType,
                partnerId: this.pickData?.partnerId
            };
        },
        externalCreditType() {
            return this.pickData?.creditProductTypeId;
        }
    },
    watch: {
        filterData(value, oldValue) {
            if (String(value.userType) !== String(oldValue.userType)) {
                // this.act
            }
        },
        filterDataFull(value, oldValue) {
            if (isEqual(value, oldValue)) {
                return;
            }
            if (value.page === oldValue.page) {
                this.resetPagination();
            }
            this.$nextTick(() => {
                this.fetchCreditBoardOffersCount(value);
            });
        }
    },
    mounted() {
        this.nuxtRoot = document.querySelector('#__nuxt');
        this.layersContainer = document.querySelector('.layers-container');

        watchResize((args) => {
            this.onResize(args);
        });
        document.addEventListener('click', this.linkProcess, true);
    },
    destroyed() {
        unwatchResize(this.onResize);
        document.removeEventListener('click', this.linkProcess, true);
    },
    methods: {
        setTheme() {
            if (this.widgets?.theme && Object.values(LAYOUT_THEMES).includes(this.widgets.theme)) {
                layoutStore.changeTheme(this.widgets.theme === LAYOUT_THEMES.WHITE ? LAYOUT_THEMES.GRAY_BLUE : this.widgets.theme);
            }
        },
        onResize(args) {
            if (!this.nuxtRoot) {
                this.nuxtRoot = document.querySelector('#__nuxt');
            }
            if (!this.layersContainer) {
                this.layersContainer = document.querySelector('.layers-container');
            }

            if (this.nuxtRoot?.classList.contains('is-layer-locked') && this.layersContainer) {
                args.bodyHeight = this.layersContainer.querySelector('.layer-base')?.scrollHeight;
            }
            parent && parent.postMessage && parent.postMessage(args, '*');
        },
        linkProcess(event) {
            const link = event.target.closest('a');
            if (link) {
                const href = link.getAttribute('href');
                if (String(href).match(/^\/|https?:\/\//)) {
                    link.setAttribute('target', '_blank');
                    event.stopPropagation();
                }
            }
        },
        async fetchWidgets() {
            if (this.widgetId) {
                this.isWidgetsPending = true;
                const [widgets, error] = await ExternalWidgetService.getList(this.widgetId, { progress: false });
                this.isWidgetsPending = false;
                if (widgets) {
                    this.widgets = widgets;
                    this.setTheme();

                    await this.fetchWidgetBlocks();
                }
                if (error) {
                    this.isFetchError = true;
                }
            }
        },
        async fetchWidgetBlocks() {
            const blocks = this.widgets && this.widgets.blocks;
            const uniqueWidgets = (Array.isArray(blocks) ? blocks : []).reduce((accumulator, currentValue) => {
                if (!accumulator.find(item => item.type === (currentValue && currentValue.type))) { accumulator.push(currentValue); }

                return accumulator;
            }, []);
            const allRequests = [];
            uniqueWidgets.forEach(block => {
                this.$set(this.blockLoader, block.type, true);
                allRequests.push(
                    ExternalWidgetService.getBlock(block.id, { progress: false })
                        .then(([blockData]) => {
                            if (blockData) {
                                this.$set(this.blocks, block.type, blockData);
                            }
                        })
                        .finally(() => {
                            this.blockLoader[block.type] = false;
                        })
                );
            });

            await Promise.all(allRequests);
            this.setUserTypes();
            this.setSortOption();
        },
        setUserTypes() {
            const typeIds = (Array.isArray(this.pickData && this.pickData.borrowerTypes) ? this.pickData.borrowerTypes : []);
            if (!typeIds.length) { return null; }
            const userTypes = [];

            if (typeIds.includes(UserTypes.private)) {
                userTypes.push({ id: UserTypes.private, title: 'Частное лицо' });
            }

            if (typeIds.includes(UserTypes.company)) {
                userTypes.push({ id: UserTypes.company, title: 'Компания' });
            }

            if (!userTypes.length) { return null; }

            this.userType = userTypes[0].id;

            this.userTypes = userTypes;
        },
        setSortOption() {
            this.offersSort = this.offersSortingTypes[0]?.id;
        },
        onFilterChange(filterData) {
            if (!filterData) { return; }
            this.pickReady = true;
            this.filterData = filterData;
            if (this.externalCreditType) {
                this.filterData.creditType = this.externalCreditType;
            }
        },
        getOffersLink(formData) {
            const query = Object.keys(formData).reduce((result, currentKey) => {
                const value = formData[currentKey];
                if (value) {
                    result[currentKey] = value;
                }

                return result;
            }, {});
            query.userType = this.userType;

            return {
                name: 'offers',
                query
            };
        },
        onPageChange(payload) {
            if (!payload?.page) return;
            this.appendOffers = true;
            this.page = payload.page;
        },
        onOffersIsPending(value) {
            if (value === false) {
                this.appendOffers = false;
            }
        },
        async fetchCreditBoardOffersCount(payload) {
            if (!payload) return;

            try {
                const dataSend = {
                    ...payload,
                    typeResponse: CREDIT_PRODUCT_RESPONSE_TYPES.COUNT
                };

                this.creditOffersLength = await CreditService.fetchCreditOffers(dataSend, { progress: false });
            } catch (err) { // silent fail
                console.error(err);
            }
        },
        resetPagination() { // очистить пагинацию
            this.page = 1;
            this.appendOffers = false;
        }
    }
};
