import { render, staticRenderFns } from "./ExternalWidget.vue?vue&type=template&id=0602c707&"
import script from "./ExternalWidget.vue?vue&type=script&lang=js&"
export * from "./ExternalWidget.vue?vue&type=script&lang=js&"
import style0 from "./ExternalWidget.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CityTooltip: require('/app/components/CityTooltip.vue').default,BaseIcon: require('/app/components/BaseIcon.vue').default,BaseSlider: require('/app/components/BaseSlider.vue').default,CalculatorLogic: require('/app/components/CalculatorLogic.vue').default,SortOffers: require('/app/components/SortOffers.vue').default,NewsCatalog: require('/app/components/NewsCatalog.vue').default})
