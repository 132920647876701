var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        'flex relative text-white transition-medium mouse-device:hover:opacity-80': true,
        'min-h-[128px] items-center': _vm.view === 'horizontal',
    }},[(!_vm.isOnlyImage)?_c('div',{staticClass:"absolute inset-0 bg-gr-b-from-black-15-to-black-0"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"-lazyload absolute inset-0 bg-cover bg-center bg-no-repeat",attrs:{"data-bg":_vm.getImage(_vm.banner.image, 'rs:fit:776:410')}}),_vm._v(" "),(!_vm.isOnlyImage)?_c('div',{staticClass:"relative py-3 px-4 grid grid-cols-2 gap-x-3 max-w-[560px] mx-auto sm:p-2 w-full"},[_c('div',{class:{
                'col-span-1': _vm.view === 'horizontal',
                'col-span-2': _vm.view === 'vertical'
            }},[(_vm.banner.logo)?_c('BaseImage',{staticClass:"relative mb-2 max-h-6 sm:max-h-3 sm:mb-1.5",attrs:{"src":_vm.getImage(_vm.banner.logo, 'rs:fit:208:208')}}):_vm._e(),_vm._v(" "),(_vm.banner.title)?_c('Typograph',{class:{
                    'relative font-bold text-lg break-word a-sm:leading-[1.375rem] sm:text-sm sm:leading-tight': true,
                    'line-clamp-2': _vm.view === 'horizontal',
                    'line-clamp-3': _vm.view === 'vertical'
                },attrs:{"html":_vm.banner.title}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.isModalExists)?_c('a',{staticClass:"absolute inset-0",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}}):_c('a',{staticClass:"absolute inset-0",attrs:{"href":_vm.banner.url,"target":"_blank"}})])}
var staticRenderFns = []

export { render, staticRenderFns }