//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getImage from '@/helpers/getImage';

export default {
    name: 'UserCard',
    props: {
        user: { type: Object },
        small: { type: Boolean, default: false },
        showSummary: { type: Boolean, default: true }
    },
    methods: {
        getAvatar(url) {
            if (this.small) {
                if (url) {
                    return getImage(url, 'rs:fill:42:42/dpr:2');
                }

                return getImage('/images/avatar.png', 'rs:fill:42:42/dpr:2');
            }

            if (url) {
                return getImage(url, 'rs:fill:98:98/dpr:2');
            }

            return getImage('/images/avatar.png', 'rs:fill:98:98/dpr:2');
        }
    }

};
