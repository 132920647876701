//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        /** src-картинки */
        src: {
            type: String,
            required: true
        },
        /** srcset-картинки */
        srcset: {
            type: String,
            required: false
        },
        /** Использовать ленивую загрузку? Включено по умолчанию */
        lazyload: {
            type: Boolean,
            default: true
        },
        /** Заглушка, если картинка еще не загружена */
        placeholder: {
            type: String,
            required: false
        },
        /** Размеры изображения до загрузки */
        sizes: {
            type: String,
            required: false
        }
    },
    computed: {
        propsWathcer() { // https://github.com/vuejs/vue/issues/844#issuecomment-265315349
            return this.src, this.srcset, this.sizes, Date.now();
        }
    },
    watch: {
        propsWatcher() {
            if (this.lazyload) {
                this.$el.classList.remove('-lazyload-success');
                this.$el.classList.add('-lazyload');
            }
        }
    }
};
