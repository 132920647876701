import { render, staticRenderFns } from "./BaseCardStack.vue?vue&type=template&id=1463af07&"
import script from "./BaseCardStack.vue?vue&type=script&lang=js&"
export * from "./BaseCardStack.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/app/components/BaseIcon.vue').default,CardStack: require('/app/components/CardStack/CardStack.vue').default})
