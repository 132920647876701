import { render, staticRenderFns } from "./CardRemovable.vue?vue&type=template&id=4e57a638&"
import script from "./CardRemovable.vue?vue&type=script&lang=js&"
export * from "./CardRemovable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/app/components/BaseImage.vue').default,BaseIcon: require('/app/components/BaseIcon.vue').default})
