var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('TransitionGroup',{attrs:{"name":"fade"}},[_c('div',{key:"bg",class:['absolute inset-2 bottom-0 top-[-5.3rem] rounded-base pointer-events-none transition-long lg:inset-1 lg:bottom-0 lg:-top-16', {
                'bg-blue-870': _vm.currentUserTypeIsCompany || _vm.showAnimation,
                'bg-[#5B92D2]': !_vm.currentUserTypeIsCompany && _vm.showAnimation,
                'bg-black': !_vm.currentUserTypeIsCompany && !_vm.showAnimation
            }]}),_vm._v(" "),(_vm.imageUrl && !_vm.currentUserTypeIsCompany && !_vm.showAnimation && _vm.withLazyload)?_c('div',{key:_vm.backgroundImage,staticClass:"-lazyload absolute inset-2 bottom-0 top-[-5.3rem] rounded-base bg-cover bg-no-repeat bg-center pointer-events-none lg:inset-1 lg:bottom-0 lg:-top-16",style:({ backgroundImage: ("url(" + _vm.backgroundImagePlaceholder + ")") }),attrs:{"data-bg":_vm.backgroundImage}}):(_vm.imageUrl && !_vm.currentUserTypeIsCompany && !_vm.showAnimation)?_c('div',{key:_vm.backgroundImage,staticClass:"absolute inset-2 bottom-0 top-[-5.3rem] rounded-base bg-cover bg-no-repeat bg-center pointer-events-none lg:inset-1 lg:bottom-0 lg:-top-16",style:({ backgroundImage: ("url(" + _vm.backgroundImage + ")") })}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"z-20 absolute inset-2 bottom-0 top-[-5.3rem] rounded-base bg-gr-to-b-black-560 lg:inset-1 lg:bottom-0 lg:-top-16"}),_vm._v(" "),(_vm.currentUserTypeIsCompany || _vm.showAnimation)?_c('div',{staticClass:"absolute inset-2 bottom-0 top-[-5.3rem] pointer-events-none overflow-hidden lg:inset-1 lg:-top-16 lg:bottom-0 sm:rounded-b-base"},[_c('div',{class:{
                'absolute inset-0 overflow-hidden pointer-events-none a-4xl:container a-4xl:max-w-[1500px]': true,
                'filter-blur-150': _vm.blurArrow
            }},[_c('AnimatedPromoArrow',{staticClass:"absolute inset-0 top-4 left-16 pointer-events-none 4xl:left-6 sm:left-10 sm:top-24",attrs:{"colors":_vm.colors,"with-motion":!_vm.blurArrow}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"z-30 relative"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }