//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash/debounce';
import mobileEventsMixin from '@/mixins/mobileEventsMixin';

export default {
    name: 'CardStack',
    mixins: [mobileEventsMixin],
    props: {
        cards: {
            type: Array,
            default: () => []
        },
        cardWidth: {
            type: Number,
            default: () => 354
        },
        cardHeight: {
            type: Number,
            default: () => 160
        },
        stackWidth: {
            type: [Number, String],
            default: () => null
        },
        sensitivity: {
            type: Number,
            default: () => 0.25
        },
        maxVisibleCards: {
            type: Number,
            default: () => 5
        },
        scaleMultiplier: {
            type: Number,
            default: () => 0.5 // last visible card will be 50% scale
        },
        speed: {
            type: Number,
            default: () => 0.2
        },
        paddingHorizontal: {
            type: Number,
            default: () => 0
        },
        paddingVertical: {
            type: Number,
            default: () => 0
        },
        cardGutter: {
            type: Number,
            default: () => 6
        }
    },
    data() {
        return {
            stack: [],
            width: 0,
            activeCardIndex: 1,
            isDragging: false,
            dragStartX: 0,
            dragStartY: 0,
            isDraggingRight: false
        };
    },
    computed: {
        _stackWidth() {
            if (!this.stackWidth) {
                return this.cardWidth + this.paddingHorizontal * 2 + this.cardGutter * (this.maxVisibleCards - 1);
            } else if (typeof this.stackWidth === 'number') {
                return this.stackWidth;
            }

            return this.width || this.$el.clientWidth;
        },
        containerWidth() {
            if (!this.stackWidth) {
                return `${ this.cardWidth + this.paddingHorizontal * 2 + this.cardGutter * (this.maxVisibleCards - 1) }px`;
            } else if (typeof this.stackWidth === 'number') {
                return `${ this.stackWidth }px`;
            }

            return this.stackWidth;
        },
        _maxVisibleCards() {
            return this.cards.length > this.maxVisibleCards
                ? this.maxVisibleCards
                : this.cards.length - 1;
        },
        _scaleMultiplier() {
            return ((this.scaleMultiplier - 1) * -1) / 10;
        },
        elementXPosOffset() {
            return this.$el.getBoundingClientRect().x;
        },
        stackRestPoints() {
            return this.cards.map((item, index) => {
                const offset = this.xPosOffset * (index - 1);

                if (!index) {
                    return this._stackWidth + this.paddingHorizontal;
                } else if (index === 1) {
                    return this._stackWidth - this.cardWidth - this.paddingHorizontal;
                } else {
                    return (
                        this._stackWidth - this.cardWidth - offset - this.paddingHorizontal
                    );
                }
            });
        },
        cardDefaults() {
            return this.cards.map((card, index) => {
                const scale = index >= 1 ? 1 - this._scaleMultiplier * (index - 1) : 1;
                const xPos = this.stackRestPoints[index];

                return {
                    opacity: index > 0 && index < this._maxVisibleCards ? 1 : 0,
                    display: index < this._maxVisibleCards + 1 ? 'block' : 'none',
                    xPos: index < this._maxVisibleCards ? xPos : xPos + this.xPosOffset,
                    yPos: this.paddingVertical,
                    scale: scale > 0 ? scale : 0,
                    width: this.cardWidth,
                    height: this.cardHeight,
                    zIndex: this.cards.length - index
                };
            });
        },
        xPosOffset() {
            return (
                (this._stackWidth - this.cardWidth - (this.paddingHorizontal * 2)) /
                (this._maxVisibleCards - 2)
            );
        },
        originalActiveCardIndex() {
            if (this.stack[this.activeCardIndex]) {
                return this.stack[this.activeCardIndex]._index;
            }

            return 0;
        }
    },
    watch: {
        _scaleMultiplier: {
            handler() {
                this.rebuild();
            }
        },
        _maxVisibleCards: {
            handler() {
                this.rebuild();
            }
        }
    },
    mounted() {
        this.init();
        window.addEventListener('resize', this.handleResize, { passive: true });
        this.$refs.stack.addEventListener(this.touchStartEvent, this.handleDebouncedOnTouchStart);
        document.addEventListener(this.touchEndEvent, this.handleDebouncedOnTouchEnd);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        this.$refs.stack.removeEventListener(this.touchStartEvent, this.handleDebouncedOnTouchStart);
        document.removeEventListener(this.touchEndEvent, this.handleDebouncedOnTouchEnd);
    },
    methods: {
        handleDebouncedOnTouchStart(e) {
            if (e.which !== 1 && !this.isTouch) return;

            this.onTouchStart(e);
        },
        handleDebouncedOnTouchEnd(e) {
            if (e.which !== 1 && !this.isTouch) return;

            this.onTouchEnd(e);
        },


        init() {
            // move bottom card to top of stack (positioned offscreen)
            const cardsCopy = [...this.cards];
            cardsCopy.unshift(cardsCopy.pop());

            this.stack = cardsCopy.map((card, index) => {
                return {
                    _id: new Date().getTime() + index,
                    _index: index,
                    ...card,
                    ...this.cardDefaults[index]
                };
            });
        },
        rebuild() {
            this.$nextTick(() => {
                this.stack = this.stack.map((card, index) => {
                    return {
                        ...card,
                        ...this.cardDefaults[index]
                    };
                });
            });
        },
        handleResize: debounce(function() {
            this.width = this.$el.clientWidth;
            this.rebuild();
        }, 250),
        onNext() {
            const cardToMoveToBottomOfStack = this.stack.shift();
            this.stack.push(cardToMoveToBottomOfStack);
            this.rebuild();
        },
        onPrevious() {
            const cardToMoveToTopOfStack = this.stack.pop();
            this.stack.unshift(cardToMoveToTopOfStack);
            this.rebuild();
        },
        updateStack() {
            this.$emit('move', 0);

            if (this.isDraggingRight) {
                this.onNext();
            } else {
                this.onPrevious();
            }
        },
        moveStack(dragXPos) {
            const activeCardOffset = dragXPos - this.dragStartX;

            this.$emit(
                'move',
                activeCardOffset / (this.cardWidth + this.paddingHorizontal)
            );

            if (this.isDraggingRight) {
                this.activeCardIndex = 1;
            } else {
                this.activeCardIndex = 0; // first card is positioned offscreen
            }

            this.stack = this.stack.map((card, index) => {
                const isActiveCard = index === this.activeCardIndex;
                const xPos = isActiveCard
                    ? this.cardDefaults[index].xPos + activeCardOffset
                    : this.cardDefaults[index].xPos +
                    (this.xPosOffset / (this.cardWidth + this.paddingHorizontal)) *
                    activeCardOffset;

                const scale = isActiveCard
                    ? this.cardDefaults[index].scale
                    : this.cardDefaults[index].scale +
                    (this._scaleMultiplier /
                        (this.cardWidth + this.paddingHorizontal)) *
                    activeCardOffset;

                return {
                    ...card,
                    ...this.cardDefaults[index],
                    xPos,
                    scale,
                    opacity:
                        index === 0 && !this.isDraggingRight
                            ? 1
                            : this.cardDefaults[index].opacity
                };
            });
        },
        onTouchStart(e) {
            this.isDragging = true;
            this.dragStartX = this.getDragXPos(e) - this.elementXPosOffset;
            this.dragStartY = this.getDragYPos(e);

            document.addEventListener(this.dragEvent, this.onDrag);
        },
        onTouchEnd() {
            const wasDragging = this.isDragging && this.dragStartX;
            this.isDragging = false;
            this.dragStartX = 0;
            this.dragStartY = 0;

            document.removeEventListener(this.dragEvent, this.onDrag);
            if (wasDragging) this.updateStack();
        },
        onDrag(e) {
            const dragXPos = this.getDragXPos(e) - this.elementXPosOffset;

            this.isDraggingRight = dragXPos > this.dragStartX;
            this.moveStack(dragXPos);
        }
    }
};
