export default {
    props: {
        mask: {}
    },

    async mounted() {
        if (!this.mask) {
            return;
        }

        let options = {
            jitMasking: 0,
            clearMaskOnLostFocus: false,
            showMaskOnHover: false,
            showMaskOnFocus: true
        };

        if (this.mask === 'phone') {
            options = {
                ...options,
                mask: '+7 (999) 999-99-99',
                placeholder: '+7 (___) ___-__-__',
                autoUnmask: true,
                onUnMask: (maskedValue) => {
                    return `${ maskedValue.replace(/[\s-()]+/g, '') }`;
                }
            };
        } else if (this.mask === 'numeric') {
            options = {
                ...options,
                mask: '9{1,}',
                placeholder: ''
            };
        } else if (this.mask === 'numeric-group') {
            options = {
                ...options,
                digits: 0,
                max: 99999999999999999999,
                groupSeparator: ' ',
                placeholder: '',
                numericInput: true,
                autoGroup: true,
                alias: 'numeric',
                rightAlign: false,
                autoUnmask: true
            };
        } else if (this.mask === 'creditRate') {
            options = {
                ...options,
                mask: '9{1,2}[.99]',
                placeholder: ''
            };
        } else if (this.mask === 'money') {
            options = {
                ...options,
                digitsOptional: true,
                digits: 0,
                max: 99999999999999999999,
                groupSeparator: ' ',
                placeholder: '0',
                numericInput: true,
                autoGroup: true,
                suffix: ' ₽',
                alias: 'numeric',
                radixPoint: '.',
                rightAlign: false,
                autoUnmask: true
            };
        } else if (this.mask === 'date') {
            options = {
                ...options,
                mask: '99.99.9999',
                placeholder: '__.__.____',
                clearIncomplete: true,
                clearMaskOnLostFocus: true,
                casing: 'upper'
            };
        } else if (this.mask === 'vin') {
            options = {
                ...options,
                jitMasking: 0,
                mask: 'V{13}9{4}',
                definitions: {
                    V: {
                        validator: '[A-HJ-NPR-Za-hj-npr-z\\d]',
                        cardinality: 1,
                        casing: 'upper'
                    }
                },
                clearIncomplete: false,
                autoUnmask: true
            };
        } else if (this.mask === 'pts') {
            options = {
                ...options,
                jitMasking: 0,
                mask: '99V{2} 9{6}',
                definitions: {
                    V: {
                        validator: '[А-Яа-яЁё]',
                        cardinality: 1,
                        casing: 'upper'
                    }
                },
                clearIncomplete: false,
                autoUnmask: true
            };
        } else if (this.mask === 'passportSeries' || this.mask === 'code' || this.mask === 'year') {
            options = {
                ...options,
                mask: '9{4}'
            };
        } else if (this.mask === 'passportNumber') {
            options = {
                ...options,
                mask: '9{6}'
            };
        } else if (this.mask === 'businessOKPO') {
            options = {
                ...options,
                mask: '9{8}'
            };
        } else if (this.mask === 'individualOKPO') {
            options = {
                ...options,
                mask: '9{10}'
            };
        } else if (this.mask === 'businessOGRN') {
            options = {
                ...options,
                mask: '9{13}'
            };
        } else if (this.mask === 'individualOGRN') {
            options = {
                ...options,
                mask: '9{15}'
            };
        } else if (this.mask === 'cadastralNumber') {
            options = {
                ...options,
                mask: '9{2}:9{2}:9{7}:9{1}'
            };
        } else if (this.mask === 'departmentCode') {
            options = {
                ...options,
                mask: '9{3}-9{3}',
                autoUnmask: true,
                onUnMask: (maskedValue) => {
                    return `${ maskedValue.replace(/[^\d]/g, '') }`;
                }
            };
        } else {
            options = {
                ...options,
                ...this.mask
            };
        }

        if (Object.keys(this.$refs).length && typeof window !== 'undefined') {
            const Inputmask = await require('inputmask').default;
            this._im = new Inputmask(options);
            this._im.mask(this.$refs.input);
        }
    },
    beforeDestroy() {
        if (this._im) {
            this._im.remove();
        }
    }
};
