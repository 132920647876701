import pluralize from '~/helpers/pluralize';

/**
 * @param {number|string} period
 * @return {string}
 */
export default function prettyPeriod(period) {
    const numericInput = parseInt(period);

    if (Number.isNaN(numericInput)) {
        return '';
    }

    if (numericInput < 12) {
        const monthTitle = pluralize('месяц|месяца|месяцев', numericInput);

        return `${ numericInput } ${ monthTitle }`;
    } else {
        const year = Math.floor(numericInput / 12);
        const yearTitle = pluralize('год|года|лет', year);
        const months = numericInput % 12;
        const monthTitle = pluralize('месяц|месяца|месяцев', months);

        if (months === 0) {
            return  `${ year } ${ yearTitle }`;
        }

        return `${ year } ${ yearTitle } ${ months } ${ monthTitle }`;
    }
}
