//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DisclosureItem from '~/components/Disclosure/DisclosureItem';

export default {
    components: {
        DisclosureItem
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        openedItems: []
    }),
    computed: {
        items() {
            return this.block.questions.map(item => ({
                id: item.title,
                title: item.title,
                description: item.answer
            }));
        }
    }
};
