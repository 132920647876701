//
//
//
//
//
//
//
//
//
//
//
//

import Typograph from '@/components/Typograph';
import BaseIcon from '@/components/BaseIcon';

export default {
    components: { Typograph, BaseIcon },
    props: {
        title: { type: String, default: '', required: false },
        message: { type: String, default: '', required: false },
        icon: { // { name: String, class: String, width: Number, height: Number }
            type: Object
        },
        messageClassMod: { type: String, default: '', required: false },
        rootClassMod: { type: String, default: '', required: false }
    }
};
