//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import IntersectionObserver from '~/components/IntersectionObserver.vue';
import AllBanksBlock from '~/components/Offer/AllBanksBlock.vue';
import { userStore } from '~/store';
import { cityIn } from 'lvovich';

export default {
    name: 'ContentAllBanks',
    components: { AllBanksBlock, IntersectionObserver },
    props: {
        isMainPage: {
            type: Boolean,
            default: false
        },
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    },
    data: () => ({
        selectedCityId: null,
        selectedCityTitle: null
    }),
    computed: {
        cityId() {
            return this.selectedCityId || userStore.userCity?.id;
        },
        cityTitle() {
            return cityIn(this.selectedCityTitle || userStore.userCity?.title);
        }
    },
    methods: {
        async onCityClick() {
            const value = await this.$layer.open('CityConfirm', { justValue: true });
            if (value) {
                this.selectedCityId = value.id;
                this.selectedCityTitle = value.title;
            }
        }
    }
};
