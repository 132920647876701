//
//
//

import InlineFeedbackForm from '@/components/InlineFeedbackForm';
export default {
    components: { InlineFeedbackForm },
    props: {
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: false }
    },
    computed: {
        title() {
            return this.block?.title || '';
        },
        description() {
            return this.block?.text || '';
        },
        faqCards() {
            if (!this.block?.faq?.length) {
                return null;
            }

            return this.block.faq;
        }
    }
};
