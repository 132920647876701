//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EMPTY_PAGINATED_LIST } from '@/models/enums';
import ReviewService from '@/services/Review';
import { LAYER_ADD_VENDOR_REVIEW } from '@/models/Layer';
import BaseButton from '@/components/BaseButton';
import ReviewCard from '@/components/ReviewCard';
import CircleLoading from '@/components/CircleLoading';

const LIMIT_DEFAULT = 30;

export default {
    components: { CircleLoading, ReviewCard, BaseButton },
    props: {
        vendorId: {
            type: [Number, String],
            required: true
        },
        disableAdd: {
            type: Boolean,
            default: false
        },
        externalAdd: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isPending: false,
            list: {
                ...EMPTY_PAGINATED_LIST
            }
        };
    },
    fetch() {
        return this.fetchReviews();
    },
    computed: {
        reviews() {

            return (Array.isArray(this.list.items) ? this.list.items : []).map(item => {
                return {
                    ...item,
                    rating: item.grade,
                    date: item.createdAt,
                    title: item.authorName || ''
                };
            });
        },
        bank() {
            // toDo: Получать партнер с другой API, когда бэк будет готов
            const partner = this.reviews.filter(review => review.partner)?.[0]?.partner;
            if (!partner) {
                return null;
            }

            return {
                ...partner,
                reviews: partner.countReview,
                rating: (parseFloat(String(partner.rating)) || 0).toFixed(1)
            };
        },
        isShowMoreAvailable() {
            return this.list.pagination.pages > this.list.pagination.page;
        },
        isHasItems() {
            return this.reviews.length > 0;
        },
        externalAddLink() {
            return `/?${ LAYER_ADD_VENDOR_REVIEW }=${ this.vendorId }`;
        }
    },
    mounted() {
        this.$refs && this.$refs.closeButton && this.$refs.closeButton.focus();
    },
    methods: {
        async fetchReviews(append = false) {
            this.isPending = true;
            const [reviewsList] = await ReviewService.getList({
                params: {
                    page: this.list.pagination.page || 1,
                    limit: LIMIT_DEFAULT,
                    partnerId: this.vendorId
                },
                progress: false
            });
            this.isPending = false;
            if (reviewsList) {
                this.list.items = append ? [...this.list.items, ...reviewsList.items] : reviewsList.items;
                this.list.pagination = reviewsList.pagination;
            }
        },
        onLoadMore() {
            this.list.pagination.page++;
            this.fetchReviews(true);
        },
        onAddReview() {
            if (!this.vendorId || this.disableAdd) {
                return;
            }

            this.$layer.open('AddReviewLayer', { vendorId: this.vendorId });
        }
    }
};
