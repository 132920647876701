export const BANNERS_VIEW_COOKIE_NAME = 'vbids';
export const BANNERS_CLICK_COOKIE_NAME = 'cbids';

export const BANNER_POSITION_BEST_OFFERS = 3;

export const enum BANNER_PLACES {
    AD_LIST = 'ad_list', // => 'Выгодные предложения',
    LIST_START = 'list_start', // => 'Список предложений (в начале)',
    LIST_MIDDLE = 'list_middle', // => 'Список предложений (в середине)',
    FILTER = 'filter', // => 'Ленивый фильтр',
    APPLICATION = 'application', // => 'Заявки'
    PROMOBLOCK = 'promoblock', // => 'Заявки'
    TYPICAL_PAGE_RIGHT = 'typical_page_right', // => 'Типовая страница (справа)'
    TYPICAL_PAGE_CONTENT = 'typical_page_content', // => 'Типовая страница (в контенте)'
}
