//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { THEMES } from '~/components/form/BaseField';
import InputField from '~/components/form/InputField';
import BaseSlider from '~/components/BaseSlider';

export default {
    components: { InputField, BaseSlider },
    props: {
        value: { type: [Number, String] },
        title: { type: String },
        error: { type: [String, Boolean] },
        disabled: { type: Boolean },
        inputTheme: {
            default: THEMES.light,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        min: { type: Number, required: true },
        max: { type: Number, required: true },
        interval: { type: Number, default: 1 },
        lazy: { type: Boolean, default: false },
        inputMask: { type: String },
        readonly: { type: Boolean, required: false, default: false }
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isSliderDisabled() {
            return this.readonly || this.disabled;
        }
    },
    methods: {
        /** Реализация v-model.lazy */
        handleInputBlur(e) {
            const { value } = e.target;
            if (+value && +value >= this.min && +value <= this.max && +value !== this.inputValue) {
                this.inputValue = value;
            }
        }
    }
};
