//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SelectField from '~/components/form/SelectField';

function setCaretPosition(ctrl, pos) {
    if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
        const range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
}

export default {
    components: { SelectField },
    storageItems: ['popularSearchQueries'],
    props: {
        value: { type: [String, Object, Number] },
        initialPropSearch: { type: String }, // Помогает идентифицировть начальное значение и выбрать его. Например, можно передать строку с адресом
        title: { type: String, default: 'Поиск лучших предложений' },
        minSearchLength: { type: Number, default: 2 },
        options: { type: Array, default: () => [] },
        error: { type: String },
        requestSettings: { type: Object, default: () => ({}) }
    },
    data: () => ({
        suggestions: [],
        searchQuery: '', // строка поиска
        isCreated: false // Можно запускать ватчер, все данные инициализированы
    }),
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.searchQuery = newValue?.title || '';
                this.$emit('input', newValue && newValue.title ? newValue : { title: newValue, value: newValue });
            }
        },
        normalizedSuggestions() {
            return this.suggestions.filter(option => option?.title);
        }
    },
    watch: {
        async value(newVal) {
            if (typeof newVal == 'string' && this.isCreated) await this.updateOptions();
        }
    },
    created() {
        this.suggestions = [...this.suggestions, ...this.options];
        this.$nextTick(async() => {
            await this.updateOptions();
            this.$emit('initial-value-set');
            this.isCreated = true;
        });
    },
    methods: {
        async search(query) {
            if (query.length === 0 || query === '[object Object]') { this.suggestions = []; this.inputValue = ''; }

            if (this.popularSearchQueries) {
                this.suggestions = [{ title: query }, ...this.popularSearchQueries].map((item) => ({
                    ...item,
                    value: item.title
                }));
            }
        },
        /** Обработать выбор */
        handleSelect(selectedOption) {
            selectedOption?.title && this.setSearchQuery(selectedOption.title);

            this.$emit('select', selectedOption);
        },
        /** Установить фокус */
        focusSearch() {
            const searchEl = this.$refs.selectField?.$refs?.select?.$refs?.search;
            searchEl && setCaretPosition(searchEl, searchEl.value.length);
            this.setSearchQuery(this.searchQuery);
        },
        /** Добавить новое значение */
        addNewValue(title) {
            const option = { id: new Date().getTime(), title, value: title };

            this.suggestions = [option];
            this.inputValue = option.title;
        },
        setSearchQuery(query) { // Необходимо, чтобы обновить инпут с поиском, иначе выбранное значение не соответстует строке поиска
            const searchEl = this.$refs.selectField?.$refs?.select?.$refs?.search;
            if (searchEl) {
                const inputEvent = new Event('input', {
                    bubbles: true,
                    cancelable: true
                });

                searchEl.value = query;
                searchEl.dispatchEvent(inputEvent);
            }
        },
        async updateOptions() {
            if ((this.inputValue && typeof this.inputValue === 'string') || this.inputValue?.title) {
                const title = this.inputValue?.title || this.inputValue;
                const query = this.initialPropSearch ? this.initialPropSearch : title;

                await this.search(query);

                this.addNewValue(title);

                await this.$nextTick();

                this.inputValue?.title && this.setSearchQuery(this.inputValue?.title);
            }
        }
    }
};
