//
//
//
//
//
//

import SwiperThumbs from '~/components/swiper/SwiperThumbs';

export default {
    components: {
        SwiperThumbs
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    computed: {
        images() {
            return this.block.gallery && this.block.gallery.map(item => item.file && item.file.url).filter(x => !!x);
        }
    }
};
