import getImage from '@/helpers/getImage';
import { WIDGET_TYPES_MAP, WIDGET_TYPES } from '@/constants/widget';

export function normalizeBase(block) {
    if (!block) { return; }

    const baseNormalized = {
        ...block,
        size: block.size === 1 ? 6 : 12
    };

    const widgetNormalizeMap = {
        [WIDGET_TYPES.TEXT]: normalizeText,
        [WIDGET_TYPES.IMAGE]: normalizeImage,
        [WIDGET_TYPES.QUOTE]: normalizeQuote,
        [WIDGET_TYPES.QUESTION_ANSWER]: normalizeFaq,
        [WIDGET_TYPES.GALLERY]: normalizeGallery,
        [WIDGET_TYPES.VIDEO]: normalizeVideo,
        [WIDGET_TYPES.JAVASCRIPT]: normalizeJavascript,
        [WIDGET_TYPES.INFORM]: normalizeInform,
        [WIDGET_TYPES.WEBINAR_FORM]: normalizeWebinarForm,
        [WIDGET_TYPES.FORM]: normalizeForm,
        [WIDGET_TYPES.FEEDBACK_FORM]: normalizeFeedbackForm,
        [WIDGET_TYPES.PARTNERS]: normalizePartners,
        [WIDGET_TYPES.CLUES]: normalizeClues,
        [WIDGET_TYPES.CREDIT_PRODUCTS]: normalizeCreditProducts
    };

    const normalizeFunction = widgetNormalizeMap[baseNormalized.type];

    return normalizeFunction ? normalizeFunction(baseNormalized) : baseNormalized;
}

function normalizePartners(block) {
    return block;
}

function normalizeClues(block) {
    return block;
}

function normalizeCreditProducts(block) {
    return block;
}

function normalizeText(block) {
    return block;
}

function normalizeImage(block) {
    return block;
}

function normalizeJavascript(block) {
    return block;
}

function normalizeQuote(block) {
    return {
        ...block,
        subText: block.authorDescription,
        authorImage: getImage(block.authorAvatar, 'rs:fill:64:64/dpr:2')
    };
}

function normalizeFaq(block) {
    return {
        ...block,
        type: WIDGET_TYPES_MAP.QUESTION_ANSWER,
        questions: (Array.isArray(block.faq) ? block.faq : []).map(faqItem => {
            return {
                title: faqItem.question,
                answer: faqItem.answer
            };
        })
    };
}

function normalizeGallery(block) {
    return {
        ...block
    };
}

function normalizeVideo(block) {
    const video = block.video;
    if (block.video) {
        video.imageUrl = block.image_url;
        video.embedUrl = block.embed_url;
    }

    return {
        ...block,
        video
    };
}

function normalizeInform(block) {
    return {
        ...block,
        sourceIds: (Array.isArray(block.sources) ? block.sources : []).map(item => item.id),
        isNormalContainer: true
    };
}

function normalizeWebinarForm(block) {
    return {
        ...block,
        type: WIDGET_TYPES_MAP.WEBINAR_FORM
    };
}

function normalizeForm(block) {
    return {
        ...block
    };
}

function normalizeFeedbackForm(block) {
    return {
        ...block,
        type: WIDGET_TYPES_MAP.FEEDBACK_FORM
    };
}
