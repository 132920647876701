
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    extends: Multiselect,
    props: {
        searchable: {
            type: Boolean,
            default: false
        },
        showLabels: {
            type: Boolean,
            default: false
        },
        deselectLabel: {
            type: String,
            default: '[Enter] чтобы удалить'
        },
        allowEmpty: {
            type: Boolean,
            default: false
        }
    }
};
