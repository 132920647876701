import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';
import { ExternalWidgetBlocks, ExternalWidgetList } from '~/models/ExternalWidget';

export class ExternalWidgetService {
    static getList<R = ExternalWidgetList>(widgetId: string|number, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/widgets/${ widgetId }`, config));
    }

    static getBlock<R = ExternalWidgetBlocks>(blockId?: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/widgetBlocks/${ blockId }`, config));
    }

    static pickClick<R = null>(id: number|string, config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get(`/api/widgetBlocks/${ id }/pickClick`, config));
    }
}
