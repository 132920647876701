//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DisclosureItem from '~/components/Disclosure/DisclosureItem';
import getImage from '~/helpers/getImage';

export default {
    components: {
        DisclosureItem
    },
    props: {
        block: {
            type: Object,
            required: true
        }
    },
    data: () => {
        return {
            openedItems: []
        };
    },
    mounted() {
        this.openedItems = this.block && this.block.items && this.block.items[0] && this.block.items[0].title ? this.block.items[0].title : [];
    },
    methods: {
        optimizedImageUrl(image) {
            const imageUrl = image && image.file.url;

            return imageUrl ? getImage(imageUrl) : null;
        }
    }
};
