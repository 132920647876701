//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentSizeMixin from '~/mixins/documentSizeMixin';
import BaseCardStack from '~/components/CardStack/BaseCardStack';

export default {
    components: { BaseCardStack },
    mixins: [documentSizeMixin],
    props: {
        faq: { type: Array, required: true }
    },
    data() {
        return {
            faqCardWidth: 354,
            faqCardHeight: 160
        };
    },
    mounted() {
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            if (this.windowSize.width <= 600) {
                this.faqCardWidth = 300;
                this.faqCardHeight = 120;
            } else if (this.windowSize.width <= 1000) {
                this.faqCardWidth = 300;
                this.faqCardHeight = 170;
            }  else {
                this.faqCardWidth = 351;
                this.faqCardHeight = 170;
            }
        }
    }
};
