//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ToggleGroup from '~/components/form/ToggleGroup';
import NewsItem from '~/components/NewsItem';
import CircleLoading from '~/components/CircleLoading';
import BasePagination from '~/components/BasePagination';
import BaseButton from '~/components/BaseButton';
import BaseIcon from '~/components/BaseIcon';

import processError from '~/helpers/processError';
import catalogObjectMixin from '~/mixins/catalogObjectMixin';
import documentSizeMixin from '~/mixins/documentSizeMixin';
import NewsService from '~/services/News';
import isEqual from 'lodash/isEqual';

const LIMIT_PER_PAGE = 6;
const MAX_FEED_COLUMNS_SIZE = 3;

export default {
    components: {
        ToggleGroup, NewsItem, CircleLoading,
        BasePagination, BaseButton, BaseIcon
    },
    mixins: [catalogObjectMixin, documentSizeMixin],
    props: {
        theme: { type: String, required: false },
        addLink: { type: String, required: false },
        listQueryParams: { type: Object, default: () => ({}) },
        categoriesQueryParams: { type: Object, default: () => ({}) },
        itemsDetailLink: { type: Boolean, default: false },
        fullWidth: { type: Boolean, default: true }
    },
    data: () => ({
        filterData: {
            categoryId: null
        },
        news: null,
        categories: null,
        isNewsFetching: false,
        isCategoriesFetching: false,
        newsColumnsSize: MAX_FEED_COLUMNS_SIZE, // максимальный размер новостного блока
        isMasonryReady: false,
        masonryInstance: null
    }),
    async fetch() {
        await this.fetchCategories();
        this.news = await this.fetchNews(this.listQueryParams);
        if (this.news) {
            this.loadMasonry();
        }
    },
    computed: {
        // importMasonry() {
        //     return import Masonry from 'masonry-layout';
        // },
        newsList() {
            if (!this.news || !this.news.pagination.total) {
                return null;
            }

            return this.news.items;
        },
        themes() {
            return {
                toggleTheme: this.theme || 'simple',
                toggleItemTheme: this.theme || 'white',
                action: this.theme || 'black'
            };
        }
    },
    watch: {
        async queryFilters(newVal, oldVal) {
            if (!isEqual(newVal, oldVal)) {
                this.news = await this.fetchNews(this.listQueryParams);
            }
        },
        news: {
            deep: true,
            handler(newVal, oldVal) {
                if (!isEqual(newVal, oldVal)) {
                    this.reloadGrid();
                }
            }
        }
    },
    mounted() {
        if (this.news) {
            this.loadMasonry();
        }
        this.calcNewsColumnSize();
        window.addEventListener('resize', this.calcNewsColumnSize, { passive: true });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calcNewsColumnSize);
        this.masonryInstance && this.masonryInstance.destroy();
    },
    methods: {
        loadMasonry() {
            if (typeof window !== 'undefined' && typeof window.Masonry === 'undefined') {
                import('masonry-layout').then(({ default: Masonry }) => {
                    window.Masonry = Masonry;
                    this.initGrid();
                });
            } else {
                this.initGrid();
            }
        },
        initGrid() {
            const elem = this.$refs.masonryGrid;
            if (!elem) {
                return;
            }

            elem.addEventListener('lazyloaded', () => {
                this.masonryInstance.layout();
            });

            this.masonryInstance = new window.Masonry(elem, {
                itemSelector: '.js-masonry-item'
            });

            this.isMasonryReady = true;
        },
        reloadGrid() {
            if (!this.masonryInstance?.reloadItems || !this.masonryInstance?.layout) {
                return;
            }
            this.$nextTick(() => {
                this.masonryInstance.reloadItems();
                this.masonryInstance.layout();
            });
        },
        /** получить новости */
        async fetchNews(queryParams = {}) {
            try {
                this.isNewsFetching = true;

                return await NewsService.fetchNews({
                    page: 1,
                    ...queryParams,
                    ...this.getActiveFiltersQuery(),
                    limit: LIMIT_PER_PAGE
                });
            } catch (err) {
                processError(err);
            } finally {
                this.isNewsFetching = false;
            }
        },
        /** получить категории новостей */
        async fetchCategories() {
            try {
                this.isCategoriesFetching = true;
                const categories = await NewsService.fetchNewsCategories({
                    params: this.categoriesQueryParams
                });
                this.categories = categories && categories.map(item => ({
                    id: item.id,
                    title: item.titleParentCase
                }));
                if (categories && Array.isArray(categories) && categories[0]) {
                    this.filterData.categoryId =  categories[0].id;
                }
            } catch (err) {
                processError(err);
            } finally {
                this.isCategoriesFetching = false;
            }
        },
        calcNewsColumnSize() {
            this.newsColumnsSize = MAX_FEED_COLUMNS_SIZE;

            if (this.windowSize.width < 1000) {
                this.newsColumnsSize = 2;
            } else if (this.windowSize.width < 600) {
                this.newsColumnsSize = 1;
            }
        },
        /**
         * Переключение страницы. Проверяем тип
         * В случае бесконечной загрузки мержим массивы итемов
         * @param payload
         * @returns {Promise<void>}
         */
        async onPageChange(payload) {
            if (!payload || !this.isMasonryReady) return;
            const page = payload.page;
            const isAppend = payload.isAppend || false;
            const queryParams = {
                ...this.listQueryParams,
                page
            };

            try {
                const news = await this.fetchNews(queryParams);
                const { items, ...pagination } = news;
                this.news = isAppend ? {
                    items: [...this.news.items, ...items],
                    ...pagination
                } : news;
            } catch (err) {
                processError(err);
            }
        }
    }
};
