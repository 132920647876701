//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import swiperMixin from '~/mixins/swiperMixin';
import documentSizeMixin from '~/mixins/documentSizeMixin';
import AllBanks from '~/components/Offer/AllBanks.vue';

export default {
    components: { AllBanks },
    mixins: [swiperMixin, documentSizeMixin],
    props: {
        offers: { type: Array, required: true },
        value: { type: [Boolean, String, Number, Array] }, // значение чекбокса
        theme: { type: String, required: false, default: 'white' } // TODO: Показ для верстки
    },
    computed: {
        swiperOptions() {
            return Object.assign(
                {},
                this.swiperOptionsBase,
                {
                    pagination: {
                        ...(this.swiperOptionsBase.pagination || {}),
                        bulletClass: 'swiper-pagination__bullet ',
                        bulletActiveClass: 'swiper-pagination__bullet_active'
                    },
                    spaceBetween: 20,
                    watchOverflow: true,
                    slidesPerView: 'auto'
                }
            );
        }
    }
};
