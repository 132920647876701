//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import swiperMixin from '~/mixins/swiperMixin';
import BaseImage from '~/components/BaseImage';
import getImage from '~/helpers/getImage';

export default {
    components: { BaseImage },
    mixins: [swiperMixin],
    props: {
        partners: { type: Array, required: true }
    },
    computed: {
        swiperOptions() {
            return Object.assign(
                {},
                this.swiperOptionsBase,
                {
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false
                    },
                    freeMode: true,
                    centeredSlides: true,
                    loop: true,
                    speed: 6000,
                    spaceBetween: 84,
                    allowTouchMove: false,
                    slidesPerView: 'auto',
                    breakpoints: {
                        0: {
                            spaceBetween: 56
                        },
                        750: {
                            spaceBetween: 84
                        }
                    }
                }
            );
        }
    },
    mounted() {
        if (this.swiperInstance && this.swiperInstance.wrapperEl) {
            this.swiperInstance.wrapperEl.style['transition-timing-function'] = 'linear';  // чтобы элементы свайпера плавно сменяли друг друга
            this.swiperInstance.wrapperEl.style['align-items'] = 'center';
        }
    },
    destroyed() {
        setTimeout(() => {
            this.swiperInstance.destroy();
        }, 1000);
    },
    methods: {
        optimizedImageUrl(imageUrl) {
            return getImage(imageUrl, 'rs:fit:152:54');
        }
    }
};
