//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InputField from '@/components/form/InputField';
import BaseButton from '@/components/BaseButton';
import { WebinarService } from '@/services/Webinar';
import processError from '@/helpers/processError';
import Typograph from '@/components/Typograph';
import CircleLoading from '@/components/CircleLoading';
export default {
    name: 'ContentWebinarForm',
    components: { CircleLoading, Typograph, BaseButton, InputField },
    props: {
        block: { type: Object, required: true }
    },
    data() {
        return {
            formData: {
                email: '',
                firstName: '',
                lastName: ''
            },
            errors: {
                email: '',
                firstName: '',
                lastName: ''
            },
            isFormSending: false
        };
    },
    computed: {
        title() {
            return this.block.title || '';
        },
        description() {
            return this.block.text || '';
        },
        eventId() {
            return this.block.eventId;
        },
        registerData() {
            return {
                ...this.formData,
                eventId: this.eventId
            };
        }
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.clearFormErrors();
            }
        }
    },
    methods: {
        clearFormErrors() {
            this.errors = {
                email: '',
                firstName: '',
                lastName: ''
            };
        },
        clearForm() {
            this.formData = {
                email: '',
                firstName: '',
                lastName: ''
            };
        },
        async onSubmit() {
            if (this.isFormSending) {
                return;
            }
            this.clearFormErrors();
            const [response, error] = await WebinarService.register(this.registerData, { progress: false });
            if (response) {
                let message = '';
                const url = response.link;
                if (url) {
                    message = `
                        <p>Вы успешно зарегистрировались на вебинар</p>
                        <p>Ссылка на вебинар: <a href="${ url }" target="_blank">${ url }</a></p>
                    `;
                } else {
                    message = 'Вы успешно зарегистрировались на вебинар';
                }

                this.$layer.alert({
                    title: 'Спасибо',
                    message,
                    withButton: true,
                    icon: {
                        name: 'complex-stars-success'
                    }
                });
                this.clearForm();
            }
            if (error) {
                processError(error, this.errors);
            }
        }
    }
};
