import { render, staticRenderFns } from "./ContentHelper.vue?vue&type=template&id=1f72fe2e&"
import script from "./ContentHelper.vue?vue&type=script&lang=js&"
export * from "./ContentHelper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserCard: require('/app/components/UserCard.vue').default,Typograph: require('/app/components/Typograph.vue').default})
