//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseField, { THEMES, VIEWS, TEXT_SIZE } from '@/components/form/BaseField';
import formFieldMixin from '@/mixins/formFieldMixin';
import inputMaskMixin from '@/mixins/inputMaskMixin';
import makeClasses from '~/helpers/makeClasses';

// language=HTML prefix=<div\u0020class=" suffix="\\/>
const useClasses = makeClasses(() => ({
    input: ({ theme, textSize, disabled, readonly, type }) => ({
        'bg-transparent w-full h-full outline-none appearance-none pr-3 pb-4': true,
        'pt-7': type !== 'textarea',
        'mt-7': type === 'textarea', // иначе скролл перекрывает лейбл
        'text-white': theme === THEMES.light,
        'text-black': theme === THEMES.dark,
        'text-md': textSize === TEXT_SIZE.base,
        'text-xl': textSize === TEXT_SIZE.xl,
        'text-opacity-50': disabled,
        'cursor-default pointer-events-none': readonly
    })
}));
export { useClasses };

export default {
    components: { BaseField },
    extends: BaseField,
    mixins: [formFieldMixin, inputMaskMixin],
    props: {
        theme: {
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        textSize: {
            default: TEXT_SIZE.base,
            validator(value) {
                return Object.keys(TEXT_SIZE).includes(value);
            }
        },
        readonly: { type: Boolean, required: false, default: false },
        autocomplete: { type: String, required: false },
        inputmode: { type: String, required: false },
        maxlength: { type: Number, required: false },
        /** textarea или нет */
        type: { type: String, default: 'text', required: false },
        /** Кол-во строк. Для типа textarea */
        rows: { type: [String, Number], default: 1, required: false }
    },
    computed: {
        classes() {
            return useClasses({
                theme: this.theme,
                textSize: this.textSize,
                disabled: this.disabled,
                readonly: this.readonly,
                type: this.type
            });
        },
        inputPropsCombined() {
            return {
                ...this.inputProps,
                readonly: this.readonly,
                autocomplete: this.autocomplete,
                inputmode: this.inputmode,
                maxlength: this.maxlength
            };
        }
    },
    methods: {
        // Обработчики событий для textarea
        onFocusTextarea() {
            this.$emit('focus');
            this.isFocused = true;
        },
        onBlurTextarea() {
            this.$emit('blur');
            this.isFocused = false;
        }
    }
};
