//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getImage from '~/helpers/getImage';
import BaseIcon from '~/components/BaseIcon';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    components: {
        Swiper,
        SwiperSlide,
        BaseIcon
    },
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        mainOptions: {
            loop: true,
            loopedSlides: 5,
            navigation: {
                nextEl: '[data-swiper-next]',
                prevEl: '[data-swiper-prev]',
                disabledClass: 'is-navigation-disabled',
                hiddenClass: 'is-navigation-hidden'
            },
            centeredSlides: true,
            grabCursor: true,
            spaceBetween: 0
        },
        thumbsOptions: {
            loop: true,
            loopedSlides: 5,
            spaceBetween: 8,
            slidesPerView: 3.45,
            centeredSlides: true,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            slideActiveClass: 'relative before:absolute before:inset-0 before:border-2 before:rounded-xl before:border-black',
            breakpoints: {
                600: {
                    slidesPerView: 5
                }
            }
        }
    }),
    mounted() {
        this.$nextTick(() => {
            const swiperMain = this.$refs.main.$swiper;
            const swiperThumbs = this.$refs.thumbs.$swiper;
            swiperMain.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperMain;
        });
    },
    methods: {
        getImage,
        onSwiperReady(swiper) {
            swiper.el.classList.remove('invisible');
        }
    }
};
