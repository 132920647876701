//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import swiperMixin from '~/mixins/swiperMixin';
import documentSizeMixin from '~/mixins/documentSizeMixin';
import OfferCard from '@/components/Offer/markup/OfferCard';
import BaseButton from '~/components/BaseButton';
import CreditOffer from '@/components/Offer/CreditOffer';
import AdBanner from '~/components/AdBanner';
import AdBannerController from '@/components/AdBannerController';

export default {
    components: { AdBannerController, OfferCard, BaseButton, CreditOffer, AdBanner },
    mixins: [swiperMixin, documentSizeMixin],
    props: {
        offers: { type: Array, required: true },
        value: { type: [Boolean, String, Number, Array] }, // значение чекбокса
        isCardCheckbox: { type: Boolean, default: true, required: false }, // является ли карточка свайпера чекбоксом
        isCardCheckboxVisible: { type: Boolean, default: false, required: false }, // показать значок чекбокса на карточке
        theme: { type: String, required: false, default: 'white' }, // TODO: Показ для верстки
        creditTypeId: { type: Number }, // выбранный тип кредита,
        isMarkup: { type: Boolean, default: false } // TODO: Показ для верстки
    },
    computed: {
        swiperOptions() {
            return Object.assign(
                {},
                this.swiperOptionsBase,
                {
                    pagination: {
                        ...(this.swiperOptionsBase.pagination || {}),
                        bulletClass: 'swiper-pagination__bullet ',
                        bulletActiveClass: 'swiper-pagination__bullet_active'
                    },
                    spaceBetween: 20,
                    watchOverflow: true,
                    slidesPerView: 'auto'
                }
            );
        },
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        offersNormalized() {
            return (this.offers && Array.isArray(this.offers) ? this.offers : []).map(item => {
                let applicationUrl = {
                    name: 'application',
                    query: {
                        offers: item.id,
                        creditType: this.creditTypeId,
                        ...this.applicationRouteQuery
                    }
                };

                return {
                    ...item,
                    applicationUrl: item.referralLink || applicationUrl
                };
            });
        }
    }
};
