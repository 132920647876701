export const THEMES = {
    dark: 'dark',
    white: 'white',
    light: 'light',
    global: 'global'
};

export const VIEWS = {
    base: 'base',
    suggestion: 'suggestion'
};

export default {
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        noOptions: {
            type: String,
            default: 'Нет данных'
        },
        noResult: {
            type: String,
            default: 'Ничего не найдено'
        },
        placeholder: {
            type: String,
            default: ''
        },
        /** Значение модели, которое ожидаем для возвращения. Например, необходимо вернуть только поле 'id' объекта */
        modelValueKey: {
            type: String, default: ''
        },
        theme: {
            default: THEMES.dark,
            validator(value) {
                return Object.keys(THEMES).includes(value);
            }
        },
        view: {
            default: VIEWS.base,
            validator(value) {
                return Object.keys(VIEWS).includes(value);
            }
        },
        disabled: { type: Boolean, required: false, default: false }
    },
    data: () => ({
        fieldFocused: false
    }),
    computed: {
        /** Прокидывает необходимые значения, если задан проп modelValueKey. Иначе работает как обычно */
        selectValue: {
            get() {
                let result = this.value;

                if (this.modelValueKey && result) {
                    if (Array.isArray(result)) {
                        result = this.options.filter(item => result.includes(item[this.modelValueKey]));
                    } else {
                        result = this.options.find(item => item[this.modelValueKey] === result);
                    }
                }

                return result;
            },
            set(newValue) {
                let result = newValue;

                if (this.modelValueKey && result) {
                    if (Array.isArray(newValue)) {
                        result = newValue.map(item => item[this.modelValueKey]);
                    } else if (typeof newValue === 'object') {
                        result = newValue[this.modelValueKey];
                    }
                }

                this.$emit('input', result);
            }
        },
        rootProps() {
            const { title, disabled, error, focus, id, readonly, value, theme } = this.$props;

            return { title, disabled, error, focus, id, readonly, value, theme };
        },
        inputPropsCombined() {
            return {
                ...this.inputProps,
                ...this.$attrs
            };
        },
        themeClass() {
            switch (this.theme) {
                case THEMES.dark: {
                    return { field: 'field_theme_dark', multiselect: 'multiselect_theme_dark' };
                }
                case THEMES.light: {
                    return { field: 'field_theme_light', multiselect: 'multiselect_theme_light' };
                }
                case THEMES.white: {
                    return { field: 'field_theme_light', multiselect: 'multiselect_theme_global' };
                }
                case THEMES.global: {
                    return { field: 'field_theme_global', multiselect: 'multiselect_theme_global' };
                }
                default: {
                    return { field: '', multiselect: '' };  // не вешаем доп. классы
                }
            }
        },
        viewClass() {
            switch (this.view) {
                case VIEWS.suggestion: {
                    return { field: 'field_view_suggestion', multiselect: 'multiselect_view_suggestion' };
                }
                default: {
                    return { field: '', multiselect: '' };  // не вешаем доп. классы
                }
            }
        }
    },
    methods: {
        onFocus() {
            this.fieldFocused = true;
            this.$emit('open', true);
        },
        onBlur() {
            this.fieldFocused = false;
            this.$emit('open', false);
        },
        handleOptionHover() {
            this.$emit('option-hover');
        }
    }
};
