//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Typograph from '~/components/Typograph.vue';
import getImage from '~/helpers/getImage';

export default {
    name: 'ContentNewsArticles',
    components: { Typograph },
    props: {
        isMainPage: {
            type: Boolean,
            default: false
        },
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    },
    computed: {
        restNews() {
            return this.block.items.filter(i => !i.isMain);
        },
        mainNews() {
            return this.block.items.filter(i => i.isMain);
        }
    },
    methods: {
        formatData(data) {
            return data?.replace(/<h4/gi, '<div class="title-h4" ')
                .replace(/<h5/gi, '<div class="title-h5" ')
                .replace(/<h6/gi, '<div class="title-h6" ')
                .replace(/<\/h[4-6]>/gi, '</div>');
        },
        optimizedImageUrl(image) {
            const imageUrl = image && image.file.url;

            return imageUrl ? getImage(imageUrl) : null;
        },
        trimHtml(html, maxLength) {
            let length = 0;
            const tagStack = [];
            const trimmedHtml = [];
            const tagPattern = /<\/?([a-zA-Z0-9]+)(?:[^>]*?)>|[^<]+/g;
            let match;

            while ((match = tagPattern.exec(html)) !== null) {
                if (match[0].startsWith('<')) {
                    if (match[0][1] !== '/') {
                        // Opening tag
                        tagStack.push(match[1]);
                        trimmedHtml.push(match[0]);
                    } else {
                        // Closing tag
                        const lastTag = tagStack.pop();
                        if (lastTag === match[1]) {
                            trimmedHtml.push(match[0]);
                        }
                    }
                } else {
                    // Text content
                    const text = match[0];
                    if (length + text.length > maxLength) {
                        trimmedHtml.push(text.slice(0, maxLength - length));
                        length = maxLength;
                        break;
                    } else {
                        trimmedHtml.push(text);
                        length += text.length;
                    }
                }
            }

            // Close any remaining opened tags
            while (tagStack.length > 0) {
                const tagName = tagStack.pop();
                trimmedHtml.push(`</${ tagName }>`);
            }

            return trimmedHtml.join('');
        }
    }
};
