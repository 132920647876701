import api, { apiFetch, FetchResult, RequestConfig } from '~/helpers/api';
import { ITag } from '~/models/List';

export default class ListService {
    /**
     * Получить список всех тегов
     * @param config
     */
    static apiFetchTagsList<R = ITag[]>(config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get('/api/list/tags', config));
    }

    /**
     * Получить список тегов, назначенных на какие-то кредитные продукты
     * @param config
     */
    static apiFetchCreditProductsTagsList<R = ITag[]>(config?: RequestConfig): FetchResult<R> {
        return apiFetch(() => api.get('/api/list/tags/creditProducts', config));
    }
}
