//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LAYER_NEWS_CHANNEL } from '@/models/Layer';
import NewsCatalog from '@/components/NewsCatalog';

export default {
    name: 'ContentInform',
    components: { NewsCatalog },
    props: {
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    },
    computed: {
        sourceIds() {
            return this.block.sourceIds || [];
        },
        newsAddLink() {
            return `/?${ LAYER_NEWS_CHANNEL }=1`;
        }
    }
};
