//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import IntersectionObserver from '~/components/IntersectionObserver.vue';
import ReviewsBlock from '~/components/Offer/ReviewsBlock.vue';

export default {
    name: 'ContentReviews',
    components: { ReviewsBlock, IntersectionObserver },
    props: {
        isMainPage: {
            type: Boolean,
            default: false
        },
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    }
};
