//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getImage from '~/helpers/getImage';
import ExchangeRatesService from '../../services/ExchangeRates';
import BaseButton from '~/components/BaseButton';

export default {
    name: 'ContentCreditProducts',
    components: { BaseButton },
    props: {
        isMainPage: {
            type: Boolean,
            default: false
        },
        block: { type: Object, required: true },
        fullWidth: { type: Boolean, default: true }
    },
    data: () => ({
        currencies: {},
        showAll: false
    }),
    async fetch() {
        const c = (this.block.currencies || []).map(c => c.currency);

        return await ExchangeRatesService.fetchExchangeRates(c).then(r => {
            this.currencies = r;
        });
    },
    computed: {
        publicCurrencies() {
            if (this.showAll) {
                return this.currencies;
            }

            const cur = {};
            (this.block.currencies || []).map(c => c.currency).slice(0, 6)
                .forEach(c => {
                    cur[c] = this.currencies[c];
                });

            return cur;
        }
    },
    methods: {
        toggleCurrencies() {
            this.showAll = !this.showAll;
        },
        optimizedImageUrl(image) {
            const imageUrl = image && image.file.url;

            return imageUrl ? getImage(imageUrl) : null;
        }
    }
};
