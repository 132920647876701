var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.blocksComponents && _vm.blocksComponents.length)?_c('div',[_c('div',{staticClass:"grid grid-cols-12 gap-x-8 gap-y-10 md:gap-x-0 md:gap-y-8"},_vm._l((_vm.blocksComponents),function(item,index){return _c('div',{key:item.id,class:[
                item.size, 'md:col-span-12'
            ]},[(index + 1 === _vm.blocksComponents.length && _vm.contentBanner)?_c('div',{staticClass:"content-banner"},[_c('AdBannerController',{staticClass:"flex relative text-white rounded-base transition-main hover:opacity-80 w-[fit-content]",attrs:{"banner":item}},[_c('img',{staticClass:"relative rounded-xl",attrs:{"src":_vm.optimizedImageUrl(typeof _vm.contentBanner.image === 'string' ? _vm.contentBanner.image : _vm.contentBanner.image.file.url),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"absolute inset-0 bg-gr-b-from-black-15-to-black-0 rounded-base md:bg-gr-b-from-black-35-to-black-0"}),_vm._v(" "),_c('div',{staticClass:"absolute bottom-0 flex flex-col flex-auto justify-between items-start lg:p-6 py-10 px-8"},[_c('div',[(_vm.contentBanner.title)?_c('div',{staticClass:"mb-2 text-sm font-stolzl font-normal break-word line-clamp-4 xl:line-clamp-3 xl:text-2xl lg:text-xl md:text-lg md:font-lato md:font-medium md:mb-0.5",domProps:{"innerHTML":_vm._s(_vm.contentBanner.title)}}):_vm._e(),_vm._v(" "),(_vm.contentBanner.comment)?_c('div',{staticClass:"text-xs max-w-[17rem] break-word line-clamp-3 xl:text-md lg:text-base md:text-xs md:line-clamp-2",domProps:{"innerHTML":_vm._s(_vm.contentBanner.comment)}}):_vm._e()])]),_vm._v(" "),(_vm.contentBanner.url)?_c('a',{staticClass:"absolute inset-0",attrs:{"target":"_blank","href":_vm.contentBanner.url}}):_vm._e(),_vm._v(" "),(_vm.contentBanner.href)?_c('NuxtLink',{staticClass:"absolute inset-0",attrs:{"to":{ name: _vm.contentBanner.href }}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_c('div',{class:[
                    item.size, 'typography-block',
                    {
                        'typography-block_text': item.component === 'content-text' && !_vm.flat,
                        'bg-white rounded-xl p-5': item.component === 'content-text'
                    }
                ]},[(item.component !== 'content-javascript' || item.component === 'content-javascript' && !_vm.getJsItemId(item))?_c(item.component,{tag:"Component",attrs:{"block":item.block,"full-width":_vm.fullWidth,"is-main-page":_vm.isMainPage}}):_c('ExternalWidget',{attrs:{"id":_vm.getJsItemId(item),"internal":true}})],1)])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }