export default {
    computed: {
        isTouch() {
            return 'ontouchstart' in window;
        },
        dragEvent() {
            return this.isTouch ? 'touchmove' : 'mousemove';
        },
        touchStartEvent() {
            return this.isTouch ? 'touchstart' : 'mousedown';
        },
        touchEndEvent() {
            return this.isTouch ? 'touchend' : 'mouseup';
        }
    },
    methods: {
        isTouchEvent(e) {
            return e.toString() === '[object TouchEvent]';
        },
        getDragXPos(e) {
            return this.isTouchEvent(e) ? e.touches[0].clientX : e.clientX;
        },
        getDragYPos(e) {
            return this.isTouchEvent(e) ? e.touches[0].clientY : e.clientY;
        }
    }
};
