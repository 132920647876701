//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BestOffersSwiper from '@/components/swiper/BestOffersSwiper';
import BaseButton from '@/components/BaseButton';
import BaseIcon from '@/components/BaseIcon';
import CircleLoading from '@/components/CircleLoading';

import { userStore } from '@/store';
import CreditService from '@/services/Credit';
import BannerService from '@/services/Banner';
import { BANNER_PLACES } from '@/constants/banners';
import { BANNER_POSITION_BEST_OFFERS } from '@/constants/banners';

const BEST_OFFERS_LIMIT = 10;

export default {
    components: { CircleLoading, BestOffersSwiper, BaseButton, BaseIcon },
    props: {
        value: { type: Array }, // значение чекбокса
        isCardCheckbox: { type: Boolean, default: true, required: false }, // является ли карточка свайпера чекбоксом
        isCardCheckboxVisible: { type: Boolean, default: false, required: false }, // показать значок чекбокса на карточке
        fetchOffersExtraParams: { type: Object, required: false }, // доп. кваэри-параметры для получения предложений
        creditTypeId: { type: Number }, // выбранные тип кредита
        items: { type: Array, required: false },
        theme: { type: String, required: false },
        applicationRouteQuery: { type: Object, default: () => ({}) }
    },
    data() {
        return {
            offers: {
                isPending: false,
                error: false,
                items: []
            },
            banners: []
        };
    },
    async fetch() {
        return Promise.all([
            this.fetchOffers()
        ]);
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        userCity() {
            return userStore.userCity;
        },
        fetchParams() {
            const userCityId = this.userCity && this.userCity.id;
            const query = {};
            Object.assign(query, userCityId ? { city: userCityId } : {});

            return query;
        },
        fetchParamsCombined() {
            return {
                ...this.fetchParams,
                ...(this.fetchOffersExtraParams || {})
            };
        },
        offerItems() {
            return this.items || this.offers.items;
        },
        offerItemsCombined() {
            const items = [];
            let currentBannerIndex = 0;
            (Array.isArray(this.offerItems) ? this.offerItems : []).forEach((offer, offerIndex) => {
                if (offerIndex % BANNER_POSITION_BEST_OFFERS === 0 && offerIndex > 0 && this.banners[currentBannerIndex]) {
                    const banner = this.banners[currentBannerIndex];
                    items.push({
                        isBanner: true,
                        banner: {
                            ...banner,
                            logo: banner.logo
                        }
                    });
                    currentBannerIndex++;
                }
                items.push(offer);
            });

            return items;
        },
        applicationUrl() {
            return {
                name: 'application',
                query: {
                    offers: this.inputValue,
                    creditType: this.creditTypeId,
                    ...this.applicationRouteQuery
                }
            };
        }
    },
    watch: {
        async fetchOffersExtraParams() {
            await this.fetchOffers();
        },
        async fetchParams() {
            await this.fetchOffers();
        }
    },
    methods: {
        async doFetchBanners(length = BANNER_POSITION_BEST_OFFERS) {
            if (!length) return;

            const limit = Math.ceil(length / BANNER_POSITION_BEST_OFFERS);
            try {
                const banners = await  BannerService.fetchBanners(
                    {
                        ...this.fetchParamsCombined,
                        place: BANNER_PLACES.AD_LIST,
                        limit
                    },
                    {
                        progress: false
                    }
                );
                if (banners) {
                    this.banners = Array.isArray(banners) ? banners : banners.items;
                }
            } catch (e) {
                console.log(e);
            }
        },
        /** Получить предложения */
        async fetchOffers() {
            if (this.items) {
                return this.doFetchBanners(this.items.length);
            }

            try {
                this.offers.isPending = true;
                const paginatedOffers = await CreditService.fetchCreditOffers(
                    {
                        ...this.fetchParamsCombined,
                        showOnIndex: true,
                        limit: BEST_OFFERS_LIMIT
                    },
                    {
                        progress: false
                    }
                );
                this.offers = Object.assign(this.offers, { ...paginatedOffers, error: false });

                this.offerItems.length && await this.doFetchBanners(this.offerItems.length);
            } catch (err) {
                this.offers.error = true;
                console.error(err);
            } finally {
                this.offers.isPending = false;
            }
        }
    }
};
